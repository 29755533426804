import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseData } from '../../models/api-model';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import { ErrorHandleService } from '../error-handle.service';
import { DefaultValueSetting, DefaultValueSettingDetail, DefaultValueSettingRO } from 'src/app/models/response/default-value-setting.ro';
import { SAUCER_LOG_ACTION, SaucerLogService } from '../saucer-logs/saucer-log.service';
import { SYNCDATA_STATUS } from 'src/app/const/text-common';
import { getDefaultFuntionFromSetting, getDSDefaultRequest, updateValueSettingForDFFuntion } from 'src/app/_helper/default-value-setting-helper';
import { StepFunctionService } from './step-function.service';
import { ClearProcessService } from '../clear-process.service';
import { sortData } from '../../_helper/helper';


@Injectable({ providedIn: 'root' })
export class DefaultValueSettingService extends ApiService {

  constructor(
    http: HttpClient, 
    router: Router, 
    errorHandle: ErrorHandleService,
    private saucerLogService: SaucerLogService,
    private stepFunctionService: StepFunctionService,
    private clearProcessService: ClearProcessService
  ) {
    super(http, router, errorHandle);
  }

  async getAll() {
    let apiUrl = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.ALL;
    let resData = await this.get(apiUrl);
    return await this.mapModel(resData);
  }
  getTagByDf(data: any) {
    let apiUrl = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.GET_TAG_BY_DF;
    let resData =  this.post(apiUrl, [], data);
    return resData
  }
  async UpdateName(data: any, oldLog: any) {
    const apiPath = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.UPDATE_NAME;
    const resData = await this.post(apiPath, [], data);

    const logAction = oldLog != null ? SAUCER_LOG_ACTION.DEFAULT_SETTING.UPDATE_CONDITION : SAUCER_LOG_ACTION.DEFAULT_SETTING.CREATE_CONDITION;


    if(resData?.statuscode === 200) {
      this.saucerLogService.system({
        apiPath,
        body: data,
        statusCode: resData?.statuscode,
        content: JSON.stringify({
          old: oldLog != null ? {
            conditionCd: oldLog.defaultvaluesettingcd,
            conditionName: oldLog.defaultvaluesettingname
          } : null,
          new: { 
            conditionCd: data.defaultvaluesetting.defaultvaluesettingcd,
            conditionName: data.defaultvaluesetting.defaultvaluesettingname
          }
        })
      }, { action: logAction });

    } else {
      this.saucerLogService.error({
        apiPath,
        body: data,
        statusCode: resData?.statuscode,
        content: SYNCDATA_STATUS.ERROR
      }, { action: logAction });
    }
    return resData;
  }

  getTTLTagData(requestBody: any): Promise<any> {
    let apiUrl = API_APP.TITLE.CONTROLLER + API_APP.TITLE.GETTTLTAGDATA;
    return this.post(apiUrl, [], requestBody);

  }

  countTTLTagByOffices(requestBody: any): Promise<any> {
    let apiUrl = API_APP.TITLE.CONTROLLER + API_APP.TITLE.COUNTTTLTAG;
    return this.post(apiUrl, [], requestBody);

  }

  LIMIT_TITLEMAPPING = 6000;
  async getDataTTLTag(requestBody: any, tokenizer: any) {
    try {
      let countRepon = await this.countTTLTagByOffices(requestBody);
      if (countRepon.statuscode === 200 && countRepon.data) {
        let loopTotal = Math.ceil(countRepon.data / this.LIMIT_TITLEMAPPING);
        let dataRepon = await Promise.all([...Array(loopTotal).keys()].map(index => {
          let titleMappingRequest = {
            officeIds: requestBody.officeIds,
            offset: index * this.LIMIT_TITLEMAPPING,
            limit: this.LIMIT_TITLEMAPPING,
          }
          return this.getTTLTagData(titleMappingRequest);
        }));
        let data = dataRepon.map((item: any) => item.data).flat();

        let kbnTagGroups = data.reduce((group, ttlTag) => {

          let kbntagGroup = group.find((g:any) => g.kbntag == ttlTag.kbntag);
          if (!kbntagGroup) {
            kbntagGroup = { kbntag: ttlTag.kbntag, name: ttlTag.kbntag, ttltags: [] };
            group.push(kbntagGroup);
          }
          let tag = kbntagGroup.ttltags.find((g:any) => g.ttltag == ttlTag.ttltag);
          if(!tag){
            kbntagGroup.ttltags.push(ttlTag);
          }
         
          return group;
        }, []);
        
        kbnTagGroups.forEach((item: any) => {
          //item.ttltags = item.ttltags.sort((a: any, b: any) => a.ttltag.localeCompare(b.ttltag, 'ja-JP-u-co-unihan'));
          item.ttltags = sortData(item.ttltags, tokenizer, 'asc', 'ttltag')
        });
        
        return kbnTagGroups;
      }
    } catch (ex) {}
    return [];
  }

  async insertOrUpdate(request: any, oldLog: any, newLog: any) {
    const apiPath = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.INSERT_OR_UPDATE;
    const resData = await this.post(apiPath, [], request);
    if(request.defaultvaluesetting.delflg) {
      if(resData?.statuscode === 200) {
        this.saucerLogService.system({
          apiPath,
          body: request,
          statusCode: resData?.statuscode,
          content: JSON.stringify({
            old: oldLog,
            new: null
          })
        }, { action: SAUCER_LOG_ACTION.DEFAULT_SETTING.DELETE_CONDITION });

      } else {
        this.saucerLogService.error({
          apiPath,
          body: request,
          statusCode: resData?.statuscode,
          content: SYNCDATA_STATUS.ERROR
        }, { action: SAUCER_LOG_ACTION.DEFAULT_SETTING.DELETE_CONDITION });
      }
    } else {
      if(resData?.statuscode === 200) {
        this.saucerLogService.system({
          apiPath,
          body: request,
          statusCode: resData?.statuscode,
          content: JSON.stringify({
            old: oldLog,
            new: newLog
          })
        }, { action: SAUCER_LOG_ACTION.DEFAULT_SETTING.SAVE_BUTTON });

      } else {
        this.saucerLogService.error({
          apiPath,
          body: request,
          statusCode: resData?.statuscode,
          content: SYNCDATA_STATUS.ERROR
        }, { action: SAUCER_LOG_ACTION.DEFAULT_SETTING.SAVE_BUTTON });
      }
    }
    return;
  }


  createDsDefaultFromTemplate(request: any) {
    let uri = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.CREATE_DS_DF_FROM_TEMPLATE;
    return this.post(uri, [], request);
  }


  insertOrUpdateDatasourceDf(request: any) {
    let uri = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.INSERT_OR_UPDATE_DS_DF;
    return this.post(uri, [], request);
  }

  syncDataSourceDefaultResult(widgetCd: any,  datasourceCd: any) {
    let uri = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.SYNC_DS_DF_BY_WIDGET+ "/"+ widgetCd+ "/"+datasourceCd;
    return this.post(uri, [],{});
  }
  cloneByConstCd(constCd: string) {
    const params = [constCd];
    let endpoint = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.CLONE;
    return this.get(endpoint, params);
  }

  searchByKeyword(keyword: string) {
    const bodyData = JSON.stringify(keyword);
    let uri = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.SEARCH;
    return this.post(uri, [], bodyData);
  }

  async mapModel(data: any) {
    let res: ResponseData<DefaultValueSettingRO[]> = new ResponseData<DefaultValueSettingRO[]>();
    let dataArr:DefaultValueSettingRO[] = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode === 200) {
      for await (const element of data.data) {
        let defaultValueSetting = new DefaultValueSettingRO();
        if(element.defaultvaluesetting) {
          defaultValueSetting.defaultValueSetting = new DefaultValueSetting();
          defaultValueSetting.defaultValueSetting.id = element.defaultvaluesetting.id;
          defaultValueSetting.defaultValueSetting.defaultvaluesettingcd = element.defaultvaluesetting.defaultvaluesettingcd;
          defaultValueSetting.defaultValueSetting.defaultvaluesettingname = element.defaultvaluesetting.defaultvaluesettingname;
          defaultValueSetting.defaultValueSetting.officeidlst = element.defaultvaluesetting.officeidlst;
          defaultValueSetting.defaultValueSetting.invalidflg = element.defaultvaluesetting.invalidflg;
          defaultValueSetting.defaultValueSetting.delflg = element.defaultvaluesetting.delflg;
          defaultValueSetting.defaultValueSetting.insstfcd = element.defaultvaluesetting.insstfcd;
          defaultValueSetting.defaultValueSetting.insdate = element.defaultvaluesetting.insdate;
          defaultValueSetting.defaultValueSetting.updstfcd = element.defaultvaluesetting.updstfcd;
          defaultValueSetting.defaultValueSetting.upddate = element.defaultvaluesetting.upddate;
          if(element.listdefaultvaluesettingdetail?.length > 0) {
            defaultValueSetting.listDefaultValueSettingDetail = [];
            element.listdefaultvaluesettingdetail.map((dfDetail: any) => {
              let dfD  = new DefaultValueSettingDetail();
              dfD.id = dfDetail.id
              dfD.defaultvaluesettingcd = dfDetail.defaultvaluesettingcd
              dfD.kbntag = dfDetail.kbntag
              dfD.ttltag = dfDetail.ttltag
              dfD.delflg = dfDetail.delflg
              dfD.insstfcd = dfDetail.insstfcd
              dfD.insdate = dfDetail.insdate
              dfD.updstfcd = dfDetail.updstfcd
              dfD.upddate = dfDetail.upddate
              defaultValueSetting.listDefaultValueSettingDetail?.push(dfD);
            })
          }
          dataArr.push(defaultValueSetting)
        }
      }
    }
    res.data = dataArr;
    return res;
  }

  async updateOrPrevivewDsDefault (requestParam: any): Promise<any> {
    
    let settingValueConfig =  requestParam.settingValueConfig
    let listValueDefaultSetting = requestParam.listValueDefaultSetting
    let dsstructcd = requestParam.dsstructcd
    let dsdefaultcd = requestParam.dsdefaultcd
    let isPreview = requestParam.isPreview

    let paramDefaultFuntion = getDefaultFuntionFromSetting(settingValueConfig);
    let dsDefaultRequest = getDSDefaultRequest(paramDefaultFuntion, false, listValueDefaultSetting, null);
    if(dsDefaultRequest == null || dsDefaultRequest.length == 0) return null;
    let request = { 
        dsstructcd: dsstructcd,
        dsdefaultcd: dsdefaultcd,
        lstcondition: dsDefaultRequest,
        ispreview: isPreview
      }
    
    let res = await this.insertOrUpdateDatasourceDf(request);
    
    if(res.statuscode == 200) {

      if(settingValueConfig && settingValueConfig.targetTable && settingValueConfig.targetTable.length > 0) {
        settingValueConfig = updateValueSettingForDFFuntion(settingValueConfig, res, false);
      }
      
      await this.stepFunctionService.checkLambdaStatus(res.data.lambdaresponse.executionarn);

      return { 
        dsdefaultcd: res.data.dsstructcd,
        settingValueConfig: settingValueConfig
      };
    } 

    return null;
  }

  
}
