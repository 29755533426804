import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ButtonType, SearchType } from '../../../../app/enum/common-enum';
import { bycd, ModalTemplate, SearchParams, TreeNode, TreeViewInputParams } from '../../../../app/models/common-model';
import { BUTTON, COMMON_TEXT } from '../../../../app/const/text-common';
import { cloneDeep, groupBy, orderBy } from 'lodash';
import TreeUtils from '../../../../app/util/treeUtils';
import { SAUCER_LOG_ACTION, SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { InvisibleColumn, TVPParams } from '../../../../app/const/const';
import { DashboardService } from '../../../../app/services/modules/dashboard.service';
import { buildTokenizer, sortData } from 'src/app/_helper/helper';

@Component({
  selector: 'pivot-dialog-select-dashboard',
  templateUrl: './dialog-select-dashboard.component.html',
  styleUrls: ['./dialog-select-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogSelectDashboardComponent implements OnInit {
  @Input() dashboardTreeData2Level: TreeViewInputParams;
  @Input() nodeSelected: any = null;
  @Output() onSubmitData = new EventEmitter();
  searchParams: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH_TEXT,
    cssStyle: { width: '100%', height: '40px' },
    readonly: false,
    disabled: false,
    maxLength: 100
  };

  isDarkMode: boolean = true;
  COMMON_TEXT = COMMON_TEXT;
  modalTemplate: ModalTemplate = new ModalTemplate();
  dashboardData: any[] = [];
  BUTTON = BUTTON;
  buttonType = ButtonType;
  submitData: any = null;
  backupData: TreeViewInputParams;
   // tree view param
   dashboardTree: TreeViewInputParams = {
    ...cloneDeep(TVPParams),
    checkbox: false,
    allowParentSelect: true,
    selectable: true,
    hoverable: true
  };
  isLoading: boolean = true;

  SAUCER_LOG_ACTION = SAUCER_LOG_ACTION;

  constructor(private dashboardService: DashboardService, private saucerLogService: SaucerLogService
  ) {
    const bodyElement = document.getElementsByTagName("body")[0];
    this.isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
	  if (this.isDarkMode) {
		this.dashboardTree.expandIcon = '../../../../assets/icons/folder-white.svg';
      this.dashboardTree.collapseIcon = '../../../../assets/icons/folder-opened-white.svg';
      } else {
      this.dashboardTree.expandIcon = '../../../../assets/icons/folder.svg';
      this.dashboardTree.collapseIcon = '../../../../assets/icons/folder-opened.svg';
	  }
   }

  async ngOnInit() {
    this.modalTemplate.header = COMMON_TEXT.TEMPLATE_SELECTION;
    this.modalTemplate.style = {'width': '500px', 'height': '550px'};
    this.modalTemplate.isResizable = true;
    if(!this.dashboardTreeData2Level)
      await this.getListDashboardFolder();
    else this.isLoading = false;
  }

  // load tree folder dashboard theo thứ tự như bên dashboard template
	async loadFolderTree(selectedDS: string[] = []) {
		const hierarchyCD: bycd[] = [
		  { cd: 'foldercd', labelcd: 'foldername', showIcon: true, },
		  { cd: 'dashboardcd', labelcd: 'dashboardname', },
		];
		let nodes = this.arrayToTreeNode(this.dashboardData, hierarchyCD, selectedDS)
		if(nodes) nodes = orderBy(nodes, ["label"]);
		nodes = nodes?.filter(s => s.data && (s.data.filter((dt: any) => dt.dashboardcd)?.length > 0));
    const tokenizer = await buildTokenizer();
    this.dashboardTree.nodes = sortData(nodes, tokenizer, "asc", "label");
		this.dashboardTree = { ...this.dashboardTree }

    this.dashboardTreeData2Level = cloneDeep(this.dashboardTree)
    this.dashboardTreeData2Level.nodes?.forEach(node => {
      if(node.nodes && node.nodes?.length > 0) {
        node.isLoaded = true;
        node.nodes = node.nodes.map(n => {
          n.isLoaded = true
          return n
        });
        node.nodes = sortData(node.nodes, tokenizer, "asc", "label");
      }
    });
	}

  // lấy danh sách dashboard có widget từ bên template
	async getListDashboardFolder() {
    this.isLoading = true;
		await this.dashboardService.getListDashboardTemplate().then(res => {
		  if(res.statuscode == 200){
			this.dashboardData = res.data;
      this.loadFolderTree()
		  }
		});
    this.isLoading = false;
	}

  handleNodeClick(node: any) {
    const isParent = this.dashboardTreeData2Level?.nodes.some(d => d === node);
    if (isParent) {
      return;
    }
    this.nodeSelected = node.id;
  }

  onFilterOutData(searchValue: any) {
    if (!searchValue) {
      this.dashboardTreeData2Level.nodes.forEach(lv1 => {
        lv1.hidden = false;

        lv1.nodes?.forEach(lv2 => lv2.hidden = false);
      });

      return;
    }

    this.dashboardTreeData2Level.nodes.forEach(lv1 => {
      lv1.nodes?.forEach(lv2 => lv2.hidden = (!TreeUtils.checkTreeNodeLabel(lv2, searchValue) && !TreeUtils.checkTreeNodeLabel(lv1, searchValue)));

      if (!lv1.nodes || lv1.nodes.length === 0) {
        lv1.hidden = true;
      } else {
        lv1.hidden = lv1.nodes?.every(n => n.hidden);
      }
    });
  }

  showNoDataText() {
    return this.dashboardTreeData2Level?.nodes?.length === 0 || this.dashboardTreeData2Level?.nodes?.every(n => n.hidden);
  }

  cancel() {
    this.onSubmitData.emit(null);
  }

  save() {
    if(!this.nodeSelected) return;
    // trả treeview về ban đầu
    this.dashboardTreeData2Level.nodes.forEach(lv1 => {
      lv1.hidden = false;
      lv1.nodes?.forEach(lv2 => lv2.hidden = false);
    });
    this.submitData = { treedata: this.dashboardTreeData2Level, nodeSelected: this.nodeSelected };
    this.onSubmitData.emit(this.submitData);
  }

  arrayToTreeNode = (data: any[], groupCD: bycd[], selectedCD: string[] = [], parentNode?: TreeNode): TreeNode[] => {
		let nodes: TreeNode[] = []
		let groupX: bycd | undefined = groupCD.shift()

		if (groupX) {
		  const { cd, labelcd }: bycd = groupX
		  const x = groupBy(data, cd)
		  const isLastNode = !groupCD.length

		  for (const key in x) {
			const selected = selectedCD.includes(key)
			let y: TreeNode = {
			  id: key,
			  label: x[key][0][labelcd as string],

			  isShowIcon: groupX.showIcon || false,
			  isShowCheckBox: groupX.checkbox || false,
			  data: x[key],
			  isLastNode: isLastNode,
			  draggable: isLastNode,
			  singleSelected: selected,
			  hidden: isLastNode ? (InvisibleColumn[x[key][0].columnname] || false) : false,
			}

			y.nodes = this.arrayToTreeNode(x[key], [...groupCD], selectedCD, y)

			selected && parentNode && (parentNode.expanded = true)

			nodes.push(y);
		  }
		  return nodes
		}

		return []
	}
}
