import { CK_REPORT_TEXT } from "../const/text-common";

export const CONFIG_LOG = {
    IS_DEBUG: (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? true : false,
    TIMEOUT_CALL_SAUCER_LOG: 30000,
    MAX_COUNT_TO_CALL_SAUCER_LOG: 50,
    API_GET_CLIENT_IP: 'https://ipinfo.io/json'
}

export const LOG_TYPE = {
    LOG_ACTION: 1,
    LOG_ERROR: 2,
    LOG_SYSTEM: 3
};

export const ACTION_LOG = {
    LOGIN: "Login",                                                     // ログインする
    LOGOUT: "Logout",                                                   // ログアウトする
    ACCESS: "ChangeScreen",                                             // Access（サイト外へのアクセス）
    VIEW: "View",                                                       // 閲覧
    CREATE: "Create",                                                   // 新規
    UPDATE: "Update",                                                   // 更新
    DELETE: "Delete",                                                   // 削除
    SEARCH: "Search",                                                   // 検索
    CHANGE_FILTER: "ChangeFilter",                                      // フィルター変更
    SORT: "Sort", 
    CHANGE_PAGE: "Pagination",                                          // ページ送り
    CHANGE_TAB: "ChangeTab",                                            // タブ切り替え
    SHOW_DIALOG: "ShowDialog",                                          // ダイアログ表示
    SHOW_CALENDAR: "ShowCalendar",                                      // カレンダー表示
    CLOSE_DIALOG: "CloseDialog",                                        // ダイアログを閉じる
    DOWNLOAD_FILE: "DownloadFile",                                      // ファイルダウンロード 
    POST: "POST",     
    GET: "GET",      
    PUT: "PUT",
    ACTION_ERROR: "アクションエラー",
    CHANGE_VIEW_TYPE: "ChangeViewType"
};

export const CONTENT_LOG = {
    LOGIN: "ログインする",
    LOGOUT: "ログアウトする",
    ACCESS: "Access（サイト外へのアクセス）",
    VIEW: "閲覧",
    CREATE: "新規",
    UPDATE: "更新",
    DELETE: "削除",
    SEARCH: "検索",
    CHANGE_FILTER: "フィルター変更",
    CHANGE_PAGE: "ページ送り",
    CHANGE_TAB: "タブ切り替え",
    SORT: "ソート",
    SHOW_DIALOG: "ダイアログ表示",
    SHOW_CALENDAR: "カレンダー表示",
    CLOSE_DIALOG: "ダイアログを閉じる",
    ADD_USER_DEFINED: "ユーザー定義列追加", 
    EDIT_USER_DEFINED: "ユーザー定義列編集",
    DELETE_USER_DEFINED: "ユーザー定義列削除",
    SAVE_UNSAVED_DATA: "未保存のデータを保存する",
    NOT_SAVE_UNSAVED_DATA: "未保存のデータを保存しない",
    RETURN_TO_ORIGINAL_SCREEN: "元の画面に戻る",
    SEARCH_FOLDER: "フォルダ検索: ",
    OFFICE_SELECTION: "事業所選択",
    DASHBOARD_SWITCHING: "ダッシュボード切り替え: ",
    EXPORT_EXCEL: "Excel出力: ",
    SYNC_LASTED_DATA: "最新データ更新: ",
    VIEW_WIDGET_DETAIL: "ウィジェットの詳細を確認する: ",
    CHANGE_FILTER_WIDGET: "ウィジェットでフィルターをする: ",
    CHANGE_SORT_ORDER_WIDGET: "ウィジェットで並び順を変更する: ",
    HOME_SYNC_WIDGET_DATA: "「更新可能」をクリックする",
    SEARCH_DATA_SOURCE: "データソース検索: ",
    RETURN_PREVIOUS_SCREEN: "前の画面に戻る",
    SAVE_AS: '別名で保存する',
    LOGIN_SUCCESS: 'ログイン成功',
    DASHBOARD_SELECTION: 'ダッシュボード選択',
    DASHBOARD_CREATION: 'ダッシュボード作成',
    DASHBOARD_EDITING: 'ダッシュボード編集',
    DELETE_DASHBOARD: 'ダッシュボード削除',
    FOLDER_SELECTION: 'フォルダ選択',
    ADD_DASHBOARD: 'ダッシュボードを作成する',
    EDIT_DASHBOARD: 'ダッシュボードを編集する',
    CREATE_FOLDER: 'フォルダ作成',
    ADD_WIDGET: 'ウィジェット追加',
    EDIT_WIDGET: 'ウィジェット編集',
    DELETE_WIDGET: 'ウィジェット削除',
    SELECT_DATASOURCE: 'データソースを選択する',
    ADD_USER_DEFINE_COLUMN: 'ユーザー定義列、目標追加',
    ITEM_NARROWING_DOWN: '項目絞込み',
    TEMPLATE_SELECTION: 'テンプレート選択',
    SAVE: '保存する',
    DOWNLOAD: 'ダウンロードする',
    REMOVE:'削除する',
    DASHBOARD_SEARCH: 'ダッシュボード検索',
    EDIT_FOLDER: 'フォルダ編集',
    DELETE_FOLDER: 'フォルダ削除',
    SEARCH_WIDGET: 'ウィジェット検索: ',
    CANCEL: 'キャンセル',
    KEEP: '保存',
    ADD_TARGET_VALUE: '目標値追加',
    EDIT_TARGET_VALUE: '目標値編集',
    DELETE_TARGET_VALUE: '目標値削除',
    SAVE_TARGET_VALUE: '目標値保存',
    SEARCH_OFFICE: '事業所検索: ',
    SWITCH_OFFICE: '事業所切り替え',
    START_MONTH: '開始月',
    END_MONTH: '終了月', 
    AUTOMATIC_ACQUISITION: '自動取得設定:',
    SAVE_CONDITION: '条件保存',
    SAVE_AND_CLOSE_CONDITION: '保存して閉じる',
    DASHBOARD_NAME: 'ダッシュボードト名',
    NEW_DASHBOARD: '新規ダッシュボード',
    DATA_SOURCE_SELECTION: 'データソース選択',
    VALIDITY_PERIOD: '有効期間',
    SELECT_TITLE: 'タイトル選択',
    PREVIEW: 'プレビュー',
    EDIT_TXT_DASHBOARD: '編集',
    // Default Setting
    COPY: '複写',
    INVALID: '条件無効',
    SEARCH_TITLE_TAG: 'タイトルタグ検索: ',
    SEARCH_CATEGORY_TAG: 'タイトル区分タグ検索: ',
    SEARCH_CONDITION: '条件検索: ',

    ADD_CONDITION: '条件追加',
    EDIT_CONDITION: '条件編集',
    DELETE_CONDITION: '条件削除',
    CLICK_ADD_TITLE_TAG: '「追加」をクリックする',
    CLICK_DELETE_TITLE_TAG: '「×」をクリックする',

    FREQUENTLY_USED_SELECTION: 'よく使用選択', 
    SEARCH_FOR_TEMPLATES: 'テンプレート検索',
    HISTORY_SELECTION: '履歴選択',
    SELECT_LATEST_PUBLISHED: '最新公開選択',
    SELECT_TEMPLATE: 'テンプレート選択',
    GO_BACK: '戻る',

    DATASOURCE_SAVE_DESTINATION: 'データソース保存先',
    WIDGET_FOLDER_SEARCH: 'ウィジェットフォルダ検索',
    WIDGET_DISPLAY_DESTINATION: 'ウィジェット表示先',
    DISPLAY_FORMAT: '表示形式',
    ADD_FOLDER_DIALOG: 'フォルダ追加',
    ADD_DASHBOARD_DIALOG: 'ダッシュボード追加',
    ADD: '追加',
    NEXT: '次へ',
    SETTINGS_CONFIRM_DIALOG: '設定',
    SHOW_MORE: 'さらに表示',
    SELECT: '選択: '
};


export const SCREEN_NAME = {
    COMMON: '',
    LOGIN: 'ログイン',
    LOGOUT: 'ログアウト',
    MY_ACCOUNT: 'マイアカウント',
    GUIDANCE: '案内',
    ROLE_SETTINGS: 'システム設定 ＞ ロール設定',
    CORP_SETTING: 'システム設定 ＞ 法人の設定',
    STAFF_INFO: 'システム設定 ＞ 職員情報',
    SYNC_CK_MASTER: 'システム設定 ＞ CKマスタ同期',
    DEFAULT_SETTING: 'システム設定 ＞ 既定値設定',
    HOME: 'ホーム',
    CONFIRM_UPDATE_LATEST_DIALOG: '最新データ更新確認ダイアログ',
    DATASOURCE_LIST: 'データソース一覧',
    DATASOURCE_LIST_TEMPLATE: 'データソース一覧（テンプレート）',
    BUSINESS_SELECTION_DIALOG: '事業所選択ダイアログ',
    CREATE_DATA_SOURCE: 'データソース作成',
    CREATE_DATA_SOURCE_TEMPLATE: 'データソース作成（テンプレート）',
    EDIT_DATA_SOURCE: 'データソース編集',
    EDIT_DATA_SOURCE_TEMPLATE: 'データソース編集（テンプレート）',
    FOLDER_SELECTION_DIALOG: 'フォルダ選択ダイアログ',
    WIDGET_DIALOG_SELECTION:'ウィジェット同期確認ダイアログ',
    WIDGET_LIST: 'ウィジェット一覧',
    WIDGET_LIST_TEMPLATE: 'ウィジェット一覧（テンプレート）',
    WIDGET_SETTING: {
        WIDGET_CREATION: 'ウィジェット作成',
        WIDGET_CREATION_TEMPLATE: 'ウィジェット作成（テンプレート）',
        WIDGET_EDITING: 'ウィジェット編集',
        WIDGET_EDITING_TEMPLATE: 'ウィジェット編集（テンプレート）',
        UNSAVED_DATA_CONFIRMATION_DIALOG : '未保存データ確認ダイアログ',
        DATA_SOURCE_SELECTION_DIALOG : 'データソース選択ダイアログ',
        ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG : 'ユーザー定義列・目標値追加ダイアログ',
        FILTER_CONDITION_DIALOG : '絞込み条件ダイアログ',
        FOLDER_SELECTION_DIALOG : 'フォルダ選択ダイアログ',
    },
    TEMPLATE_SELECTION: {
        MAIN: 'テンプレート選択',
        BUSINESS_LOCATION_SELECTION_DIALOG: '事業所選択ダイアログ',
        DATA_SOURCE_INFORMATION_REGISTRATION_DIALOG: 'データソース情報登録ダイアログ',
        FOLDER_SELECTION_DIALOG: 'フォルダ選択ダイアログ',
        CONFIRMATION_DIALOG: '確認ダイアログ'
    },
    MENU_TEMPLATE: {
        TEMPLATE_SELECTION: 'テンプレート選択',
        TEMPLATE_SELECTION_DIALOG: 'テンプレート選択ダイアログ',
        WIDGET_CREATION: 'ウィジェット作成',
        WIDGET_SAVE_DESTINATION_SETTING_DIALOG: 'ウィジェット保存先設定',
        BUSINESS_LOCATION_SELECTION_DIALOG: '事業所選択ダイアログ',
        DATASOURCE_SAVE_DESTINATION_SETTING_DIALOG: 'データソース保存先設定ダイアログ',
        CREATE_FOLDER_DIALOG: 'フォルダ作成ダイアログ',
        DASHBOARD_SELECTION_DIALOG: 'ダッシュボード選択ダイアログ',
        DASHBOARD_CREATION_DIALOG: 'ダッシュボード作成ダイアログ',
        CONFIRMATION_DIALOG: '確認ダイアログ',
        SETTINGS_DIALOG: '設定ダイアログ'
    },
    RETURN_HOME: '在宅復帰',
    RETURN_HOME_DIALOG: '自動取得設定ダイアログ',
    DASHBOARD_LIST: 'ダッシュボード一覧',
    DASHBOARD_CREATION_DIALOG: 'ダッシュボード作成ダイアログ',
    DASHBOARD_EDITING_DIALOG: 'ダッシュボード編集ダイアログ',
    DASHBOARD_TEMPLATE_CREATION_DIALOG: 'ダッシュボード作成（テンプレート）ダイアログ',
    DASHBOARD_TEMPLATE_EDITING_DIALOG: 'ダッシュボード編集（テンプレート）ダイアログ',
    DASHBOARD_CREATION: 'ダッシュボード作成',
    UNSAVED_CONFIRM_DIALOG: '未保存データ確認ダイアログ',
    DASHBOARD_EDITING: 'ダッシュボード編集',
    DASHBOARD_TEMPLATE_LIST: 'ダッシュボード一覧（テンプレート）',
    DASHBOARD_TEMPLATE_EDITING: 'ダッシュボード編集（テンプレート）',
    DASHBOARD_TEMPLATE_CREATION: 'ダッシュボード作成（テンプレート）',
};

export const SAUCER_LOG_ACTION = {
    COMMON_CALL_API: {
        GET: {screenName: SCREEN_NAME.COMMON, action: ACTION_LOG.GET, body: ''},
        POST: {screenName: SCREEN_NAME.COMMON, action: ACTION_LOG.POST, body: ''},
        PUT: {screenName: SCREEN_NAME.COMMON, action: ACTION_LOG.PUT, body: ''},
    },
    // ログイン
    LOGIN: {
        ACTION_LOGIN: {screenName: SCREEN_NAME.LOGIN, action: ACTION_LOG.LOGIN, body: 'ログイン成功'},
        SUCCESS: {screenName: SCREEN_NAME.LOGIN, action: ACTION_LOG.POST, body: 'ログイン成功'},
        FAILED: {screenName: SCREEN_NAME.LOGIN, action: ACTION_LOG.LOGIN, body: 'ログイン失敗'}
    },
    // ヘッダ
    LOGOUT: {
        SUCCESS: {screenName: SCREEN_NAME.LOGOUT, action: ACTION_LOG.LOGOUT, body: 'ログアウト成功'},
        FAILED: {screenName: SCREEN_NAME.LOGOUT, action: ACTION_LOG.LOGOUT, body: 'ログアウト失敗'}
    },
    //#region ヘッダ
    // マイアカウント
    MY_ACCOUNT: {
        VIEW: {screenName: SCREEN_NAME.MY_ACCOUNT , action: ACTION_LOG.VIEW, body: ''},
        ACCESS_UPDATE_USER: {screenName: SCREEN_NAME.MY_ACCOUNT , action: ACTION_LOG.ACCESS, body: '', content: "アカウント情報変更"},
        UPDATE_AVATAR: {screenName: SCREEN_NAME.MY_ACCOUNT , action: ACTION_LOG.UPDATE, body: ''},
        UPDATE_THEME: {screenName: SCREEN_NAME.MY_ACCOUNT , action: ACTION_LOG.UPDATE, body: ''}
    },
    // 案内
    GUIDANCE: {
        VIEW: {screenName: SCREEN_NAME.GUIDANCE, action: ACTION_LOG.VIEW, body: ''},
        CATEGORY_SELECTION: {screenName: SCREEN_NAME.GUIDANCE, action: ACTION_LOG.VIEW, body: '', content: 'カテゴリ選択: '},
        ACCESS: {screenName: SCREEN_NAME.GUIDANCE, action: ACTION_LOG.ACCESS, body: '', content: 'ケアコネクトジャパンサポートサイトは「こちら」をクリック'},
        DIALOG_DETAIL: {screenName: SCREEN_NAME.GUIDANCE, action: '案内の詳細', body: ''}
    },
    // ロール設定
    ROLE_SETTING: {
        VIEW: {screenName: SCREEN_NAME.ROLE_SETTINGS, action: ACTION_LOG.VIEW, body: ''},
        SEARCH: {screenName: SCREEN_NAME.ROLE_SETTINGS, action: ACTION_LOG.SEARCH, body: ''},
        CREATE: {screenName: SCREEN_NAME.ROLE_SETTINGS, action: ACTION_LOG.CREATE, body: '', content: 'ロール追加'},
        UPDATE: {screenName: SCREEN_NAME.ROLE_SETTINGS, action: ACTION_LOG.UPDATE, body: '', content: 'ロール編集'},
        DELETE: {screenName: SCREEN_NAME.ROLE_SETTINGS, action: ACTION_LOG.DELETE, body: '', content: 'ロール削除'},
    },
    // 法人の設定
    CORP_SETTING: {
        VIEW: {screenName: SCREEN_NAME.CORP_SETTING, action: ACTION_LOG.VIEW, body: ''},
        UPDATE_MONTH: {screenName: SCREEN_NAME.CORP_SETTING, action: ACTION_LOG.UPDATE, body: '開始月の変更'},
        UPDATE_INDICATOR: {screenName: SCREEN_NAME.CORP_SETTING, action: ACTION_LOG.UPDATE, body: '在宅復帰在宅療養等指標の変更'},
    },
    // 職員情報
    STAFF_INFO: {
        VIEW: {screenName: SCREEN_NAME.STAFF_INFO, action: ACTION_LOG.VIEW, body: ''},
        SEARCH: {screenName: SCREEN_NAME.STAFF_INFO, action: ACTION_LOG.SEARCH, body: ''},
        CHANGE_PAGE: {screenName: SCREEN_NAME.STAFF_INFO, action: ACTION_LOG.CHANGE_PAGE, body: ''},
        INVITE_STAFF: {screenName: SCREEN_NAME.STAFF_INFO, action: ACTION_LOG.CREATE, body: ''},
        CANCEL_INVITE: {screenName: SCREEN_NAME.STAFF_INFO, action: ACTION_LOG.DELETE, body: ''},
        UPDATE_STAFF: {screenName: SCREEN_NAME.STAFF_INFO, action: ACTION_LOG.UPDATE, body: ''},
        DELETE_STAFF: {screenName: SCREEN_NAME.STAFF_INFO, action: ACTION_LOG.DELETE, body: ''},
        CREATE_GROUP: {screenName: SCREEN_NAME.STAFF_INFO, action: ACTION_LOG.CREATE, body: ''},
        UPDATE_GROUP: {screenName: SCREEN_NAME.STAFF_INFO, action: ACTION_LOG.UPDATE, body: ''},
        DELETE_GROUP: {screenName: SCREEN_NAME.STAFF_INFO, action: ACTION_LOG.DELETE, body: ''},
    },
    // CKマスタ同期
    SYNC_CK_MASTER: {
        VIEW: {screenName: SCREEN_NAME.SYNC_CK_MASTER, action: ACTION_LOG.VIEW, body: ''},
        SYNC: {screenName: SCREEN_NAME.SYNC_CK_MASTER, action: ACTION_LOG.CREATE, body: ''},
        DELETE_SYNC: {screenName: SCREEN_NAME.SYNC_CK_MASTER, action: ACTION_LOG.DELETE, body: ''},
    },
    //DEFAULT_SETTING
    DEFAULT_SETTING: {
        VIEW: {screenName: SCREEN_NAME.DEFAULT_SETTING, action: ACTION_LOG.VIEW, body: ''},
        SEARCH_CONDITION: {screenName: SCREEN_NAME.DEFAULT_SETTING, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_CONDITION},
        CREATE_CONDITION: {screenName: SCREEN_NAME.DEFAULT_SETTING + " ＞ " + CONTENT_LOG.ADD_CONDITION, action: ACTION_LOG.CREATE, body: ''},
        UPDATE_CONDITION: {screenName: SCREEN_NAME.DEFAULT_SETTING + " ＞ " + CONTENT_LOG.EDIT_CONDITION, action: ACTION_LOG.UPDATE, body: ''},
        DELETE_CONDITION: {screenName: SCREEN_NAME.DEFAULT_SETTING + " ＞ " + CONTENT_LOG.DELETE_CONDITION, action: ACTION_LOG.DELETE, body: ''},
        SELECT_BUSINESS_LOCATION: {screenName: SCREEN_NAME.DEFAULT_SETTING + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},

        ADD_TITLE_TAG: {screenName: SCREEN_NAME.DEFAULT_SETTING + " ＞ " + CONTENT_LOG.CLICK_ADD_TITLE_TAG, action: ACTION_LOG.CREATE, body: ''},
        SEARCH_CATEGORY_TAG: {screenName: SCREEN_NAME.DEFAULT_SETTING, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_CATEGORY_TAG},
        SEARCH_TTL_TAG: {screenName: SCREEN_NAME.DEFAULT_SETTING, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_TITLE_TAG},
        DELETE_TITLE_TAG: {screenName: SCREEN_NAME.DEFAULT_SETTING + " ＞ " + CONTENT_LOG.CLICK_DELETE_TITLE_TAG, action: ACTION_LOG.DELETE, body: ''},

        UPDATE_INVALID_CONDITION: {screenName: SCREEN_NAME.DEFAULT_SETTING + " ＞ " + CONTENT_LOG.INVALID, action: ACTION_LOG.UPDATE, body: ''},

        CANCEL_BUTTON: {screenName: SCREEN_NAME.DEFAULT_SETTING + " ＞ " + CONTENT_LOG.CANCEL, action: ACTION_LOG.DELETE, body: ''},
        COPY_BUTTON: {screenName: SCREEN_NAME.DEFAULT_SETTING + " ＞ " + CONTENT_LOG.COPY, action: ACTION_LOG.CREATE, body: ''},
        SAVE_BUTTON: {screenName: SCREEN_NAME.DEFAULT_SETTING + " ＞ " + CONTENT_LOG.KEEP , action: ACTION_LOG.CREATE, body: ''},
        
        SEARCH_OFFICE: {screenName:SCREEN_NAME.DEFAULT_SETTING + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: '' },
        CHANGE_FILTER_OFFICE: {screenName:SCREEN_NAME.DEFAULT_SETTING + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.CHANGE_FILTER, action: ACTION_LOG.CHANGE_FILTER, body: ''},
        SELECT_OFFICE: {screenName:SCREEN_NAME.DEFAULT_SETTING + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.OFFICE_SELECTION, action: ACTION_LOG.CREATE, body: ''},
    },
    //#endregion
    //#region ホーム
    // ホーム
    HOME: {
        VIEW: {screenName: SCREEN_NAME.HOME, action: ACTION_LOG.VIEW, body: ''},
        SELECT_BUSINESS_LOCATION: {screenName: SCREEN_NAME.HOME + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.OFFICE_SELECTION, action: ACTION_LOG.CHANGE_FILTER, body: ''},
        SEARCH_DASHBOARD: {screenName: SCREEN_NAME.HOME, action: ACTION_LOG.SEARCH, body: '', content: 'ダッシュボード検索: '},
        SWITCH_DASHBOARD: {screenName: SCREEN_NAME.HOME, action: ACTION_LOG.CHANGE_TAB, body: ''},
        EXPORT_EXCEL: {screenName: SCREEN_NAME.HOME, action: ACTION_LOG.DOWNLOAD_FILE, body: ''},
        SYNC_LATEST_DATA: {screenName: SCREEN_NAME.HOME + " ＞ " + SCREEN_NAME.CONFIRM_UPDATE_LATEST_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
        VIEW_WIDGET_DETAIL: {screenName: SCREEN_NAME.HOME, action: ACTION_LOG.VIEW, body: ''},
        FILTER_WIDGET: {screenName: SCREEN_NAME.HOME, action: ACTION_LOG.CHANGE_FILTER, body: '', content: CONTENT_LOG.CHANGE_FILTER_WIDGET},
        SORT_WIDGET: {screenName: SCREEN_NAME.HOME, action: ACTION_LOG.SORT, body: '', content: CONTENT_LOG.CHANGE_SORT_ORDER_WIDGET},
        SYNC_WIDGET_DATA: {screenName: SCREEN_NAME.HOME, action: ACTION_LOG.UPDATE, body: '', content: CONTENT_LOG.HOME_SYNC_WIDGET_DATA}
    },
    //#endregion
    //#region データソース
    // データソース一覧
    DATASOURCE_LIST: {
        // データソース一覧
        VIEW: {screenName: SCREEN_NAME.DATASOURCE_LIST, action: ACTION_LOG.VIEW, body: ''},
        CHANGE_PAGE: {screenName: SCREEN_NAME.DATASOURCE_LIST, action: ACTION_LOG.CHANGE_PAGE, body: ''},
        SEARCH_FOLDER: {screenName: SCREEN_NAME.DATASOURCE_LIST, action: ACTION_LOG.SEARCH, body: ''},
        CREATE_FOLDER: {screenName: SCREEN_NAME.DATASOURCE_LIST, action: ACTION_LOG.CREATE, body: ''},
        UPDATE_FOLDER: {screenName: SCREEN_NAME.DATASOURCE_LIST, action: ACTION_LOG.UPDATE, body: ''},
        DELETE_FOLDER: {screenName: SCREEN_NAME.DATASOURCE_LIST, action: ACTION_LOG.DELETE, body: ''},
        SEARCH_DATASOURCE: {screenName: SCREEN_NAME.DATASOURCE_LIST, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_DATA_SOURCE},
        DATASOURCE_SELECTION: {screenName: SCREEN_NAME.DATASOURCE_LIST + " ＞ " + CONTENT_LOG.DATA_SOURCE_SELECTION, action: ACTION_LOG.VIEW, body: ''},
        CREATE_DATASOURCE: {screenName: SCREEN_NAME.DATASOURCE_LIST + " ＞ " + SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        DELETE_DATASOURCE: {screenName: SCREEN_NAME.DATASOURCE_LIST, action: ACTION_LOG.DELETE, body: ''},
        // 事業所選択ダイアログ
        SEARCH_OFFICE: {screenName: SCREEN_NAME.DATASOURCE_LIST + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        CHANGE_FILTER_OFFICE: {screenName: SCREEN_NAME.DATASOURCE_LIST + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.CHANGE_FILTER, action: ACTION_LOG.CHANGE_FILTER, body: ''},
        SELECT_OFFICE: {screenName: SCREEN_NAME.DATASOURCE_LIST + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.OFFICE_SELECTION, action: ACTION_LOG.CREATE, body: ''}
    },
    // データソース一覧（テンプレート）
    DATASOURCE_LIST_TEMPLATE: {
        // データソース一覧（テンプレート）
        VIEW: {screenName: SCREEN_NAME.DATASOURCE_LIST_TEMPLATE, action: ACTION_LOG.VIEW, body: ''},
        CHANGE_PAGE: {screenName: SCREEN_NAME.DATASOURCE_LIST_TEMPLATE, action: ACTION_LOG.CHANGE_PAGE, body: ''},
        SEARCH_FOLDER: {screenName: SCREEN_NAME.DATASOURCE_LIST_TEMPLATE, action: ACTION_LOG.SEARCH, body: ''},
        CREATE_FOLDER: {screenName: SCREEN_NAME.DATASOURCE_LIST_TEMPLATE, action: ACTION_LOG.CREATE, body: ''},
        UPDATE_FOLDER: {screenName: SCREEN_NAME.DATASOURCE_LIST_TEMPLATE, action: ACTION_LOG.UPDATE, body: ''},
        DELETE_FOLDER: {screenName: SCREEN_NAME.DATASOURCE_LIST_TEMPLATE, action: ACTION_LOG.DELETE, body: ''},
        SEARCH_DATASOURCE: {screenName: SCREEN_NAME.DATASOURCE_LIST_TEMPLATE, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_DATA_SOURCE},
        DATASOURCE_SELECTION: {screenName: SCREEN_NAME.DATASOURCE_LIST_TEMPLATE + " ＞ " + CONTENT_LOG.DATA_SOURCE_SELECTION, action: ACTION_LOG.VIEW, body: ''},
        CREATE_DATASOURCE: {screenName: SCREEN_NAME.DATASOURCE_LIST_TEMPLATE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        DELETE_DATASOURCE: {screenName: SCREEN_NAME.DATASOURCE_LIST_TEMPLATE, action: ACTION_LOG.DELETE, body: ''},
        // 事業所選択ダイアログ
        SEARCH_OFFICE: {screenName:SCREEN_NAME.DATASOURCE_LIST_TEMPLATE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        CHANGE_FILTER_OFFICE: {screenName: SCREEN_NAME.DATASOURCE_LIST_TEMPLATE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.CHANGE_FILTER, action: ACTION_LOG.CHANGE_FILTER, body: ''},
        SELECT_OFFICE: {screenName: SCREEN_NAME.DATASOURCE_LIST_TEMPLATE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.OFFICE_SELECTION, action: ACTION_LOG.CREATE, body: ''},
    },
    // データソース作成
    CREATE_DATA_SOURCE: {
        // データソース作成
        VIEW: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE, action: ACTION_LOG.VIEW, body: ''},
        RETURN_PREVIOUS_SCREEN: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.RETURN_PREVIOUS_SCREEN},
        SELECT_OFFICE: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.OFFICE_SELECTION, action: ACTION_LOG.UPDATE, body: ''},
        SEARCH_TITLE_AND_TAG: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE, action: ACTION_LOG.SEARCH, body: ''},
        SELECT_TITLE: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + CONTENT_LOG.SELECT_TITLE, action: ACTION_LOG.UPDATE, body: ''},
        SELECT_FOLDER: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        CALCULATION_DATE: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE, action: ACTION_LOG.SHOW_CALENDAR, body: ''},
        BASE_DATE: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE, action: ACTION_LOG.SHOW_CALENDAR, body: ''},
        ADD_USER_DEFINED_COLUMN: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + CONTENT_LOG.ADD_USER_DEFINED, action: ACTION_LOG.CREATE, body: ''},
        EDIT_USER_DEFINED_COLUMN: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + CONTENT_LOG.EDIT_USER_DEFINED, action: ACTION_LOG.UPDATE, body: ''},
        DELETE_USER_DEFINED_COLUMN: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + CONTENT_LOG.DELETE_USER_DEFINED, action: ACTION_LOG.DELETE, body: ''},
        PREVIEW: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + CONTENT_LOG.PREVIEW, action: ACTION_LOG.CREATE, body: ''},
        SAVE: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + CONTENT_LOG.KEEP, action: ACTION_LOG.CREATE, body: ''},
        // 未保存データ確認ダイアログ
        SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE  + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.CREATE, body: ''},
        NOT_SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE  + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.VIEW, body: ''},
        RETURN_ORIGINAL_SCREEN: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE  + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.CLOSE_DIALOG, body: ''},
        // フォルダ選択ダイアログ
        SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        CREATE_FOLDER: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
        SELECT_FOLDER_SELECTION: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
        // 事業所選択ダイアログ
        SEARCH_OFFICE: {screenName:SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        CHANGE_FILTER_OFFICE: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.CHANGE_FILTER, action: ACTION_LOG.CHANGE_FILTER, body: ''},
    },
    // データソース作成（テンプレート）
    CREATE_DATA_SOURCE_TEMPLATE: {
        // データソース作成（テンプレート）
        VIEW: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE, action: ACTION_LOG.VIEW, body: ''},
        RETURN_PREVIOUS_SCREEN: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.RETURN_PREVIOUS_SCREEN},
        SELECT_OFFICE: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.OFFICE_SELECTION, action: ACTION_LOG.UPDATE, body: ''},
        SEARCH_TITLE_AND_TAG: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE, action: ACTION_LOG.SEARCH, body: ''},
        SELECT_TITLE: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + CONTENT_LOG.SELECT_TITLE, action: ACTION_LOG.UPDATE, body: ''},
        SELECT_FOLDER: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        CALCULATION_DATE: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE, action: ACTION_LOG.SHOW_CALENDAR, body: ''},
        BASE_DATE: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE, action: ACTION_LOG.SHOW_CALENDAR, body: ''},
        ADD_USER_DEFINED_COLUMN: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + CONTENT_LOG.ADD_USER_DEFINED, action: ACTION_LOG.CREATE, body: ''},
        EDIT_USER_DEFINED_COLUMN: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + CONTENT_LOG.EDIT_USER_DEFINED, action: ACTION_LOG.UPDATE, body: ''},
        DELETE_USER_DEFINED_COLUMN: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + CONTENT_LOG.DELETE_USER_DEFINED, action: ACTION_LOG.DELETE, body: ''},
        PREVIEW: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + CONTENT_LOG.PREVIEW, action: ACTION_LOG.CREATE, body: ''},
        SAVE: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + CONTENT_LOG.KEEP, action: ACTION_LOG.CREATE, body: ''},
        // 未保存データ確認ダイアログ
        SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.CREATE, body: ''},
        NOT_SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.VIEW, body: ''},
        RETURN_ORIGINAL_SCREEN: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.CLOSE_DIALOG, body: ''},
        // フォルダ選択ダイアログ
        SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        CREATE_FOLDER: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
        SELECT_FOLDER_SELECTION: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
        // 事業所選択ダイアログ
        SEARCH_OFFICE: {screenName:SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        CHANGE_FILTER_OFFICE: {screenName: SCREEN_NAME.CREATE_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.CHANGE_FILTER, action: ACTION_LOG.CHANGE_FILTER, body: ''},
    },
    // データソース編集	
    EDIT_DATA_SOURCE: {
        // データソース編集	
        VIEW: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE, action: ACTION_LOG.VIEW, body: ''},
        RETURN_PREVIOUS_SCREEN: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.RETURN_PREVIOUS_SCREEN},
        SELECT_OFFICE: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.OFFICE_SELECTION, action: ACTION_LOG.UPDATE, body: ''},
        SEARCH_TITLE_AND_TAG: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE, action: ACTION_LOG.SEARCH, body: ''},
        SELECT_TITLE: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + CONTENT_LOG.SELECT_TITLE, action: ACTION_LOG.UPDATE, body: ''},
        SELECT_FOLDER: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        CALCULATION_DATE: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE, action: ACTION_LOG.SHOW_CALENDAR, body: ''},
        BASE_DATE: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE, action: ACTION_LOG.SHOW_CALENDAR, body: ''},
        ADD_USER_DEFINED_COLUMN: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + CONTENT_LOG.ADD_USER_DEFINED, action: ACTION_LOG.CREATE, body: ''},
        EDIT_USER_DEFINED_COLUMN: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + CONTENT_LOG.EDIT_USER_DEFINED, action: ACTION_LOG.UPDATE, body: ''},
        DELETE_USER_DEFINED_COLUMN: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + CONTENT_LOG.DELETE_USER_DEFINED, action: ACTION_LOG.DELETE, body: ''},
        PREVIEW: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + CONTENT_LOG.PREVIEW, action: ACTION_LOG.UPDATE, body: ''},
        SAVE: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + CONTENT_LOG.KEEP, action: ACTION_LOG.CREATE, body: ''},
        SAVE_AS: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + CONTENT_LOG.SAVE_AS, action: ACTION_LOG.CREATE, body: CONTENT_LOG.SAVE_AS},
        DELETE_DATASOURCE: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE, action: ACTION_LOG.DELETE, body: ''},
        // 未保存データ確認ダイアログ
        SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
        NOT_SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.VIEW, body: ''},
        RETURN_ORIGINAL_SCREEN: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.CLOSE_DIALOG, body: ''},
        // フォルダ選択ダイアログ
        SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        CREATE_FOLDER: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
        SELECT_FOLDER_SELECTION: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
        // ウィジェット同期確認ダイアログ
        SEARCH_WIDGET: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + SCREEN_NAME.WIDGET_DIALOG_SELECTION, action: ACTION_LOG.SEARCH, body: ''},
        VIEW_DIALOG_WIDGET: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE+ " ＞ " + SCREEN_NAME.WIDGET_DIALOG_SELECTION, action: ACTION_LOG.VIEW, body: ''},
        SELECT_WIDGET: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE+ " ＞ " + SCREEN_NAME.WIDGET_DIALOG_SELECTION, action: ACTION_LOG.UPDATE, body: ''},
        // 事業所選択ダイアログ
        SEARCH_OFFICE: {screenName:SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        CHANGE_FILTER_OFFICE: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.CHANGE_FILTER, action: ACTION_LOG.CHANGE_FILTER, body: ''},
    },
    // データソース編集（テンプレート）
    EDIT_DATA_SOURCE_TEMPLATE: {
        // データソース編集（テンプレート）
        VIEW: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE, action: ACTION_LOG.VIEW, body: ''},
        RETURN_PREVIOUS_SCREEN: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.RETURN_PREVIOUS_SCREEN},
        SELECT_OFFICE: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.OFFICE_SELECTION, action: ACTION_LOG.UPDATE, body: ''},
        SEARCH_TITLE_AND_TAG: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE, action: ACTION_LOG.SEARCH, body: ''},
        SELECT_TITLE: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + CONTENT_LOG.SELECT_TITLE, action: ACTION_LOG.UPDATE, body: ''},
        SELECT_FOLDER: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        CALCULATION_DATE: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE, action: ACTION_LOG.SHOW_CALENDAR, body: ''},
        BASE_DATE: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE, action: ACTION_LOG.SHOW_CALENDAR, body: ''},
        ADD_USER_DEFINED_COLUMN: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + CONTENT_LOG.ADD_USER_DEFINED, action: ACTION_LOG.CREATE, body: ''},
        EDIT_USER_DEFINED_COLUMN: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + CONTENT_LOG.EDIT_USER_DEFINED, action: ACTION_LOG.UPDATE, body: ''},
        DELETE_USER_DEFINED_COLUMN: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + CONTENT_LOG.DELETE_USER_DEFINED, action: ACTION_LOG.DELETE, body: ''},
        PREVIEW: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + CONTENT_LOG.PREVIEW, action: ACTION_LOG.UPDATE, body: ''},
        SAVE: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + CONTENT_LOG.KEEP, action: ACTION_LOG.UPDATE , body: ''},
        SAVE_AS: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + CONTENT_LOG.SAVE_AS, action: ACTION_LOG.CREATE, body: CONTENT_LOG.SAVE_AS},
        DELETE_DATASOURCE: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE, action: ACTION_LOG.DELETE, body: ''},
        // 未保存データ確認ダイアログ
        SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
        NOT_SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.VIEW, body: ''},
        RETURN_ORIGINAL_SCREEN: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.CLOSE_DIALOG, body: ''},
        // フォルダ選択ダイアログ
        SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        CREATE_FOLDER: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
        SELECT_FOLDER_SELECTION: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
        // ウィジェット同期確認ダイアログ
        SEARCH_WIDGET: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_DIALOG_SELECTION, action: ACTION_LOG.SEARCH, body: ''},
        VIEW_DIALOG_WIDGET: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE+ " ＞ " + SCREEN_NAME.WIDGET_DIALOG_SELECTION, action: ACTION_LOG.VIEW, body: ''},
        SELECT_WIDGET: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE+ " ＞ " + SCREEN_NAME.WIDGET_DIALOG_SELECTION, action: ACTION_LOG.UPDATE, body: ''},
        // 事業所選択ダイアログ
        SEARCH_OFFICE: {screenName:SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        CHANGE_FILTER_OFFICE: {screenName: SCREEN_NAME.EDIT_DATA_SOURCE_TEMPLATE + " ＞ " + SCREEN_NAME.BUSINESS_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.CHANGE_FILTER, action: ACTION_LOG.CHANGE_FILTER, body: ''},
    },
    //#endregion
    //#region ダッシュボード
    //ダッシュボード一覧    
    DASHBOARD_LIST: {
        VIEW: {screenName: SCREEN_NAME.DASHBOARD_LIST, action: ACTION_LOG.VIEW, body: ''},
        CHANGE_PAGE: {screenName: SCREEN_NAME.DASHBOARD_LIST, action: ACTION_LOG.CHANGE_PAGE, body: ''},
        SEARCH_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_LIST, action: ACTION_LOG.SEARCH, body: ''},
        UPDATE_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_LIST, action: ACTION_LOG.UPDATE, body: ''},
        DELETE_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_LIST, action: ACTION_LOG.DELETE, body: ''},
        SEARCH_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_LIST, action: ACTION_LOG.SEARCH, body: ''},
        DASHBOARD_SELECTION: {screenName: SCREEN_NAME.DASHBOARD_LIST + " ＞ " + CONTENT_LOG.DASHBOARD_SELECTION, action: ACTION_LOG.VIEW, body: '', content: ''},
        CREATE_DASHBOARD_DIALOG: {screenName: SCREEN_NAME.DASHBOARD_LIST  + " ＞ " + SCREEN_NAME.DASHBOARD_CREATION_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.DASHBOARD_CREATION},
        UPDATE_DASHBOARD_DIALOG: {screenName: SCREEN_NAME.DASHBOARD_LIST  + " ＞ " + SCREEN_NAME.DASHBOARD_EDITING_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: '', content: ''},
        DELETE_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_LIST, action: ACTION_LOG.DELETE, body: '', content: CONTENT_LOG.DELETE_DASHBOARD},
        //ダッシュボード作成ダイアログ
        DIALOG_ADD_SELECT_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_LIST + " ＞ " + SCREEN_NAME.DASHBOARD_CREATION_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        DIALOG_EDIT_SELECT_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_LIST + " ＞ " + SCREEN_NAME.DASHBOARD_EDITING_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        CREATE_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_LIST + " ＞ " + SCREEN_NAME.DASHBOARD_CREATION_DIALOG, action: ACTION_LOG.CREATE, body: '', content: CONTENT_LOG.ADD_DASHBOARD},
        //ダッシュボード編集ダイアログ
        UPDATE_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_LIST + " ＞ " + SCREEN_NAME.DASHBOARD_EDITING_DIALOG, action: ACTION_LOG.UPDATE, body: '', content: CONTENT_LOG.EDIT_DASHBOARD},
        // フォルダ選択ダイアログ
        SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.DASHBOARD_LIST + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        CREATE_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_LIST + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.CREATE_FOLDER, action: ACTION_LOG.CREATE, body: ''},
        SELECT_FOLDER_SELECTION: {screenName: SCREEN_NAME.DASHBOARD_LIST + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
    },
    //ダッシュボード作成
    DASHBOARD_CREATION: {
        VIEW: {screenName: SCREEN_NAME.DASHBOARD_CREATION, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.VIEW},
        BACK_SCREEN: {screenName: SCREEN_NAME.DASHBOARD_CREATION + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: '', content: ''},
        ADD_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_CREATION + " ＞ " + CONTENT_LOG.ADD_WIDGET, action: ACTION_LOG.VIEW, body: '', content: ''},
        EDIT_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_CREATION + " ＞ " + SCREEN_NAME.DASHBOARD_EDITING, action: ACTION_LOG.VIEW, body: '', content: ''},
        UPDATE_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_CREATION + " ＞ " + CONTENT_LOG.EDIT_WIDGET, action: ACTION_LOG.VIEW, body: '', content: ''},
        DELETE_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_CREATION + " ＞ " + CONTENT_LOG.DELETE_WIDGET, action: ACTION_LOG.DELETE, body: '', content: ''},
        SAVE: {screenName: SCREEN_NAME.DASHBOARD_CREATION + " ＞ " + CONTENT_LOG.KEEP, action: ACTION_LOG.CREATE, body: '', content: CONTENT_LOG.CREATE},
        //未保存データ確認ダイアログ
        YES: {screenName: SCREEN_NAME.DASHBOARD_CREATION + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.CREATE, body: '', content: CONTENT_LOG.SAVE_UNSAVED_DATA},
        YES_VIEW: {screenName: SCREEN_NAME.DASHBOARD_CREATION + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.SAVE_UNSAVED_DATA},
        NO: {screenName: SCREEN_NAME.DASHBOARD_CREATION + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.NOT_SAVE_UNSAVED_DATA},
        CLOSE: {screenName: SCREEN_NAME.DASHBOARD_CREATION + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.CLOSE_DIALOG, body: '', content: CONTENT_LOG.RETURN_TO_ORIGINAL_SCREEN},
        FILTER_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_CREATION, action: ACTION_LOG.CHANGE_FILTER, body: '', content: CONTENT_LOG.CHANGE_FILTER_WIDGET},
        SORT_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_CREATION, action: ACTION_LOG.SORT, body: '', content: CONTENT_LOG.CHANGE_SORT_ORDER_WIDGET},
    },
    DASHBOARD_EDITING: {
        VIEW: {screenName: SCREEN_NAME.DASHBOARD_EDITING, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.VIEW},
        BACK_SCREEN: {screenName: SCREEN_NAME.DASHBOARD_EDITING + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.VIEW, body: '', content: ''},
        CLOSE: {screenName: SCREEN_NAME.DASHBOARD_EDITING + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.CLOSE_DIALOG, body: '', content: CONTENT_LOG.RETURN_TO_ORIGINAL_SCREEN},
        EDIT_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_EDITING  + " ＞ " + CONTENT_LOG.EDIT_TXT_DASHBOARD, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.EDIT_DASHBOARD},
        ADD_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_EDITING + " ＞ " + CONTENT_LOG.ADD_WIDGET, action: ACTION_LOG.VIEW, body: '', content: ''},
        UPDATE_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_EDITING + " ＞ " + CONTENT_LOG.EDIT_WIDGET, action: ACTION_LOG.VIEW, body: '', content: ''},
        DELETE_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_EDITING + " ＞ " + CONTENT_LOG.DELETE_WIDGET, action: ACTION_LOG.DELETE, body: '', content: ''},
        SAVE_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_EDITING + " ＞ " + CONTENT_LOG.KEEP, action: ACTION_LOG.UPDATE, body: '', content: CONTENT_LOG.SAVE},
        DOWNLOAD_IMAGE: {screenName: SCREEN_NAME.DASHBOARD_EDITING + " ＞ " + CONTENT_LOG.DOWNLOAD, action: ACTION_LOG.DOWNLOAD_FILE, body: '', content: CONTENT_LOG.DOWNLOAD},
        CREATE_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_EDITING, action: ACTION_LOG.SHOW_DIALOG, body: '', content: ''},
        DELETE_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_EDITING, action: ACTION_LOG.DELETE, body: '', content: CONTENT_LOG.REMOVE},
        //ダッシュボード作成ダイアログ
        DIALOG_ADD_SELECT_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_EDITING + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.FOLDER_SELECTION},
        DASHBOARD_CREATION_DIALOG_CREATE_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_EDITING + " ＞ " + SCREEN_NAME.DASHBOARD_CREATION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
        // フォルダ選択ダイアログ
        SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.DASHBOARD_EDITING + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        CREATE_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_EDITING + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.CREATE_FOLDER, action: ACTION_LOG.CREATE, body: ''},
        SELECT_FOLDER_SELECTION: {screenName: SCREEN_NAME.DASHBOARD_EDITING + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
        FILTER_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_EDITING, action: ACTION_LOG.CHANGE_FILTER, body: '', content: CONTENT_LOG.CHANGE_FILTER_WIDGET},
        SORT_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_EDITING, action: ACTION_LOG.SORT, body: '', content: CONTENT_LOG.CHANGE_SORT_ORDER_WIDGET},
        YES_VIEW: {screenName: SCREEN_NAME.DASHBOARD_EDITING + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.SAVE_UNSAVED_DATA},
    },
    DASHBOARD_TEMPLATE_LIST: {
        VIEW: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST, action: ACTION_LOG.VIEW, body: ''},
        CHANGE_PAGE: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST, action: ACTION_LOG.CHANGE_PAGE, body: ''},
        SEARCH_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST, action: ACTION_LOG.SEARCH, body: ''},
        UPDATE_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST, action: ACTION_LOG.UPDATE, body: ''},
        DELETE_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST, action: ACTION_LOG.DELETE, body: ''},
        SEARCH_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST, action: ACTION_LOG.SEARCH, body: ''},
        DASHBOARD_SELECTION: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST + " ＞ " + CONTENT_LOG.DASHBOARD_SELECTION, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.DASHBOARD_SELECTION},
        CREATE_DASHBOARD_DIALOG: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST + " ＞ " + SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: '', content: ''},
        UPDATE_DASHBOARD_DIALOG: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST + " ＞ " + SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: '', content: ''},
        DELETE_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST, action: ACTION_LOG.DELETE, body: '', content: CONTENT_LOG.DELETE_DASHBOARD},
        //ダッシュボード作成ダイアログ
        DIALOG_ADD_SELECT_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST + " ＞ " + SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        DIALOG_EDIT_SELECT_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST + " ＞ " + SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        CREATE_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST + " ＞ " + SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION_DIALOG, action: ACTION_LOG.CREATE, body: '', content: CONTENT_LOG.ADD_DASHBOARD},
        //ダッシュボード編集ダイアログ
        UPDATE_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST + " ＞ " + SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING_DIALOG, action: ACTION_LOG.UPDATE, body: '', content: CONTENT_LOG.EDIT_DASHBOARD},
        DIALOG_SELECT_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST + " ＞ " + SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        // フォルダ選択ダイアログ
        SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        CREATE_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.CREATE_FOLDER , action: ACTION_LOG.CREATE, body: ''},
        SELECT_FOLDER_SELECTION: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_LIST + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
    },
    DASHBOARD_TEMPLATE_CREATION: {
        VIEW: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.VIEW},
        BACK_SCREEN: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: '', content:''},
        ADD_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION + " ＞ " + CONTENT_LOG.ADD_WIDGET, action: ACTION_LOG.VIEW, body: '', content: ''},
        UPDATE_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION + " ＞ " + CONTENT_LOG.EDIT_WIDGET, action: ACTION_LOG.VIEW, body: '', content: ''},
        DELETE_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION + " ＞ " + CONTENT_LOG.DELETE_WIDGET, action: ACTION_LOG.DELETE, body: '', content: ''},
        SAVE: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION + " ＞ " + CONTENT_LOG.KEEP, action: ACTION_LOG.CREATE, body: '', content: ''},
        SEARCH: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION, action: ACTION_LOG.SEARCH, body: ''},
        //未保存データ確認ダイアログ
        YES: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.CREATE, body: '', content: CONTENT_LOG.SAVE_UNSAVED_DATA},
        YES_VIEW: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.SAVE_UNSAVED_DATA},
        NO: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.NOT_SAVE_UNSAVED_DATA},
        CLOSE: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.CLOSE_DIALOG, body: '', content: CONTENT_LOG.RETURN_TO_ORIGINAL_SCREEN},
        FILTER_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION, action: ACTION_LOG.CHANGE_FILTER, body: '', content: CONTENT_LOG.CHANGE_FILTER_WIDGET},
        SORT_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_CREATION, action: ACTION_LOG.SORT, body: '', content: CONTENT_LOG.CHANGE_SORT_ORDER_WIDGET},

    },
    DASHBOARD_TEMPLATE_EDITING: {
        VIEW: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.VIEW},
        BACK_SCREEN: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG , action: ACTION_LOG.VIEW, body: '', content: ''},
        SEARCH: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH + ': '},
        EDIT_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING  + " ＞ " + CONTENT_LOG.EDIT_TXT_DASHBOARD, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.EDIT_DASHBOARD},
        ADD_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING + " ＞ " + CONTENT_LOG.ADD_WIDGET, action: ACTION_LOG.VIEW, body: '', content: ''},
        UPDATE_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING + " ＞ " + CONTENT_LOG.EDIT_WIDGET, action: ACTION_LOG.VIEW, body: '', content: ''},
        DELETE_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING + " ＞ " + CONTENT_LOG.DELETE_WIDGET, action: ACTION_LOG.DELETE, body: '', content: ''},
        SAVE_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING + " ＞ " + CONTENT_LOG.KEEP, action: ACTION_LOG.UPDATE, body: '', content: CONTENT_LOG.SAVE},
        DOWNLOAD_IMAGE: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING + " ＞ " + CONTENT_LOG.DOWNLOAD, action: ACTION_LOG.DOWNLOAD_FILE, body: '', content: CONTENT_LOG.DOWNLOAD},
        SAVE_AS: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.SAVE_AS},
        CREATE_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        DELETE_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING, action: ACTION_LOG.DELETE, body: '', content: CONTENT_LOG.REMOVE},
        //ダッシュボード作成ダイアログ
        DIALOG_ADD_SELECT_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.FOLDER_SELECTION},
        DASHBOARD_CREATION_DIALOG_CREATE_DASHBOARD: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING + " ＞ " + SCREEN_NAME.DASHBOARD_CREATION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
        // フォルダ選択ダイアログ
        SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        CREATE_FOLDER: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.CREATE_FOLDER, action: ACTION_LOG.CREATE, body: ''},
        SELECT_FOLDER_SELECTION: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
        FILTER_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING, action: ACTION_LOG.CHANGE_FILTER, body: '', content: CONTENT_LOG.CHANGE_FILTER_WIDGET},
        SORT_WIDGET: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING, action: ACTION_LOG.SORT, body: '', content: CONTENT_LOG.CHANGE_SORT_ORDER_WIDGET},
        YES_VIEW: {screenName: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING + " ＞ " + SCREEN_NAME.UNSAVED_CONFIRM_DIALOG, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.SAVE_UNSAVED_DATA},
    },
    //#endregion
    //#region ウィジェット
    //ウィジェット一覧
    WIDGET_LIST: {
        VIEW: {screenName: SCREEN_NAME.WIDGET_LIST, action: ACTION_LOG.VIEW, body: ''},
        CHANGE_PAGE: {screenName: SCREEN_NAME.WIDGET_LIST, action: ACTION_LOG.CHANGE_PAGE, body: ''},
        SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.WIDGET_LIST, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_FOLDER},
        CREATE_FOLDER: {screenName: SCREEN_NAME.WIDGET_LIST+ " ＞ " + CONTENT_LOG.CREATE_FOLDER, action: ACTION_LOG.CREATE, body: ''},
        UPDATE_FOLDER: {screenName: SCREEN_NAME.WIDGET_LIST+ " ＞ " + CONTENT_LOG.EDIT_FOLDER, action: ACTION_LOG.UPDATE, body: ''},
        DELETE_FOLDER: {screenName: SCREEN_NAME.WIDGET_LIST+ " ＞ " + CONTENT_LOG.DELETE_FOLDER, action: ACTION_LOG.DELETE, body: ''},
        SEARCH_WIDGET: {screenName: SCREEN_NAME.WIDGET_LIST, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_WIDGET},
        VIEW_WIDGET_SELECTION: {screenName: SCREEN_NAME.WIDGET_LIST, action: ACTION_LOG.VIEW, body: ''},
        VIEW_WIDGET_CREATION: {screenName: SCREEN_NAME.WIDGET_LIST + " ＞ " + SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION, action: ACTION_LOG.VIEW, body: ''},
        DELETE_WIDGET: {screenName: SCREEN_NAME.WIDGET_LIST + " ＞ " + CONTENT_LOG.DELETE_WIDGET, action: ACTION_LOG.DELETE, body: ''},
    },
    WIDGET_LIST_TEMPLATE: {
        VIEW: {screenName: SCREEN_NAME.WIDGET_LIST_TEMPLATE, action: ACTION_LOG.VIEW, body: ''},
        CHANGE_PAGE: {screenName: SCREEN_NAME.WIDGET_LIST_TEMPLATE, action: ACTION_LOG.CHANGE_PAGE, body: ''},
        SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.WIDGET_LIST_TEMPLATE, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_FOLDER},
        CREATE_FOLDER: {screenName: SCREEN_NAME.WIDGET_LIST_TEMPLATE, action: ACTION_LOG.CREATE, body: ''},
        UPDATE_FOLDER: {screenName: SCREEN_NAME.WIDGET_LIST_TEMPLATE, action: ACTION_LOG.UPDATE, body: ''},
        DELETE_FOLDER: {screenName: SCREEN_NAME.WIDGET_LIST_TEMPLATE, action: ACTION_LOG.DELETE, body: ''},
        SEARCH_WIDGET: {screenName: SCREEN_NAME.WIDGET_LIST_TEMPLATE, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_WIDGET},
        VIEW_WIDGET_SELECTION: {screenName: SCREEN_NAME.WIDGET_LIST_TEMPLATE, action: ACTION_LOG.VIEW, body: ''},
        VIEW_WIDGET_CREATION: {screenName: SCREEN_NAME.WIDGET_LIST_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION, action: ACTION_LOG.VIEW, body: ''},
        DELETE_WIDGET: {screenName: SCREEN_NAME.WIDGET_LIST_TEMPLATE, action: ACTION_LOG.DELETE, body: ''},
    },
    //ウィジェット作成
    WIDGET_SETTING_CREATION: {
        //ウィジェット作成 
        MAIN: {
            VIEW: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION, action: ACTION_LOG.VIEW, body: ''},
            RETURN_TO_PREVIOUS_SCREEN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + CONTENT_LOG.RETURN_PREVIOUS_SCREEN, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            SELECT_DATA_SOURCE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + CONTENT_LOG.SELECT_DATASOURCE, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            ADD_USER_DEFINED_COLUMNS_AND_GOALS: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + CONTENT_LOG.ADD_USER_DEFINE_COLUMN, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            REFINE_ITEMS: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.ITEM_NARROWING_DOWN},
            SELECT_FOLDER: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + CONTENT_LOG.FOLDER_SELECTION, action: ACTION_LOG.SHOW_DIALOG, body: '', content: ''},
            SELECT_TEMPLATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + CONTENT_LOG.TEMPLATE_SELECTION, action: ACTION_LOG.VIEW, body: '', content: ''},
            CHANGE_VIEW_TYPE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION, action: ACTION_LOG.CHANGE_VIEW_TYPE, body: ''},
            CANCEL: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + CONTENT_LOG.CANCEL, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.CANCEL},
            SAVE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + CONTENT_LOG.KEEP, action: ACTION_LOG.CREATE, body: '', content: CONTENT_LOG.KEEP},
            DELETE_WIDGET: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION, action: ACTION_LOG.DELETE, body: ''},
        },
        //未保存データ確認ダイアログ
        UNSAVED_DATA_CONFIRMATION_DIALOG: {
            SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.UNSAVED_DATA_CONFIRMATION_DIALOG + " ＞ " + CONTENT_LOG.SAVE_UNSAVED_DATA, action: ACTION_LOG.CREATE, body: '', content: '' },
            DO_NOT_SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.UNSAVED_DATA_CONFIRMATION_DIALOG + " ＞ " + CONTENT_LOG.NOT_SAVE_UNSAVED_DATA, action: ACTION_LOG.VIEW, body: '', content: ''},
            RETURN_TO_THE_ORIGINAL_SCREEN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.UNSAVED_DATA_CONFIRMATION_DIALOG + " ＞ " + CONTENT_LOG.RETURN_TO_ORIGINAL_SCREEN, action: ACTION_LOG.CLOSE_DIALOG, body: '', content: ''},
        },
        //データソース選択ダイアログ
        DATA_SOURCE_SELECTION_DIALOG: {
            SEARCH: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.DATA_SOURCE_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_DATA_SOURCE},
            CREATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.DATA_SOURCE_SELECTION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
        },
        //ユーザー定義列・目標値追加ダイアログ
        ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG: {
            CREATE_DEFINED_COLUMN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.ADD_USER_DEFINED, action: ACTION_LOG.CREATE, body: ''},
            UPDATE_DEFINED_COLUMN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.EDIT_USER_DEFINED, action: ACTION_LOG.UPDATE, body: ''},
            DELETE_DEFINED_COLUMN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.DELETE_USER_DEFINED, action: ACTION_LOG.DELETE, body: ''},
            CREATE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.ADD_TARGET_VALUE, action: ACTION_LOG.CREATE, body: ''},
            UPDATE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.EDIT_TARGET_VALUE, action: ACTION_LOG.UPDATE, body: ''},
            DELETE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.DELETE_TARGET_VALUE, action: ACTION_LOG.DELETE, body: ''},
            SAVE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.SAVE_TARGET_VALUE, action: ACTION_LOG.CREATE, body: ''},
        },
        //絞込み条件ダイアログ
        FILTER_CONDITION_DIALOG: {
            CREATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FILTER_CONDITION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
            UPDATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FILTER_CONDITION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
            DELETE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FILTER_CONDITION_DIALOG, action: ACTION_LOG.DELETE, body: ''},
        },
        //フォルダ選択ダイアログ
        FOLDER_SELECTION_DIALOG: {
            SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
            CREATE_FOLDER: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
            SELECT_FOLDER_SELECTION: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FOLDER_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.FOLDER_SELECTION, action: ACTION_LOG.UPDATE, body: '', content: ''},
        },
    },
    //ウィジェット作成
    WIDGET_SETTING_EDITING: {
        //ウィジェット作成 
        MAIN: {
            VIEW: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING, action: ACTION_LOG.VIEW, body: ''},
            RETURN_TO_PREVIOUS_SCREEN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + CONTENT_LOG.RETURN_PREVIOUS_SCREEN, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            SELECT_DATA_SOURCE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + CONTENT_LOG.SELECT_DATASOURCE, action: ACTION_LOG.SHOW_DIALOG, body: '',},
            ADD_USER_DEFINED_COLUMNS_AND_GOALS: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + CONTENT_LOG.ADD_USER_DEFINE_COLUMN, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            REFINE_ITEMS: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.ITEM_NARROWING_DOWN},
            SELECT_FOLDER: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + CONTENT_LOG.FOLDER_SELECTION, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.FOLDER_SELECTION},
            SELECT_TEMPLATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + CONTENT_LOG.TEMPLATE_SELECTION, action: ACTION_LOG.VIEW, body: '', content: ''},
            CHANGE_VIEW_TYPE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING, action: ACTION_LOG.CHANGE_VIEW_TYPE, body: ''},
            CANCEL: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + CONTENT_LOG.CANCEL, action: ACTION_LOG.VIEW, body: '', content: ''},
            SAVE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + CONTENT_LOG.KEEP, action: ACTION_LOG.UPDATE, body: '', content: ''},
            DELETE_WIDGET: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING, action: ACTION_LOG.DELETE, body: ''},
        },
        //未保存データ確認ダイアログ
        UNSAVED_DATA_CONFIRMATION_DIALOG: {
            SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.UNSAVED_DATA_CONFIRMATION_DIALOG + " ＞ " + CONTENT_LOG.SAVE_UNSAVED_DATA, action: ACTION_LOG.UPDATE, body: '', content: ''},
            DO_NOT_SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.UNSAVED_DATA_CONFIRMATION_DIALOG +  " ＞ " + CONTENT_LOG.NOT_SAVE_UNSAVED_DATA, action: ACTION_LOG.VIEW, body: '', content: ''},
            RETURN_TO_THE_ORIGINAL_SCREEN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.UNSAVED_DATA_CONFIRMATION_DIALOG + " ＞ " + CONTENT_LOG.RETURN_TO_ORIGINAL_SCREEN, action: ACTION_LOG.CLOSE_DIALOG, body: '', content: ''},
        },
        //データソース選択ダイアログ
        DATA_SOURCE_SELECTION_DIALOG: {
            SEARCH: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.DATA_SOURCE_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_DATA_SOURCE},
            CREATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.DATA_SOURCE_SELECTION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
        },
        //ユーザー定義列・目標値追加ダイアログ
        ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG: {
            CREATE_DEFINED_COLUMN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.ADD_USER_DEFINED, action: ACTION_LOG.CREATE, body: ''},
            UPDATE_DEFINED_COLUMN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.EDIT_USER_DEFINED, action: ACTION_LOG.UPDATE, body: ''},
            DELETE_DEFINED_COLUMN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.DELETE_USER_DEFINED, action: ACTION_LOG.DELETE, body: ''},
            CREATE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.ADD_TARGET_VALUE, action: ACTION_LOG.CREATE, body: ''},
            UPDATE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.EDIT_TARGET_VALUE, action: ACTION_LOG.UPDATE, body: ''},
            DELETE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.DELETE_TARGET_VALUE, action: ACTION_LOG.DELETE, body: ''},
            SAVE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.SAVE_TARGET_VALUE, action: ACTION_LOG.CREATE, body: ''},
        },
        //絞込み条件ダイアログ
        FILTER_CONDITION_DIALOG: {
            CREATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FILTER_CONDITION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
            UPDATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FILTER_CONDITION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
            DELETE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FILTER_CONDITION_DIALOG, action: ACTION_LOG.DELETE, body: ''},
        },
        //フォルダ選択ダイアログ
        FOLDER_SELECTION_DIALOG: {
            SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
            CREATE_FOLDER: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
            SELECT_FOLDER_SELECTION: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FOLDER_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.FOLDER_SELECTION, action: ACTION_LOG.UPDATE, body: '', content: ''},
        },
    },
    //ウィジェット作成
    WIDGET_SETTING_CREATION_TEMPLATE: {
        //ウィジェット作成 
        MAIN: {
            VIEW: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE, action: ACTION_LOG.VIEW, body: ''},
            RETURN_TO_PREVIOUS_SCREEN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + CONTENT_LOG.RETURN_PREVIOUS_SCREEN, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            SELECT_DATA_SOURCE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + CONTENT_LOG.SELECT_DATASOURCE, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            ADD_USER_DEFINED_COLUMNS_AND_GOALS: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + CONTENT_LOG.ADD_USER_DEFINE_COLUMN, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            REFINE_ITEMS: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE , action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.ITEM_NARROWING_DOWN},
            VALIDITY_PERIOD: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + CONTENT_LOG.VALIDITY_PERIOD, action: ACTION_LOG.SHOW_CALENDAR, body: '', content: ''},
            SELECT_FOLDER: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + CONTENT_LOG.FOLDER_SELECTION, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.FOLDER_SELECTION},
            SELECT_TEMPLATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + CONTENT_LOG.TEMPLATE_SELECTION, action: ACTION_LOG.VIEW, body: '', content: ''},
            CHANGE_VIEW_TYPE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE, action: ACTION_LOG.CHANGE_VIEW_TYPE, body: ''},
            CANCEL: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + CONTENT_LOG.CANCEL, action: ACTION_LOG.VIEW, body: '', content: CONTENT_LOG.CANCEL},
            SAVE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + CONTENT_LOG.KEEP, action: ACTION_LOG.CREATE, body: '', content: ''},
            DELETE_WIDGET: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE, action: ACTION_LOG.DELETE, body: ''},
        },
        //未保存データ確認ダイアログ
        UNSAVED_DATA_CONFIRMATION_DIALOG: {
            SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.UNSAVED_DATA_CONFIRMATION_DIALOG + " ＞ " + CONTENT_LOG.SAVE_UNSAVED_DATA, action: ACTION_LOG.CREATE, body: '', content: '' },
            DO_NOT_SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.UNSAVED_DATA_CONFIRMATION_DIALOG +  " ＞ " + CONTENT_LOG.NOT_SAVE_UNSAVED_DATA, action: ACTION_LOG.VIEW, body: '', content: ''},
            RETURN_TO_THE_ORIGINAL_SCREEN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.UNSAVED_DATA_CONFIRMATION_DIALOG + " ＞ " + CONTENT_LOG.RETURN_TO_ORIGINAL_SCREEN, action: ACTION_LOG.CLOSE_DIALOG, body: '', content: ''},
        },
        //データソース選択ダイアログ
        DATA_SOURCE_SELECTION_DIALOG: {
            SEARCH: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.DATA_SOURCE_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_DATA_SOURCE},
            CREATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.DATA_SOURCE_SELECTION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
        },
        //ユーザー定義列・目標値追加ダイアログ
        ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG: {
            CREATE_DEFINED_COLUMN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.ADD_USER_DEFINED, action: ACTION_LOG.CREATE, body: ''},
            UPDATE_DEFINED_COLUMN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.EDIT_USER_DEFINED, action: ACTION_LOG.UPDATE, body: ''},
            DELETE_DEFINED_COLUMN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.DELETE_USER_DEFINED, action: ACTION_LOG.DELETE, body: ''},
            CREATE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.ADD_TARGET_VALUE, action: ACTION_LOG.CREATE, body: ''},
            UPDATE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.EDIT_TARGET_VALUE, action: ACTION_LOG.UPDATE, body: ''},
            DELETE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.DELETE_TARGET_VALUE, action: ACTION_LOG.DELETE, body: ''},
            SAVE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.SAVE_TARGET_VALUE, action: ACTION_LOG.CREATE, body: ''},
        },
        //絞込み条件ダイアログ
        FILTER_CONDITION_DIALOG: {
            CREATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FILTER_CONDITION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
            UPDATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FILTER_CONDITION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
            DELETE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FILTER_CONDITION_DIALOG, action: ACTION_LOG.DELETE, body: ''},
        },
        //フォルダ選択ダイアログ
        FOLDER_SELECTION_DIALOG: {
            SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
            CREATE_FOLDER: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
            SELECT_FOLDER_SELECTION: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_CREATION_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FOLDER_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.FOLDER_SELECTION, action: ACTION_LOG.UPDATE, body: '', content: ''},
        },
    },
    //ウィジェット作成
    WIDGET_SETTING_EDITING_TEMPLATE: {
        //ウィジェット作成 
        MAIN: {
            VIEW: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE, action: ACTION_LOG.VIEW, body: ''},
            RETURN_TO_PREVIOUS_SCREEN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + CONTENT_LOG.RETURN_PREVIOUS_SCREEN, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            SELECT_DATA_SOURCE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + CONTENT_LOG.SELECT_DATASOURCE, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            ADD_USER_DEFINED_COLUMNS_AND_GOALS: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + CONTENT_LOG.ADD_USER_DEFINE_COLUMN, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            REFINE_ITEMS: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.ITEM_NARROWING_DOWN},
            VALIDITY_PERIOD: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + CONTENT_LOG.VALIDITY_PERIOD, action: ACTION_LOG.SHOW_CALENDAR, body: '', content: ''},
            SELECT_FOLDER: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + CONTENT_LOG.FOLDER_SELECTION, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.FOLDER_SELECTION},
            SELECT_TEMPLATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + CONTENT_LOG.TEMPLATE_SELECTION, action: ACTION_LOG.VIEW, body: '', content: ''},
            CHANGE_VIEW_TYPE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE, action: ACTION_LOG.CHANGE_VIEW_TYPE, body: ''},
            CANCEL: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + CONTENT_LOG.CANCEL, action: ACTION_LOG.VIEW, body: '', content: ''},
            SAVE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + CONTENT_LOG.KEEP, action: ACTION_LOG.UPDATE, body: '', content: ''},
            DELETE_WIDGET: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE, action: ACTION_LOG.DELETE, body: ''},
        },
        //未保存データ確認ダイアログ
        UNSAVED_DATA_CONFIRMATION_DIALOG: {
            SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.UNSAVED_DATA_CONFIRMATION_DIALOG + " ＞ " + CONTENT_LOG.SAVE_UNSAVED_DATA, action: ACTION_LOG.UPDATE, body: '', content: ''},
            DO_NOT_SAVE_UNSAVED_DATA: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.UNSAVED_DATA_CONFIRMATION_DIALOG +  " ＞ " + CONTENT_LOG.NOT_SAVE_UNSAVED_DATA, action: ACTION_LOG.VIEW, body: '', content: ''},
            RETURN_TO_THE_ORIGINAL_SCREEN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.UNSAVED_DATA_CONFIRMATION_DIALOG + " ＞ " + CONTENT_LOG.RETURN_TO_ORIGINAL_SCREEN, action: ACTION_LOG.CLOSE_DIALOG, body: '', content: ''},
        },
        //データソース選択ダイアログ
        DATA_SOURCE_SELECTION_DIALOG: {
            SEARCH: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.DATA_SOURCE_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_DATA_SOURCE},
            CREATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.DATA_SOURCE_SELECTION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
        },
        //ユーザー定義列・目標値追加ダイアログ
        ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG: {
            CREATE_DEFINED_COLUMN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.ADD_USER_DEFINED, action: ACTION_LOG.CREATE, body: ''},
            UPDATE_DEFINED_COLUMN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.EDIT_USER_DEFINED, action: ACTION_LOG.UPDATE, body: ''},
            DELETE_DEFINED_COLUMN: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.DELETE_USER_DEFINED, action: ACTION_LOG.DELETE, body: ''},
            CREATE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.ADD_TARGET_VALUE, action: ACTION_LOG.CREATE, body: ''},
            UPDATE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.EDIT_TARGET_VALUE, action: ACTION_LOG.UPDATE, body: ''},
            DELETE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.DELETE_TARGET_VALUE, action: ACTION_LOG.DELETE, body: ''},
            SAVE_TARGET_VALUE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG + " ＞ " + CONTENT_LOG.SAVE_TARGET_VALUE, action: ACTION_LOG.CREATE, body: ''},
        },
        //絞込み条件ダイアログ
        FILTER_CONDITION_DIALOG: {
            CREATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FILTER_CONDITION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
            UPDATE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FILTER_CONDITION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
            DELETE: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FILTER_CONDITION_DIALOG, action: ACTION_LOG.DELETE, body: ''},
        },
        //フォルダ選択ダイアログ
        FOLDER_SELECTION_DIALOG: {
            SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
            CREATE_FOLDER: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
            SELECT_FOLDER_SELECTION: {screenName: SCREEN_NAME.WIDGET_SETTING.WIDGET_EDITING_TEMPLATE + " ＞ " + SCREEN_NAME.WIDGET_SETTING.FOLDER_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.FOLDER_SELECTION , action: ACTION_LOG.UPDATE, body: '', content: ''},
        },
    },
    
    //テンプレート選択
    TEMPLATE_SELECTION: {
        //テンプレート選択
        MAIN: {
            VIEW: {screenName: SCREEN_NAME.TEMPLATE_SELECTION.MAIN, action: ACTION_LOG.VIEW, body: ''},
            SEARCH: {screenName: SCREEN_NAME.TEMPLATE_SELECTION.MAIN, action: ACTION_LOG.SEARCH, body: '', content : CONTENT_LOG.SEARCH_FOLDER},
            VIEW_RETURN_TO_PREVIOUS_SCREEN: {screenName: SCREEN_NAME.TEMPLATE_SELECTION.MAIN + " ＞ " + CONTENT_LOG.RETURN_PREVIOUS_SCREEN, action: ACTION_LOG.VIEW, body: ''},
            SHOW_DIALOG: {screenName: SCREEN_NAME.TEMPLATE_SELECTION.MAIN, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        },
        //事業所選択ダイアログ
        BUSINESS_LOCATION_SELECTION_DIALOG: {
            VIEW: {screenName: SCREEN_NAME.TEMPLATE_SELECTION.MAIN + " ＞ " + SCREEN_NAME.TEMPLATE_SELECTION.BUSINESS_LOCATION_SELECTION_DIALOG, action: ACTION_LOG.VIEW, body: ''},
            SEARCH: {screenName: SCREEN_NAME.TEMPLATE_SELECTION.MAIN + " ＞ " + SCREEN_NAME.TEMPLATE_SELECTION.BUSINESS_LOCATION_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
            CHANGE_FILTER: {screenName: SCREEN_NAME.TEMPLATE_SELECTION.MAIN + " ＞ " + SCREEN_NAME.TEMPLATE_SELECTION.BUSINESS_LOCATION_SELECTION_DIALOG, action: ACTION_LOG.CHANGE_FILTER, body: ''},
            SELECT_BUSINESS_LOCATION: {screenName: SCREEN_NAME.TEMPLATE_SELECTION.MAIN + " ＞ " + SCREEN_NAME.TEMPLATE_SELECTION.BUSINESS_LOCATION_SELECTION_DIALOG, action: ACTION_LOG.VIEW, body: ''},
        },
        //データソース情報登録ダイアログ
        DATA_SOURCE_INFORMATION_REGISTRATION_DIALOG: {
            VIEW: {screenName:SCREEN_NAME.TEMPLATE_SELECTION.MAIN + " ＞ " + SCREEN_NAME.TEMPLATE_SELECTION.DATA_SOURCE_INFORMATION_REGISTRATION_DIALOG, action: ACTION_LOG.VIEW, body: ''},
            FOLDER_SHOW_DIALOG: {screenName:SCREEN_NAME.TEMPLATE_SELECTION.MAIN + " ＞ " + SCREEN_NAME.TEMPLATE_SELECTION.DATA_SOURCE_INFORMATION_REGISTRATION_DIALOG + " ＞ " + SCREEN_NAME.TEMPLATE_SELECTION.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            VIEW_DATA_SOURCE_INFORMATION_REGISTRATION_DIALOG: {screenName:SCREEN_NAME.TEMPLATE_SELECTION.MAIN + " ＞ " + SCREEN_NAME.TEMPLATE_SELECTION.DATA_SOURCE_INFORMATION_REGISTRATION_DIALOG, action: ACTION_LOG.VIEW, body: ''},
        },
        //フォルダ選択ダイアログ
        FOLDER_SELECTION_DIALOG: {
            SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.TEMPLATE_SELECTION.MAIN + " ＞ " + SCREEN_NAME.TEMPLATE_SELECTION.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
            CREATE_FOLDER: {screenName: SCREEN_NAME.TEMPLATE_SELECTION.MAIN + " ＞ " + SCREEN_NAME.TEMPLATE_SELECTION.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
            UPDATE_FOLDER: {screenName: SCREEN_NAME.TEMPLATE_SELECTION.MAIN + " ＞ " + SCREEN_NAME.TEMPLATE_SELECTION.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
        },
        //確認ダイアログ
        CONFIRMATION_DIALOG: {
            VIEW: {screenName: SCREEN_NAME.TEMPLATE_SELECTION.MAIN + " ＞ " + SCREEN_NAME.TEMPLATE_SELECTION.CONFIRMATION_DIALOG, action: ACTION_LOG.VIEW, body: ''},
            SAVE: {screenName: SCREEN_NAME.TEMPLATE_SELECTION.MAIN + " ＞ " + SCREEN_NAME.TEMPLATE_SELECTION.CONFIRMATION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
        }
    },
    MENU_TEMPLATE: {
        VIEW: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION, action: ACTION_LOG.VIEW, body: ''},
        SELECT_FREQUENTLY_USED: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + CONTENT_LOG.FREQUENTLY_USED_SELECTION, action: ACTION_LOG.VIEW, body: ''},
        SEARCH_FOR_TEMPLATES: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + CONTENT_LOG.SEARCH_FOR_TEMPLATES, action: ACTION_LOG.SEARCH, body: ''},
        SELECT_HISTORY: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + CONTENT_LOG.HISTORY_SELECTION, action: ACTION_LOG.VIEW, body: ''},
        SHOW_MORE: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.SHOW_MORE, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        SELECT_LATEST_PUBLISHED: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + CONTENT_LOG.SELECT_LATEST_PUBLISHED, action: ACTION_LOG.VIEW, body: ''},
        SELECT_TEMPLATE: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + CONTENT_LOG.SELECT_TEMPLATE, action: ACTION_LOG.VIEW, body: ''},

        TEMPLATE_DIALOG: {
            SEARCH_TEMPLATE_DIALOG: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_FOR_TEMPLATES + ": "},
            SELECT_TEMPLATE_DIALOG: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION_DIALOG, action: ACTION_LOG.VIEW, body: ''},
        },
        
        WIDGET_CREATION: {
            VIEW: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION, action: ACTION_LOG.VIEW, body: ''},
            GO_BACK: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + CONTENT_LOG.GO_BACK, action: ACTION_LOG.VIEW, body: ''},
            SELECT_BUSINESS: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + CONTENT_LOG.OFFICE_SELECTION, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            DATASOURCE_SAVE_DESTINATION: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DATASOURCE_SAVE_DESTINATION_SETTING_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            WIDGET_DISPLAY_DESTINATION: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + CONTENT_LOG.WIDGET_DISPLAY_DESTINATION, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            DISPLAY_FORMAT: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + CONTENT_LOG.DISPLAY_FORMAT, action: ACTION_LOG.CHANGE_VIEW_TYPE, body: ''},
            ADD: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + CONTENT_LOG.ADD, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        },

        WIDGET_FOLDER_DIAGLOG: {
            WIDGET_FOLDER_SEARCH: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_SAVE_DESTINATION_SETTING_DIALOG + " ＞ " + CONTENT_LOG.WIDGET_FOLDER_SEARCH, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_WIDGET},
            CREATE_FOLDER: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_SAVE_DESTINATION_SETTING_DIALOG+ " ＞ " + CONTENT_LOG.CREATE_FOLDER, action: ACTION_LOG.CREATE, body: ''},
        },

        BUSINESS_LOCATION_SELECTION_DIALOG: {
            SEARCH_OFFICE: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.BUSINESS_LOCATION_SELECTION_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
            CHANGE_FILTER_OFFICE: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.BUSINESS_LOCATION_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.CHANGE_FILTER, action: ACTION_LOG.CHANGE_FILTER, body: ''},
            SELECT_OFFICE: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.BUSINESS_LOCATION_SELECTION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
        },

        DATASOURCE_SAVE_DESTINATION_SETTING_DIALOG: {
            ADD: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DATASOURCE_SAVE_DESTINATION_SETTING_DIALOG + " ＞ " + CONTENT_LOG.ADD, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            SELECT: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DATASOURCE_SAVE_DESTINATION_SETTING_DIALOG + " ＞ " + CONTENT_LOG.SETTINGS_CONFIRM_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
        },

        DATASOURCE_CREATE_FOLDER_DIALOG: {
            SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + CONTENT_LOG.DATA_SOURCE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.CREATE_FOLDER_DIALOG, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_FOLDER},
            CREATE_FOLDER: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + CONTENT_LOG.DATA_SOURCE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.CREATE_FOLDER_DIALOG, action: ACTION_LOG.CREATE, body: ''},
            UPDATE_FOLDER_SELECTION: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + CONTENT_LOG.DATA_SOURCE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.CREATE_FOLDER_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
        },

        DASHBOARD_SELECTION_DIALOG: {
            SHOW_DIALOG_ADD_FOLDER: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DASHBOARD_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.ADD_FOLDER_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            CREATE_FOLDER: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DASHBOARD_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.ADD_FOLDER_DIALOG, action: ACTION_LOG.CREATE, body: ''},
            SHOW_DIALOG_ADD_DASHBOARD: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DASHBOARD_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.ADD_DASHBOARD_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            SELECT_DASHBOARD: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DASHBOARD_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.DASHBOARD_SELECTION, action: ACTION_LOG.UPDATE, body: ''},
        },

        DASHBOARD_CREATION_DIALOG: {
            SEARCH_FOLDER_SELECTION: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DASHBOARD_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.CREATE_FOLDER, action: ACTION_LOG.SEARCH, body: ''},
            CREATE_FOLDER: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DASHBOARD_SELECTION_DIALOG + " ＞ " + CONTENT_LOG.CREATE_FOLDER, action: ACTION_LOG.CREATE, body: ''},
            CREATE_DASHBOARD: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DASHBOARD_SELECTION_DIALOG + " ＞ " + SCREEN_NAME.DASHBOARD_CREATION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
            DIALOG_EDIT_SELECT_FOLDER : {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DASHBOARD_SELECTION_DIALOG + " ＞ " + SCREEN_NAME.DASHBOARD_EDITING_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            DIALOG_ADD_SELECT_FOLDER: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DASHBOARD_SELECTION_DIALOG + " ＞ " + SCREEN_NAME.DASHBOARD_CREATION_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            SELECT_FOLDER_SELECTION: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DASHBOARD_SELECTION_DIALOG + " ＞ " + SCREEN_NAME.FOLDER_SELECTION_DIALOG, action: ACTION_LOG.UPDATE, body: ''},
            CREATE: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DASHBOARD_SELECTION_DIALOG + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.DASHBOARD_CREATION_DIALOG, action: ACTION_LOG.CREATE, body: ''},
        },

        CONFIRMATION_DIALOG: {
            NEXT: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.CONFIRMATION_DIALOG + " ＞ " + CONTENT_LOG.NEXT, action: ACTION_LOG.SHOW_DIALOG, body: ''},
            CANCEL: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.CONFIRMATION_DIALOG + " ＞ " + CONTENT_LOG.CANCEL, action: ACTION_LOG.CLOSE_DIALOG, body: ''},
        },

        SETTINGS_DIALOG: {
            SETTINGS: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.SETTINGS_DIALOG + " ＞ " + CONTENT_LOG.SETTINGS_CONFIRM_DIALOG, action: ACTION_LOG.VIEW, body: ''},
            BACK: {screenName: SCREEN_NAME.MENU_TEMPLATE.TEMPLATE_SELECTION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.WIDGET_CREATION + " ＞ " + SCREEN_NAME.MENU_TEMPLATE.SETTINGS_DIALOG + " ＞ " + CONTENT_LOG.GO_BACK, action: ACTION_LOG.SHOW_DIALOG, body: ''},
        },
    },
    //#endregion
    //#region 在宅復帰
    // 在宅復帰
    RETURN_HOME: {
        VIEW: {screenName: SCREEN_NAME.RETURN_HOME, action: ACTION_LOG.VIEW, body: ''},
        SEARCH_BUSINESS: {screenName: SCREEN_NAME.RETURN_HOME, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_OFFICE},
        SWITCHING_BUSINESS: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + CONTENT_LOG.SWITCH_OFFICE, action: ACTION_LOG.CHANGE_TAB, body: ''},
        SHOW_CALENDAR_START: {screenName: SCREEN_NAME.RETURN_HOME, action: ACTION_LOG.SHOW_CALENDAR, body: ''},
        SHOW_CALENDAR_END: {screenName: SCREEN_NAME.RETURN_HOME, action: ACTION_LOG.SHOW_CALENDAR, body: ''},
        SHOW_DIALOG: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG, action: ACTION_LOG.SHOW_DIALOG, body: '', content: CONTENT_LOG.SHOW_DIALOG},
        CREATE_IMPORT_DATA: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + CK_REPORT_TEXT.DATA_ACQUISITION, action: ACTION_LOG.CREATE, body: ''},
        VIEW_DIRECT_INPUT: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + CK_REPORT_TEXT.DIRECT_INPUT, action: ACTION_LOG.VIEW, body: ''},
        SAVE_DIRECT_INPUT: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + CONTENT_LOG.KEEP, action: ACTION_LOG.CREATE, body: ''},
        // 自動取得設定ダイアログ
        VIEW_DIALOG: {screenName: SCREEN_NAME.RETURN_HOME_DIALOG, action: ACTION_LOG.VIEW, body: ''},
        SEARCH_BUSINESS_ESTABLISHMENT: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG, action: ACTION_LOG.SEARCH, body: '', content: CONTENT_LOG.SEARCH_OFFICE},
        SWITCHING_BUSINESS_ESTABLISHMENT: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG, action: ACTION_LOG.CHANGE_TAB, body: '', content: CONTENT_LOG.SWITCH_OFFICE},
        CREATE_CONDITION: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG, action: ACTION_LOG.CREATE, body: '', content: CONTENT_LOG.ADD_CONDITION},
        DELETE_CONDITION: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG + ' ＞ ' + CK_REPORT_TEXT.DELETE_CONDITION, action: ACTION_LOG.DELETE, body: ''},
        SEARCH_CONDITION: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG, action: ACTION_LOG.SEARCH, body: ''},
        SEARCH_SCHEMAS: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG, action: ACTION_LOG.SEARCH, body: '', content:  'スキーマ検索: '},
        SHOW_CALENDAR_START_DIALOG: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG + ' ＞ ' + CONTENT_LOG.START_MONTH, action: ACTION_LOG.SHOW_CALENDAR, body: ''},
        SHOW_CALENDAR_END_DIALOG: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG + ' ＞ ' + CONTENT_LOG.END_MONTH, action: ACTION_LOG.SHOW_CALENDAR, body: ''},
        SEARCH_RECORD: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG, action: ACTION_LOG.SEARCH, body: '', content: '記録項目検索: '},
        CREATE_RECORD: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG, action: ACTION_LOG.CREATE, body: '', content: '記録項目追加'},
        DELETE_RECORD: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG + ' ＞ ' + CK_REPORT_TEXT.DELETE_RECORD, action: ACTION_LOG.DELETE, body: '', content: '記録項目削除: ' },
        SAVE_CONDITION_CREATE: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG + ' ＞ ' + CONTENT_LOG.CREATE + ' ＞ ' + CONTENT_LOG.SAVE_CONDITION, action: ACTION_LOG.CREATE, body: ''},
        SAVE_CONDITION_UPDATE: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG + ' ＞ ' + CONTENT_LOG.UPDATE + ' ＞ ' + CONTENT_LOG.SAVE_CONDITION, action: ACTION_LOG.UPDATE, body: ''},
        SAVE_AND_CLOSE_CONDITION_CREATE: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG + ' ＞ ' + CONTENT_LOG.CREATE + ' ＞ ' + CONTENT_LOG.SAVE_AND_CLOSE_CONDITION, action: ACTION_LOG.CREATE, body: ''},
        SAVE_AND_CLOSE_CONDITION_UPDATE: {screenName: SCREEN_NAME.RETURN_HOME + ' ＞ ' + SCREEN_NAME.RETURN_HOME_DIALOG + ' ＞ ' + CONTENT_LOG.UPDATE + ' ＞ ' + CONTENT_LOG.SAVE_AND_CLOSE_CONDITION, action: ACTION_LOG.UPDATE, body: ''},
    },
    //#endregion
    
};
