import { Injectable, OnDestroy } from '@angular/core';

interface WorkerTask {
  data: any;
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
}

@Injectable({
  providedIn: 'root',
})
export class PivotTableSortWorkerService implements OnDestroy {
  private workers: Worker[] = [];
  private busyWorkers: Set<Worker> = new Set();
  private taskQueue: WorkerTask[] = [];
  private maxWorkers = 4; // Giới hạn số lượng worker tối đa

  constructor() {
  }

  public postMessage(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const task: WorkerTask = { data, resolve, reject };
      this.taskQueue.push(task);
      this.processQueue(); // Tiến hành xử lý hàng đợi
    });
  }

  // Xử lý hàng đợi
  private processQueue(): void {
    if (this.taskQueue.length === 0) return;

    // Tìm worker không bận
    const availableWorker = this.workers.find(worker => !this.busyWorkers.has(worker));

    if (availableWorker) {
      // Có worker trống, dùng nó để xử lý
      this.runTaskOnWorker(availableWorker, this.taskQueue.shift()!);
    } else if (this.workers.length < this.maxWorkers) {
      // Nếu không có worker trống và số lượng worker hiện tại chưa đạt giới hạn, tạo worker mới
      const newWorker = this.createWorker();
      this.runTaskOnWorker(newWorker, this.taskQueue.shift()!);
    }
  }

  // Tạo worker mới
  private createWorker(): Worker {
    const worker = new Worker(
      new URL('src/app/workers/pivot-table-sort.worker.ts', import.meta.url),
      { name: `pivot_table_sort_worker_${this.workers.length}`, type: 'module' }
    );
    this.workers.push(worker);
    return worker;
  }

  // Chạy task trên worker
  private runTaskOnWorker(worker: Worker, task: WorkerTask): void {
    this.busyWorkers.add(worker);

    // Lắng nghe kết quả trả về từ worker
    worker.onmessage = ({ data }) => {
      this.busyWorkers.delete(worker); // Đánh dấu worker là rảnh
      task.resolve(data); // Trả kết quả về caller
      this.processQueue(); // Xử lý các yêu cầu tiếp theo trong hàng đợi
    };

    // Xử lý lỗi từ worker
    worker.onerror = (error) => {
      this.busyWorkers.delete(worker); // Đánh dấu worker là rảnh
      task.reject(error); // Trả lỗi về caller
      this.processQueue(); // Xử lý các yêu cầu tiếp theo trong hàng đợi
    };

    // Gửi dữ liệu vào worker để xử lý
    worker.postMessage(task.data);
  }

  // Hủy tất cả workers
  public terminateAllWorkers(): void {
    this.workers.forEach(worker => worker.terminate());
    this.workers = [];
    this.busyWorkers.clear();
  }

  ngOnDestroy(): void {
    this.terminateAllWorkers();
  }
}
