
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { PivotTableConfig } from '../../../../../app/models/response/widget.ro';
import { PivotTableData, buildTokenizer, indexChecking, mergeCellVertically, replaceNameNashi, selectType, sortArray } from '../../../../../app/_helper/helper';
import { DateFormat, DeviceType, FooterOption, FormatType, SummaryColumnOption } from '../../../../../app/enum/common-enum';
import { WidgetService } from '../../../../../app/services/modules/widget.service';
import { DetectDeviceService, IDeviceType } from 'src/app/services/detect-device.service';
import { JapanDateFormat, PivotFooterOptions, TableLimits } from '../../../../../app/const/const';
import * as moment from 'moment';
import { drop, head, isNumber } from 'lodash';
import { cloneDeep, fill, last, orderBy } from 'lodash';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogFilterTableComponent } from '../../../../../app/module/dialog-filter-table/dialog-filter-table.component';
import { ROUTE_PATH } from '../../../../../app/const/route-path';
import { COMMON_TEXT } from 'src/app/const/text-common';
import { SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { CONTENT_LOG } from 'src/app/config/saucer-log.config';
import { PivotTableSortWorkerService } from 'src/app/services/pivot-table-sort-worker.service';
import { ProcessLoadingStackService } from 'src/app/services/loading-stack.service';

@Component({
  selector: 'pivot-table-chart',
  templateUrl: './table-chart.component.html',
  styleUrls: ['./table-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableChartComponent implements OnChanges, OnInit, AfterViewInit ,OnDestroy{

  // Input, Output
  @Input() filterLog: {screenName: string, action: string, body: string} | null = null;
  @Input() sortLog: {screenName: string, action: string, body: string} | null = null;
  @Input() widget: any;
  @Input() data: PivotTableData
  @Input() sortParams: any = null;
  @Input() isFilterByOffice?: boolean = false;
  @Input() isKeepOriginCell: boolean = false;
  @Input() tableSize = { Columns: 0, Rows: 0};
  @Output() handleSortData = new EventEmitter();
  @Output() handleClick = new EventEmitter();

  // viewchild
  @ViewChild('table', { read: ElementRef, static: false }) table: ElementRef|undefined;

  // variables for the pivot table
  headers: Array<Array<{ value: string | number, colspan: number, rowspan: number, formattype: FormatType, datatype?: string, cssStyle?: string }>> = []
  originalBody: Array<Array<{ value: string | number, rowspan: number, formattype: FormatType, cssStyle?: string }>> = []
  allBodyRows: Array<Array<{ value: string | number, rowspan: number, formattype: FormatType, cssStyle?: string }>> = []
  allBodyRowsFiltered: Array<Array<{ value: string | number, rowspan: number, formattype: FormatType, cssStyle?: string }>> = []
  displayedRows: Array<Array<{ value: string | number, rowspan: number, formattype: FormatType, datatype?: string, cssStyle?: string }>> | null = null;
  footers: Array<{ value: string | number, colspan: number, cssStyle?: string }> = []
  config: PivotTableConfig
  deviceType: string;
  DeviceType = DeviceType;
  pIndexs: any[] = [];
  footertype: string;
  summaryColumnType: SummaryColumnOption;
  COMMON_TEXT = COMMON_TEXT;
  colnLength: number = 0;
  bodyLength: number = 0;
  replaceNameNashi = replaceNameNashi
  filterData: any[] = []
  filterSelecteds: any[] = []
  isFilter: boolean = false
  sortArr: string[] = [];
  TableLimits = TableLimits;

  ref: DynamicDialogRef | null = null;

  // Pagination by scroll
  readonly pageSize: number = 1000; // The number of rows to be displayed per page (1000 rows per page).
  readonly rowHeight: number = 30; // The height of each row in pixels (30px per row).
  readonly buffer: number = 100;  // The number of additional rows loaded to create a smooth scrolling experience, used to pre-load data during scrolling (100 extra rows).
  currentPage: number = 1; // The index of the page currently being viewed by the user (starting from page 1).
  previousPage: number = 1; // The index of the page that the user viewed before the current page (used to track the previously viewed page).

  isSpecialHandleFooter: boolean = false;
  startIndexForFormat: number;
  jumpStepForFormat: number;

  isSorting = false;
  constructor(
    private widgetService: WidgetService,
    private detectDeviceService: DetectDeviceService,
    private processLoadingStackService: ProcessLoadingStackService,
    private cdr: ChangeDetectorRef,
    public modalService: DialogService,
    private saucerLogService: SaucerLogService,
    private pivotTableSortWorkerService: PivotTableSortWorkerService
    ) {
    this.detectDeviceService.currentDevice.subscribe((device: IDeviceType) => {
      if (device) this.deviceType = device.type;
    });
  }
  ngOnDestroy(): void {
    this.headers = [];
    this.originalBody = []
    this.allBodyRows = [];
    this.displayedRows = [];
    this.footers = [];
    this.config = new PivotTableConfig();


    this.filterData = []
    this.filterSelecteds = []
    this.isFilter = false
    this.sortArr = [];
    this.table = undefined;
    
    this.pivotTableSortWorkerService.terminateAllWorkers();
  }


  async ngOnInit(): Promise<void> {

    this.colnLength = this.data?.table?.headers ? this.data?.table?.headers[0]?.length : 0;
    this.bodyLength = this.data?.table?.body?.length;

    // subscribe for change footer type in widget setting
    this.widgetService.footertype$.subscribe((type: string | undefined) => {
      this.footertype = type || ""
      this.recalculateFooter();
    })

    //If having footers need to recalculate the footers
    this.widgetService.summaryColumntype$.subscribe((type: string | undefined) => {
      this.summaryColumnType = type as SummaryColumnOption;
      this.recalculateFooter();
    })
    
    // Filter for the dashboard widget
    let pathname = window.location.pathname;
    if (
      pathname?.includes(ROUTE_PATH.DASHBOARD_DETAIL) ||
      pathname?.includes(ROUTE_PATH.HOME)) {
        this.isFilter = true
      } else {
        this.isFilter = false
    }
  }

  specialColProcess() {
    if(!this.data.config?.values) return;
    if(this.data.config?.values.length == 0) return;

    this.isSpecialHandleFooter = this.data.config?.values.some(e => e?.columnname == "PRVHOUR");
    if(this.isSpecialHandleFooter) {
      let colIndex = this.data.config?.values.findIndex(e => e.columnname == "PRVHOUR");
      this.startIndexForFormat = (this.data.config?.rows ? this.data.config?.rows.length ?? 0 : 0) + colIndex;
      this.jumpStepForFormat = this.data.config?.values.length;
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if(this.sortParams) this.sortArr = this.sortParams?.sortArr || [];
    if (this.data.config) {
      this.specialColProcess();
      if(this.filterSelecteds?.length > 0) return
      this.config = this.data.config
      this.footertype = this.config?.footers[0]?.formattype || ""
      this.summaryColumnType = this.config.summaryColumns[0]?.formattype as SummaryColumnOption
      this.pIndexs = this.config.values.map((x: any, i: number) => {
        return x.formattype === FormatType.Percent ? i : -1
      }).filter(x => x != -1)
  
      // clone the object and replace special character "\" => "¥"
      let headers = this.data?.table?.headers?.map(x =>{
        for (let key in x){
          if (typeof x[key] == 'object') {
            if (typeof x[key].value == 'string') {
              x[key].value = x[key].value.replace(/\\/g, "¥");
            }
          }
        }
        return [...x];
      });
      
      let body = this.data?.table?.body?.map(x =>{
        for (let key in x){
          if (typeof x[key] == 'object') {
            if (typeof x[key].value == 'string') {
              x[key].value = x[key].value.replace(/\\/g, "¥");
            }
          }
        }
        return [...x];
      });

      this.config.isKeepOriginTable = this.isKeepOriginCell;
      this.headers = mergeCellHeader(headers, this.summaryColumnType, this.config)
      this.originalBody = mergeCellVertically(body, this.config)
      const isSortGrp = this.sortArr.length == 0 || this.sortArr?.filter(s=> s != 'none')?.length == 0 ? true : false;
      if(this.originalBody?.length > 0 && isSortGrp ) {
        const index = this.originalBody[0]?.findIndex(s=>s.formattype?.toString()?.includes(FormatType.Group));
        if(index != -1) {
          const tokenizer = await buildTokenizer();
          this.allBodyRows = sortArray([], this.originalBody, 'none', (x: any) => x[index].value, index, tokenizer);
        }
      } 

      let isRunWorker = false;
      if(this.sortArr.length == 0) {
        this.sortArr = fill(Array(last(this.headers)?.length), 'none')
      } else if(!this.isSorting) {
        //this.processLoadingStackService.loadingSomething("onChangesTableChart", true);
        let countHeader = last(this.headers)?.length || 0;
        if(this.sortArr?.length != countHeader) {
          if(this.sortArr?.length > countHeader)  this.sortArr = this.sortArr.splice(0, countHeader);  
          else {
            let countDifference = countHeader - this.sortArr?.length || 0;
            for(let i = 0; i< countDifference; i++) {
              this.sortArr.push('none');
            }  
          }
          this.handleSortData.emit({ sortArr: this.sortArr, headers: last(this.headers) });
        }
        this.allBodyRows = cloneDeep(this.originalBody)

        const headerValues = this.headers.slice(-1)[0].map(header => header.value);
       
        let dataWorker = {
          typeWorker: "sortPivotTable",
          sortArr: this.sortArr,
          config: this.config,
          bodyRender: this.allBodyRows,
          headerValues: headerValues
        }

        if (typeof Worker !== 'undefined') {
          isRunWorker = true;
          try {
            const result = await this.pivotTableSortWorkerService.postMessage(dataWorker);
            this.allBodyRows = result;
            this.initDisplayedRows();
            this.recalculateFooter();
            //this.processLoadingStackService.loadingSomething("onChangesTableChart", false);
          } catch (error) {
            //this.processLoadingStackService.loadingSomething("onChangesTableChart", false);
          }
        } else {
          //this.processLoadingStackService.loadingSomething("onChangesTableChart", false);
        }
      }
      if(!isRunWorker && !this.isSorting) {
        this.allBodyRows = cloneDeep(this.originalBody);
        this.initDisplayedRows();
        this.recalculateFooter();
      }
      this.isSorting = false;
    }
  }

  ngAfterViewInit() {
    this.table?.nativeElement?.addEventListener('scroll', this.handleScrollTable.bind(this));
  }

  handleScrollTable() {
    const scrollTop = this.table?.nativeElement.scrollTop;
    const firstVisibleRowIndex = Math.floor(scrollTop / this.rowHeight);
    const rowsInView = Math.ceil(this.table?.nativeElement.offsetHeight / this.rowHeight);
    const lastVisibleRowIndex = firstVisibleRowIndex + rowsInView;
    this.currentPage = Math.ceil(lastVisibleRowIndex / this.pageSize);
    let allPage = Math.ceil(this.allBodyRows.length / this.pageSize);

    if (this.previousPage != this.currentPage) {
      if (this.currentPage <= allPage) {
        this.displayedRows = this.fetchViewportData();
        this.previousPage = this.currentPage;
      } else {
        this.currentPage = this.previousPage;
      }
    }
    this.cdr.detectChanges();
  }

  fetchViewportData() {

    // Extract the relevant slice of bodyRender for the current page
    let slicedRows;
    if (this.currentPage == 1) {
      slicedRows = cloneDeep(this.allBodyRows.slice(0, this.pageSize + this.buffer));
    } else {
      let offsetRows = (this.currentPage - 1) * this.pageSize - this.buffer;
      slicedRows = cloneDeep(this.allBodyRows.slice(offsetRows, this.currentPage * this.pageSize));
    }

    if (slicedRows && slicedRows?.length != 0) {
      // Recalculate rowspan for the last part (end of the sliced data)
      slicedRows = this.recalculateRowspan(slicedRows, 'end');
      // Recalculate rowspan for the first part (beginning of the sliced data)
      slicedRows = this.recalculateRowspan(slicedRows, 'start');

    }
    return slicedRows;
  }

  initDisplayedRows() {
    this.displayedRows = cloneDeep(this.allBodyRows.slice(0,this.pageSize + this.buffer));

    // Recalculate rowspan for the last part (end of the sliced data)
    this.displayedRows = this.recalculateRowspan(this.displayedRows, 'end');
    this.cdr.detectChanges();
  }

  scrollToTop() {
    this.currentPage = 1;
    this.previousPage = 1;
    if (this.table) {
      let elementScroll = this.table?.nativeElement;
      if (elementScroll) {
        elementScroll.scrollTop = 0;
      }
    }
  }

  recalculateRowspan(slicedRows: any[][], type: 'start' | 'end') {
    if (!this.displayedRows || this.displayedRows.length == 0) return slicedRows;
    let numCols = this.displayedRows[0].length;
    // Initialize arrays to keep track of row span counts and update flags
    let rowSpanCounts = Array(numCols).fill(0);
    let columnsToUpdate = Array(numCols).fill(true);
    if (type == 'start') {
      // Calculate the row span counts for the first row
      for (let row = 0; row < slicedRows.length; row++) {
        if (columnsToUpdate.every(flag => !flag)) break;
        for (let col = 0; col < numCols; col++) {
          if (columnsToUpdate[col] && slicedRows[row][col].rowspan === 0) {
            rowSpanCounts[col]++;
          } else {
            columnsToUpdate[col] = false;
          }
        }
      }
      // Update the row span values for the first row based on calculated counts
      slicedRows[0].forEach((cell, index) => cell.rowspan = (cell.rowspan === 0 ? rowSpanCounts[index] : cell.rowspan));
    }
    
    if (type == 'end') {
      for (let row = slicedRows.length - 1; row >= 0; row--) {
        if (columnsToUpdate.every(flag => !flag)) break;
        
        for (let col = 0; col < numCols; col++) {
          if (columnsToUpdate[col] && slicedRows[row][col].rowspan === 0) {
            rowSpanCounts[col]++;
          } else {
            if (columnsToUpdate[col]) {
              columnsToUpdate[col] = false;
              if (slicedRows[row][col].rowspan == rowSpanCounts[col]) {
                rowSpanCounts[col] = 0;
              } else {
                rowSpanCounts[col]++;
              }
            }
          }
        }
      }
      rowSpanCounts.forEach((num, colIndex) => {
        if (num != 0) slicedRows[slicedRows.length - num][colIndex].rowspan = num;
      })
    }
    return slicedRows;
  }


  beautify(value: any, formattype?: string, i?: number, datatype?: string, isHeaderValue?: boolean) {
    if(typeof value === 'object' && value != null && typeof value.value === 'undefined' )  return COMMON_TEXT.VALUE_EMPTY;
    if((typeof value === 'number' && isNaN(value)) || value === "NaN" || value == Infinity || value == -Infinity) return COMMON_TEXT.VALUE_EMPTY;  
    //stupid javascript
    if(value === "0.00") return value;
    //in file helper.ts line  roundDown() functiion sometimes return value -0.
    if(value === -0) value = 0;
    const { rows, columns, values } = this.config
    const { Sum, Average } = SummaryColumnOption
    if (isNumber(i) && i >= rows.length && this.pIndexs.length > 0) {
      const isPercent = this.pIndexs.includes((i - rows.length) % values.length) && !isNaN(value);
      const haveSummaryColumn = this.summaryColumnType !== null && this.summaryColumnType !== undefined;
      const isSumOrAvg = [Sum, Average].includes(this.summaryColumnType);
      const isBeforeSummaryColumn = i < Number(last(this.headers)?.length) - values.length;
    
      if (isPercent && !haveSummaryColumn) {
        return Number(value).toFixed(2) + '%';
      } else if (isPercent && haveSummaryColumn) {
        if (isBeforeSummaryColumn || (!isBeforeSummaryColumn && isSumOrAvg)) return  this.formatDataType(Number(value).toFixed(2), 'FLOAT') +  '%';
      }
    }
    const allItems = [...rows, ...columns];
    const haveGroup = allItems.some(item => item.formattype?.includes(FormatType.Group));
    if(haveGroup && value == undefined && isNumber(i) && i >= rows.length) {
      value = 0;
    }

    if (!formattype) {
      if(datatype != null && datatype != "") {
        return this.formatDataType(value, datatype);
      }
      else {
        //isHeaderValue = true is apply beautify for headers of table.
        if(!isHeaderValue) {
          // The purpose of this method to format string '1,000.21'
          if(typeof value == 'string') {
            let stringNumber: string = value;
            let containDot = stringNumber.includes(".");
            if(containDot == true) {
              let numberArray = stringNumber.split(".");
              if( numberArray.length == 2 && (!Number.isNaN(Number(numberArray[0].replace(",", "")))) ||  !Number.isNaN(Number(numberArray[1]))) {
                let containComma = numberArray[0].includes(",");
                return containComma ? value : Number(stringNumber).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              }
              return value;
            }
            else return value;
          }
          else 
          {
            let lastHeaders = last(this.headers) || [];
            if(i && i < lastHeaders.length) { 
              let col = lastHeaders[i];
              let formatType = col? values.find(v => v.displayname == col.value)?.formattype || undefined : undefined;
              const isAvg = formatType == FormatType.Average ? true: false;
              if(!isAvg) return isNumber(value) ? this.formatDataType(value, "FLOAT") : value;
              else return isNumber(value) ?  Number(value).toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2
             }): value
            }
            else return isNumber(value) ? this.formatDataType(value, "FLOAT") : value;
          }
        }
        else  return isNumber(value) ? this.formatDataType(value, "FLOAT") : value;
      }
    }

    // string is not empty and not just whitespace
    if (!/\S/.test(String(value))) return value
    
    const type = selectType(formattype, 'A')
    if (!JapanDateFormat[type!]) return value
  
    return moment(value,'YYYY-MM-DD').format(JapanDateFormat[type] as string);

  }

  formatDataType(value: any, datatype: any) {
    if(value == null ) return "";

    switch (datatype.toUpperCase()) {
      case "VARCHAR":
        return value;
      case "INT":
      case "FLOAT":
        return value.toLocaleString();
      case "DATETIME":
        value = value.trim();
        if(value == "") return "";
        return moment(value).format(DateFormat.FULL_SHORT_DASH_DATE_TIME);
      default:
        return value;
    }
  }

  formatFooterValue(value: any, index: number): any {
    const { rows, values } = this.config
    const { Count, CountUnique,Average, Sum } = FooterOption
    const footerType = this.footertype as FooterOption
    const haveSummaryColumn = this.summaryColumnType !== null && this.summaryColumnType != undefined;
    const isSumOrAvg = [Sum, Average].includes(footerType);
    const numberOfNormalColumns = Number(last(this.headers)?.length) - rows.length - (haveSummaryColumn ? values.length : 0);
    const isBeforeSummaryColumn = index - rows.length < numberOfNormalColumns;
    
    if (index < rows.length || footerType === Count || footerType === CountUnique) return value
    
    const valueIndex = (index - rows.length) % values.length
    const isPercentColumn = this.pIndexs.includes(valueIndex)

    if(isNaN(value) || value == Infinity || value == -Infinity)
    {
      if(!this.isSpecialHandleFooter) return COMMON_TEXT.VALUE_EMPTY;
      let isOk =  indexChecking(index, this.startIndexForFormat, this.jumpStepForFormat);
      if(!isOk) return COMMON_TEXT.VALUE_EMPTY;
      return (value == "NaN") ? COMMON_TEXT.VALUE_EMPTY : value;

    }
    if (!isPercentColumn || !isSumOrAvg) {
      if(footerType == FooterOption.Average) {
        let number = Number.parseFloat(value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        return number;
      }
      else {
        return this.formatDataType(value, "FLOAT")
      }
    }

    if (isBeforeSummaryColumn) {
      if (footerType === Sum) {
        if ( value >= 99.9){
          return '100%'
        }else if (value == 0){
          return '0%'
        }else {
          return Number(value).toFixed(2) + '%'
        }
      }
      if (footerType === Average) return Number(value).toFixed(2) + '%'
    } else {
      if (footerType === Sum) {
        if (this.summaryColumnType === SummaryColumnOption.Sum) {
          if(values.filter((v: any) => v.pivotfiltertype)?.length > 0) {
            return value >= 99.9 ? '100%': Number(value).toFixed(2) + '%'
          } else if (value == 0){
            return '0%'
          }
          else return  Number(value).toFixed(2) + '%'
        }
        if (this.summaryColumnType === SummaryColumnOption.Average) return Number(Number(value).toFixed(2)) + '%'
      }
      if (footerType === Average) {
        if ([SummaryColumnOption.Sum, SummaryColumnOption.Average].includes(this.summaryColumnType)) return Number(value).toFixed(2) + '%'
      }
    }
    return isNumber(value) ? this.formatDataType(value, "FLOAT"): value ;
  }

  async onSortCol(colIndex: number, refreshSort: boolean) {
    this.isSorting = true;
    //this.processLoadingStackService.loadingSomething("onSortColTableChart", true);
    if(!refreshSort) {
      if (this.sortArr[colIndex] == 'none') {
        this.sortArr[colIndex] = 'asc'
      } else if (this.sortArr[colIndex] == 'asc') {
        this.sortArr[colIndex] = 'desc'
      } else {
        this.sortArr[colIndex] = 'none'
      }
    }
    this.scrollToTop()
    let countHeader = last(this.headers)?.length || 0;
    while (this.sortArr?.length < countHeader) {
      this.sortArr.push('none');
    }
    if (this.sortArr.findIndex(x => x !== 'none') === -1) {
      if(this.filterSelecteds.length && this.filterSelecteds.some(x => x.value)) {
        this.allBodyRows = cloneDeep(this.allBodyRowsFiltered);
      } else {
        this.allBodyRows = cloneDeep(this.originalBody);
      }
      this.initDisplayedRows();
      //this.processLoadingStackService.loadingSomething("onSortColTableChart", false);
      this.handleSortData.emit({ sortArr: this.sortArr, headers: last(this.headers) });
      this.handleClick.emit();
      return;
    }

    const headerValues = this.headers.slice(-1)[0].map(header => header.value);
    let dataWorker = {
      typeWorker: "sortPivotTable",
      sortArr: this.sortArr,
      config: this.config,
      bodyRender: this.allBodyRows,
      headerValues: headerValues
    }
    if (typeof Worker !== 'undefined') {
      try {
        const result = await this.pivotTableSortWorkerService.postMessage(dataWorker);
        this.allBodyRows = result;
        this.initDisplayedRows();
        //this.processLoadingStackService.loadingSomething("onSortColTableChart", false);
      } catch (error) {
        //this.processLoadingStackService.loadingSomething("onSortColTableChart", false);
      }
    } else {
      //this.processLoadingStackService.loadingSomething("onSortColTableChart", false);
    }
    this.handleSortData.emit({ sortArr: this.sortArr, headers: last(this.headers) });
    this.handleClick.emit();

    //Log
    if(this.sortLog) {
      this.saucerLogService.action({
        content: CONTENT_LOG.CHANGE_SORT_ORDER_WIDGET + "[ widgetCd: " + this.widget.widgetCd + " widgetName: " + this.widget.widgetName + " ] " + JSON.stringify({ sortArr: this.sortArr, headers: last(this.headers) })
      }, { 
        action: this.sortLog
      });
    }
  }

  filterPivotTableWorker(data: any) : Promise<any[]> {
    // Create a new Web Worker instance
    const worker = new Worker(new URL('../../../../workers/pivot-table-filter.worker.ts', import.meta.url), { name: 'pivot_table_filter_worker', type: 'module' });     
   return new Promise((resolve, reject) => {
     worker.onmessage = ({ data }) => {
       resolve(data);
       worker.terminate()
     };
     worker.onerror = (error) => {
       reject(error);
     };
     worker.postMessage(data);
   });
 }

  showDialogFilter(index: any) {
    let bodyFilter: any[] = [];
    if(this.filterSelecteds.length > 0) {
      let dataSelected = cloneDeep(this.filterSelecteds)
      dataSelected.map(ft => {
        if(ft.index == index) 
          ft.value = []
      })
      let dataWorker = {
        typeWorker: "filterPivotTable",
        filterArr:  cloneDeep(dataSelected),
        config: this.config,
        body: this.originalBody
      }
   
      if (typeof Worker !== 'undefined') { 
        this.filterPivotTableWorker(dataWorker).then(data => {
          if(data) {
            bodyFilter = data;
            let lstData: any = this.filterSelecteds.length > 0 ?  cloneDeep(bodyFilter) :  cloneDeep(this.originalBody) 
            this.callDialogFilter(index, lstData)
          }
        })
      }
    }
    else {
      this.callDialogFilter(index, this.originalBody)
    }
  }

  callDialogFilter(index: any, lstData: any[]) {
    this.getListFilterData(lstData, index);
    this.filterData = orderBy(this.filterData || [], ["value"])

    if(this.ref) return;//Dialog đang mở, không làm gì thêm

    this.ref = this.modalService.open(DialogFilterTableComponent, {
      data: {
        filterData: this.filterData,
        rowIndex: index,
        colnm: this.config.rows[index]?.displayname
      },
      header: COMMON_TEXT.SORTTABLE,
      width: '400px'
    });

    this.ref.onClose.subscribe((x: any) => {
      this.ref = null; // Reset tham chiếu khi dialog đóng
      if(!x) return
      this.setListFilterData(x)
      let dataWorker = {
        typeWorker: "filterPivotTable",
        filterArr:  cloneDeep(this.filterSelecteds),
        config: this.config,
        body: this.originalBody
      }
      this.processLoadingStackService.loadingSomething("filterPivotTableWorker", true);
      if (typeof Worker !== 'undefined') {
        this.filterPivotTableWorker(dataWorker).then(data => {
          if(data) {
            this.allBodyRows = data;
            this.allBodyRowsFiltered = cloneDeep(data);
            this.recalculateFooter();
            this.scrollToTop();
            this.initDisplayedRows();
            this.onSortCol(index, true);
            this.processLoadingStackService.loadingSomething("filterPivotTableWorker", false);

            if(this.filterLog) {
              const chekedFiltered = this.filterData.flat().filter(item => item.checked === true);
              this.saucerLogService.action({
                content: CONTENT_LOG.CHANGE_FILTER_WIDGET + "[ widgetCd: " + this.widget.widgetCd + " widgetName: " + this.widget.widgetName + " ] " + JSON.stringify(chekedFiltered)
              }, { 
                action: this.filterLog
              });
            }
          }
        })
        
      } else {
        this.processLoadingStackService.loadingSomething("filterPivotTableWorker", false);
      }
    })
  }

  checkDataExist(index: number) {
    let exist = false
    this.filterSelecteds?.forEach(ft => {
        if(index == ft.index) 
          exist = true;
    })
    return exist
  }

  setListFilterData(filter: any) {
    if(!filter) return;
    for(let i = 0; i <= filter[0]?.index; i++) {
      let data = filter[0]?.index == i? filter[0] : []
      let exist = this.checkDataExist(filter[0]?.index)
      if(exist) {
        this.filterSelecteds[filter[0]?.index].value = filter[0]
      }
      else {
        let existed = this.checkDataExist(i)
        if(!existed) {
          this.filterSelecteds.push({ value: data, index: i == filter[0].index? filter[0].index: i })
        }
      }
    }
  }

  getListFilterData(data: any[], index: number) {
    this.filterData = []
    let row = this.config.rows.length || 0;
    data.forEach((s:any)=> {
      let rowData = [];
      for(let i = 0; i< row; i++) {
        rowData.push({ 
          colnm: this.config.rows[i].columnname, 
          value: s[i].value, 
          checked: (this.filterSelecteds[index]?.value?.data && this.filterSelecteds[index]?.value?.data?.findIndex((ft: any) => ft.value == s[i].value) != -1) ?  true: false,
          datatype: s[i].datatype,
          formattype :  s[i].formattype
        } )
      }
      this.filterData.push(rowData);
    })
  }

  calculatorCellFooter(footer: any[], config: PivotTableConfig, formattype: string) {
    let countColumn = this.allBodyRows[0]?.length || 0
    let tableData: any[] = [];
    for(let i = 0; i< countColumn;  i++) {
      let values = this.allBodyRows.map(x => x[i].value)
        let sum: number = Number(values.reduce((a, b) => Number(a || 0) + Number(b || 0), 0))
        let count: number = values.filter(x => x != undefined).length
          switch (formattype) {
            case FooterOption.Sum:
              tableData.push(sum);
              break;
            case FooterOption.Average:
              tableData.push((sum / (count || 1)).toFixed(2))
              break;
            case FooterOption.CountUnique:
              tableData.push((new Set(values).size))
              break;
            case FooterOption.Count:
            default:
              tableData.push(count);
              break;
        }
    }
    const { rows } = config
    return footer.map((x, i) => {
      let obj = {
        colspan: 1,
        value: i < rows.length  ? x : tableData[i],
      }

      if (i < config.rows.length) {
        if(i == 0)
          obj.colspan = rows.length
        else obj.colspan = 0
      }
      return obj
    })
  }
  
  getFooterName(footertype: string ) {return PivotFooterOptions.find(x => x.value == footertype)?.name || ""}

  recalculateFooter() {
    if (this.footertype && this.data?.table?.footers) {
      const footers = this.data.table.footers[this.footertype]
      if (this.filterSelecteds && this.filterSelecteds.some(item => item?.value?.data?.length > 0)) {
        this.footers = this.calculatorCellFooter(footers, this.config, this.footertype)
      } else {
        this.footers = mergeCellFooter(footers, this.config)
      }
    } else {
      this.footers = []
    }
  }
}

/**
 * merge cell
 * | A | A |  =>  |   A   |
 * | B | C |      | B | C |
 * 
 */
export const mergeCellHeader = (table: any[][], summaryColumnType: SummaryColumnOption, config: PivotTableConfig) => {
  let previousRow: any[] = []

  let headers = table?.map((x, z) => {
    const spans: number[] = []
    const length = x.length || 0
    const lastIndex = length - 1

    let previousValue = x[lastIndex]?.value
    let spanProcessor = 1

    if (length > 1) {

      spans[lastIndex] = x[lastIndex]?.value == x[length - 2]?.value
        && (!previousRow.length || previousRow[lastIndex]?.colspan == 0) ? 0 : 1

      // iterate table, add colspan to the cell
      // then transform value  =>  { value, colspan }
      for (let i = length - 2; i >= 0; i--) {

        if (x[i].value == previousValue && (!previousRow.length || previousRow[i + 1].colspan == 0)) {

          spanProcessor++

        } else {

          spans[i + 1] = spanProcessor
          spanProcessor = 1
        }

        previousValue = x[i].value
        spans[i] = 0
      }

      spans[0] = spanProcessor

    } else {

      spans[0] = 1
    }

    if (z == table.length - 1) x.map((r, index) => (spans[index] = 1));

    const currentRow = x?.map((y, i) => ({
      colspan: getColSpan(summaryColumnType ,spans[i], table, z, i, config),
      rowspan: getRowSpan(summaryColumnType, table, z, i, config),
      value: typeof y === 'object' ? y.value : y,
      formattype: y.formattype
    }))

    previousRow = currentRow

    return currentRow
  })
  if(!summaryColumnType && !config.columns.length) return drop(headers)
  return headers
}

const getRowSpan = (summaryColumnType: SummaryColumnOption, headers: any[][], rowHeaderIndex: number, colHeaderIndex: number, config: PivotTableConfig) => {
  if (!summaryColumnType) return 1
  const { columns, values } = config
  const fistSummaryColumn = Number(last(headers)?.length) - values.length
  const isAfterSummaryColumn = colHeaderIndex >= fistSummaryColumn;
  if (!isAfterSummaryColumn || rowHeaderIndex == columns.length) return 1
  if (rowHeaderIndex == 0 && colHeaderIndex == fistSummaryColumn) {
    return columns.length
  } else {
    return 0
  }
}

const getColSpan = (summaryColumnType: SummaryColumnOption, span: number, headers: any[][], rowHeaderIndex: number, colHeaderIndex: number, config: PivotTableConfig) => {
  if (!summaryColumnType) return span
  const { columns, values } = config
  const fistSummaryColumn = Number(last(headers)?.length) - values.length
  if (rowHeaderIndex != 0 && rowHeaderIndex != (columns.length || 1) && colHeaderIndex == fistSummaryColumn) return 0
  return span
}

const mergeCellFooter = (table: any[], config: PivotTableConfig) => {
  return table?.map((x, i) => {
    let obj = {
      colspan: 1,
      value: x,
    }

    if (i === 0) {
      obj.colspan = config.rows?.length || 1
    } else if (i < config.rows?.length) {
      obj.colspan = 0
    }

    return obj
  })
}


