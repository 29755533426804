import { FolderService } from '../../../services/modules/folder.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ButtonType, DataSourceColumnType, DataType, SearchType } from '../../../enum/common-enum';
import { ModalTemplate, SearchParams, TitleMapping } from '../../../models/common-model';
import Utils from '../../../../app/util/utils';
import { Folder } from '../../../models/response/folder.ro';
import { BUTTON, COMMON_TEXT } from '../../../const/text-common';
import { Router } from '@angular/router';
import { DataTypeOptions } from 'src/app/const/const';
import { InputFormulaComponent } from 'src/app/component/common/input-formula/input-formula.component';
import { getOperatorString } from 'src/app/_helper/operator-custom-helper';
import { OPERATOR_TYPE } from 'src/app/const/operator-custom';

@Component({
  selector: 'pivot-user-defined-column',
  templateUrl: './user-defined-column.component.html',
  styleUrls: ['./user-defined-column.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserDefinedComponent implements OnInit, OnChanges {
  
  @ViewChild('inputFormula') inputFormula: InputFormulaComponent;
  @Input() selectedDsColumns: any[] = [];
  @Input() userDefinedColumns: any[] = [];
  @Input() colUsed: any[] = [];
  @Input() selected: any;
  @Output() closeModalHandler = new EventEmitter();
  @Output() onSubmitUserDefinedColumn = new EventEmitter<any>();   
  @Output() onDeleteUserDefinedColumn = new EventEmitter<any>();   
  columnName: string = "";
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  buttonType = ButtonType;
  searchParams: SearchParams = {
    type: SearchType.insert,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { width: '100%', height: '40px' },
    comboDisplayText: 'name',
    readonly: false,
    disabled: false,
    isFilter: false,
    dataSource: this.userDefinedColumns,
    maxLength: 100,
  }
  displayCols: any[] = [];
  columnSelected: any|null;
  columnEdit: any;
  validateInput: boolean = false;
  modalUserdefined: ModalTemplate = new ModalTemplate();
  messageError = "";
  constructor(private folderService: FolderService, 
    private router: Router) { }

  ngOnChanges(): void {
    if(this.selected) {
      this.columnSelected = this.selected;
      this.columnName  =  this.columnSelected.name;
      this.displayCols = this.selectedDsColumns.filter((col: any)=> col.columnId != this.selected.columnId && (col.data && !col.data.delflg));
    } else {
      this.displayCols = this.selectedDsColumns.filter((col: any)=> (col.data && !col.data.delflg));
    }
  }

  async ngOnInit() {
    this.modalUserdefined.header = COMMON_TEXT.USER_DEFINED_COLUMN_MANAGERMENT;
    this.modalUserdefined.style = { 'width': '460px' };
    this.modalUserdefined.hasBottom = false;
    this.modalUserdefined.hasFooter = true;
    this.searchParams.dataSource = this.userDefinedColumns;
  }

  closeFormModal() {
    this.closeModalHandler.emit();
  }

  onSelectColumn(columns: any) {
      this.validateInput = false;
    if(columns && columns.length > 0){
      this.columnName = columns[0].name;
      this.columnSelected = columns[0];
      this.displayCols = this.selectedDsColumns.filter((col: any)=> col.columnId != this.columnSelected.columnId && (col.data && !col.data.delflg));
    }
  }

  
  onSubmit() {
    if (Utils.isNullOrEmpty(this.columnName)) {
      this.validateInput = true;
      this.messageError = "";
    } 
    if (this.validateInput) {
      return;
    }
    let column: any;
    let data: TitleMapping = {
          displayname: this.columnName.trim(),
          columnname: this.columnName.trim().toUpperCase(),
          datatype: "VARCHAR",
          columntype: DataSourceColumnType.USER_DEFINED,
          sortno: -1,
          delflg: false,
          tooltip: ""
        }

    let dataFormula = this.inputFormula.getData();

    let operator = getOperatorString(dataFormula.operators, true);
    let operatorSaucerLog = this.getOperatorStringSaucerLog(operator);
    if(this.columnSelected){
      data.id = this.columnSelected.data.id;
      data.datatype = this.columnSelected.data.datatype;
      column = { value: `COL${data.sortno}`, operator: operator, name: this.columnName.trim(), defaultValue: DataTypeOptions[0], data: data, tooltip: this.columnName.trim(), columnId: this.columnSelected.columnId }
    }else{
      column = { value: `COL${data.sortno}`, operator: operator, name: this.columnName.trim(), defaultValue: DataTypeOptions[0], data: data, tooltip: this.columnName.trim() }
    }

    column.operatorColunm = dataFormula.operators.filter((op: any) => op.type == OPERATOR_TYPE.COLUMN);
    column.operatorSaucerLog = operatorSaucerLog; 
    this.onSubmitUserDefinedColumn.emit(column);
  }
  oncCancel() {
    this.closeModalHandler.emit();
  }
  onDelete() {
    this.columnSelected.operatorSaucerLog = this.getOperatorStringSaucerLog(this.columnSelected.operator);
    this.onDeleteUserDefinedColumn.emit(this.columnSelected);
  }

  getOperatorStringSaucerLog(operator:any){
    const lstFormular = this.inputFormula.getData()?.operators;
    if (lstFormular.length > 0) {
      for (let i = 0; i < lstFormular.length; i++) {
        const item = lstFormular[i]?.value;
        if (item && typeof item === 'object') {
          operator = operator.replace(item.columnId, item.name);
        }
      }
    }
    return operator;
  }

  onChangeColumnName(str: any){
    this.validateInput = false;
    let userDefinedColumnsResult = this.userDefinedColumns.find(x => x.name == str);
    if (!this.columnSelected && userDefinedColumnsResult) {
      this.validateInput = true;
      this.messageError = COMMON_TEXT.COLUMN_NAME_ALREADY_EXISTS;
    } 
    if (this.selected && this.selected.name == str) {
      this.validateInput = false;
    }
    if (this.columnSelected) {
      if (this.columnSelected.name != str) {
        let check = this.userDefinedColumns.find(x => x.name != this.columnSelected.name && x.name.toUpperCase() == str);
        if (check) {
          this.validateInput = true;
          this.messageError = COMMON_TEXT.COLUMN_NAME_ALREADY_EXISTS;
        }
      } else {
        this.validateInput = false;
      }
    }
  }
}
