<div class="ck-report" id="ztk-parent">
	<div class="ck-report--flex" id="ztk-div1">
		<div class="header">
            <div class="header--title">{{CK_REPORT.TITLE}}</div>
            <pivot-text-button 
                saucerLogger [logMessage]="showAutomaticAcquisitionSettingsLog"
                [disabled]="isEdit"
                (btnClick)="openDlgSetting()" 
                [buttonType]="buttonType.SECONDARY"
                [buttonText]="CK_REPORT.AUTO_ACQUISITION_SETTING">
            </pivot-text-button>
            <pivot-text-button 
                (btnClick)="getDataCK()" 
                [disabled]="!isValidDate || isEdit" 
                [buttonType]="buttonType.SECONDARY" 
                [buttonText]="CK_REPORT.DATA_ACQUISITION">
            </pivot-text-button>
            <pivot-text-button 
                class="header--btn"
                [ngClass]="{ 'not-sync': !isValidMapDateTable && isValidDate }"
                (btnClick)="enableEditMode()" 
                [disabled]="!isValidDate || isChangeDate || !isValidMapDateTable"
                [buttonType]="!isEdit? buttonType.SECONDARY : buttonType.PRIMARY"
                [buttonText]="inputText">
            </pivot-text-button>
		</div>
		<div></div>
	</div>

	<div class="ck-report--grb-left" id="ztk-div2">
		<pivot-search-bar [filterLog]="SAUCER_LOG_ACTION.RETURN_HOME.SEARCH_BUSINESS" *ngIf="!isEdit" [searchParams]="searchParams" [placeHolderFilter]="CK_REPORT.TITLE" (onFilterData)="onFilterOutData($event)"></pivot-search-bar>
        <div *ngIf="isEdit" class="search-bar-disable">{{searchParams.defaultValue}}</div>
        <div>
            <div class="frm-group">
                <span class="frm-group--label">{{CK_REPORT.DISPLAY_PERIOD}}</span>
                <pivot-calendar
                    [disabled]="isEdit"
                    class="input-date"
                    [ngClass]="{ 'valid-date': !isValidDate }"
                    [dateFormat]="dateFormat"
                    [view]="'month'"
                    [(value)]="this.startDate"
                    (valueChange)="changeStartDate($event)"
                    [canClear]="false">
                </pivot-calendar>
                <span>{{CK_REPORT.TO}}</span>
                <pivot-calendar
                    [disabled]="isEdit"
                    class="input-date"
                    [ngClass]="{ 'valid-date': !isValidDate }"
                    [dateFormat]="dateFormat"
                    [view]="'month'"
                    [(value)]="this.endDateAt1stDay"
                    (valueChange)="changeEndDate($event)"
                    [canClear]="false">
                </pivot-calendar>
                <span [ngClass]="{ 'valid-date': !isValidDate }">{{CK_REPORT.NOTE}}</span>
            </div>
            <div class="frm-group-info">
                <div class="frm-group-info--note">
                    <span class="span-format">{{CK_REPORT.SQUARE}}</span>{{CK_REPORT.DATA_NOTE}}
                </div>
                <div class="frm-group-info--cb">
                    <pivot-checkbox class="hospital-cb" [value]="hasHospitalNearby" [disabled]="!isEdit" (onClick)="onCheckboxHospitalNearby()"></pivot-checkbox>
                    <div class="has-hospital-nearby">{{CK_REPORT.HAS_HOSPITAL_NEARBY}}</div>
                </div>
            </div>
        </div>
	</div>
    <pivot-table class="ztk-pivot-table"
        (cellFocusOut)="cellFocusOut($event)"
        (cellFocusIn)="cellFocusIn($event)"
        (checkFieldEvent)="checkFieldEvent($event)"
        (cellDropdownChange)="cellDropdownChange($event)"
        (doCheckEvent)="doCheckTableEvent($event)"
        [data]="configTableData">
    </pivot-table>
    <ng-container *ngIf="isShowWarningDaibelong">
        <div class="warning-dbl-container" [style.maxHeight]="warningDivMaxHeight">
            <table class="warning-table">
                <tbody>
                    <tr *ngFor="let item of warningDaibelongTable">
                        <td>{{item?.date}}の延入所者日数の中に所属情報が無い利用者 ({{item?.fullnames}}) が含まれています。</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>