export const SEIKUYFORDATASOURCE = [
  // {
    
  //   name: "請求データ①（〜2024/11）",
  //   table: "0",//PROVIDED_DETAILS
  //   calcfunc: 0,
  //   fieldname: "field",
  //   fields: [
  //     {
  //       field: "ROWTYPE",
  //       displayName: "行区分",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "DAICD",
  //       displayName: "対象者コード",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "KANANAME",
  //       displayName: "対象者ｶﾅ",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "NAME",
  //       displayName: "対象者名称",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "HHCD",
  //       displayName: "被保険者番号",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "HOCD",
  //       displayName: "保険者番号",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "HONM",
  //       displayName: "保険者名",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "GKLV",
  //       displayName: "介護度",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "GENDER",
  //       displayName: "性別",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "SRVSDATE",
  //       displayName: "サービス提供月",
  //       dataType: "DATETIME"
  //     },
  //     {
  //       field: "RECEDATEKOKUHO",
  //       displayName: "国保請求月",
  //       dataType: "DATETIME"
  //     },
  //     {
  //       field: "RECEDATEKOJIN",
  //       displayName: "個人請求月",
  //       dataType: "DATETIME"
  //     },
  //     {
  //       field: "JSRVKBNCD",
  //       displayName: "種別",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "JIGCD",
  //       displayName: "事業所コード",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "SHITEI",
  //       displayName: "指定",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "JIGNM",
  //       displayName: "事業所名",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "SDATE",
  //       displayName: "日付",
  //       dataType: "DATETIME"
  //     },
  //     {
  //       field: "STIME",
  //       displayName: "開始",
  //       dataType: "DATETIME"
  //     },
  //     {
  //       field: "ETIME",
  //       displayName: "終了",
  //       dataType: "DATETIME"
  //     },
  //     {
  //       field: "KINDNM",
  //       displayName: "サービス種別",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "SRVCD",
  //       displayName: "コード",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "SRVNM",
  //       displayName: "サービス内容",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "KMKCD",
  //       displayName: "科目コード",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "KMKNM",
  //       displayName: "科目名",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "PRODUCTID",
  //       displayName: "商品コード",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "TAISCD",
  //       displayName: "JAN/TAISコード",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "PRODUCTNM",
  //       displayName: "商品名",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "TYPE",
  //       displayName: "単位数識別記号",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "TANISRC",
  //       displayName: "合成単位",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DISCOUNT",
  //       displayName: "割引率％",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "BASEPAY",
  //       displayName: "基準額(円)",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "HOSOKSUMPRICE",
  //       displayName: "実際の費用",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "LIMITPAY",
  //       displayName: "負担限度(円)",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "TANIDST",
  //       displayName: "単位",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "COUNTSUM",
  //       displayName: "回数",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "TANISUM",
  //       displayName: "合計単位",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "PAYKBN",
  //       displayName: "請求方法",
  //       dataType: "VARCHAR"
  //     },
  //     {
  //       field: "INSURA",
  //       displayName: "給付率",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "KOUHI1",
  //       displayName: "第１公費",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "KOUHI2",
  //       displayName: "第２公費",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "KOUHI3",
  //       displayName: "第３公費",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "AREACOST",
  //       displayName: "地域単価",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "INSURSUM",
  //       displayName: "保険＋公費負担額",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "INSURAOWN",
  //       displayName: "保険分自己負担額",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "INSOUTOWN",
  //       displayName: "全額自己負担金額",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "___SFK",
  //       displayName: "社福軽減額",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "___JSI",
  //       displayName: "自治体独自助成等",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "___KOUGK",
  //       displayName: "高額介護サ費",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "MEDTARGET",
  //       displayName: "医療費控除対象額",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "___RIYOUPAY",
  //       displayName: "利用者請求額",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "SUMPRICE",
  //       displayName: "サービス費用総額",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "___ZEI",
  //       displayName: "消費税",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "HOSOKPAY",
  //       displayName: "補足給付(円)",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "SEIHOPAY",
  //       displayName: "生保支払(円)",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "FUKUSPAY",
  //       displayName: "福祉事務所(円)",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "HOSKOWN",
  //       displayName: "自己負担(円)",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "ADJUSTPAY",
  //       displayName: "調整額(円)",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY1",
  //       displayName: "1日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY2",
  //       displayName: "2日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY3",
  //       displayName: "3日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY4",
  //       displayName: "4日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY5",
  //       displayName: "5日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY6",
  //       displayName: "6日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY7",
  //       displayName: "7日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY8",
  //       displayName: "8日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY9",
  //       displayName: "9日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY10",
  //       displayName: "10日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY11",
  //       displayName: "11日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY12",
  //       displayName: "12日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY13",
  //       displayName: "13日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY14",
  //       displayName: "14日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY15",
  //       displayName: "15日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY16",
  //       displayName: "16日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY17",
  //       displayName: "17日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY18",
  //       displayName: "18日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY19",
  //       displayName: "19日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY20",
  //       displayName: "20日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY21",
  //       displayName: "21日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY22",
  //       displayName: "22日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY23",
  //       displayName: "23日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY24",
  //       displayName: "24日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY25",
  //       displayName: "25日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY26",
  //       displayName: "26日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY27",
  //       displayName: "27日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY28",
  //       displayName: "28日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY29",
  //       displayName: "29日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY30",
  //       displayName: "30日",
  //       dataType: "FLOAT"
  //     },
  //     {
  //       field: "DAY31",
  //       displayName: "31日",
  //       dataType: "FLOAT"
  //     },
  
  //   ]
  // },
  {
    
    name: "提供明細",
    table: "1",
    calcfunc: 0,
    fieldname: "field",
    fields: [
      {
        field: "ROWTYPE",
        displayName: "行区分",
        dataType: "VARCHAR"
      },
      {
        field: "DAICD",
        displayName: "対象者コード",
        dataType: "VARCHAR"
      },
      {
        field: "KANANAME",
        displayName: "対象者ｶﾅ",
        dataType: "VARCHAR"
      },
      {
        field: "NAME",
        displayName: "対象者名称",
        dataType: "VARCHAR"
      },
      {
        field: "HHCD",
        displayName: "被保険者番号",
        dataType: "VARCHAR"
      },
      {
        field: "HOCD",
        displayName: "保険者番号",
        dataType: "VARCHAR"
      },
      {
        field: "HONM",
        displayName: "保険者名",
        dataType: "VARCHAR"
      },
      {
        field: "GKLV",
        displayName: "介護度",
        dataType: "VARCHAR"
      },
      {
        field: "GENDER",
        displayName: "性別",
        dataType: "VARCHAR"
      },
      {
        field: "SRVSDATE",
        displayName: "サービス提供月",
        dataType: "DATETIME"
      },
      {
        field: "RECEDATEKOKUHO",
        displayName: "国保請求月",
        dataType: "DATETIME"
      },
      {
        field: "RECEDATEKOJIN",
        displayName: "個人請求月",
        dataType: "DATETIME"
      },
      {
        field: "JSRVKBNCD",
        displayName: "種別",
        dataType: "VARCHAR"
      },
      {
        field: "JIGCD",
        displayName: "事業所コード",
        dataType: "VARCHAR"
      },
      {
        field: "SHITEI",
        displayName: "指定",
        dataType: "VARCHAR"
      },
      {
        field: "JIGNM",
        displayName: "事業所名",
        dataType: "VARCHAR"
      },
      {
        field: "SDATE",
        displayName: "日付",
        dataType: "DATETIME"
      },
      {
        field: "STIME",
        displayName: "開始",
        dataType: "DATETIME"
      },
      {
        field: "ETIME",
        displayName: "終了",
        dataType: "DATETIME"
      },
      {
        field: "KINDNM",
        displayName: "サービス種別",
        dataType: "VARCHAR"
      },
      {
        field: "SRVCD",
        displayName: "コード",
        dataType: "VARCHAR"
      },
      {
        field: "SRVNM",
        displayName: "サービス内容",
        dataType: "VARCHAR"
      },
      {
        field: "KMKCD",
        displayName: "科目コード",
        dataType: "VARCHAR"
      },
      {
        field: "KMKNM",
        displayName: "科目名",
        dataType: "VARCHAR"
      },
      {
        field: "PRODUCTID",
        displayName: "商品コード",
        dataType: "VARCHAR"
      },
      {
        field: "TAISCD",
        displayName: "JAN/TAISコード",
        dataType: "VARCHAR"
      },
      {
        field: "PRODUCTNM",
        displayName: "商品名",
        dataType: "VARCHAR"
      },
      {
        field: "TYPE",
        displayName: "単位数識別記号",
        dataType: "VARCHAR"
      },
      {
        field: "TANISRC",
        displayName: "合成単位",
        dataType: "FLOAT"
      },
      {
        field: "DISCOUNT",
        displayName: "割引率％",
        dataType: "FLOAT"
      },
      {
        field: "BASEPAY",
        displayName: "基準額(円)",
        dataType: "FLOAT"
      },
      {
        field: "HOSOKSUMPRICE",
        displayName: "実際の費用",
        dataType: "FLOAT"
      },
      {
        field: "LIMITPAY",
        displayName: "負担限度(円)",
        dataType: "FLOAT"
      },
      {
        field: "TANIDST",
        displayName: "単位",
        dataType: "FLOAT"
      },
      {
        field: "COUNTSUM",
        displayName: "回数",
        dataType: "FLOAT"
      },
      {
        field: "TANISUM",
        displayName: "合計単位",
        dataType: "FLOAT"
      },
      {
        field: "PAYKBN",
        displayName: "請求方法",
        dataType: "VARCHAR"
      },
      {
        field: "INSURA",
        displayName: "給付率",
        dataType: "FLOAT"
      },
      {
        field: "KOUHI1",
        displayName: "第１公費",
        dataType: "FLOAT"
      },
      {
        field: "KOUHI2",
        displayName: "第２公費",
        dataType: "FLOAT"
      },
      {
        field: "KOUHI3",
        displayName: "第３公費",
        dataType: "FLOAT"
      },
      {
        field: "AREACOST",
        displayName: "地域単価",
        dataType: "FLOAT"
      },
      {
        field: "INSURSUM",
        displayName: "保険＋公費負担額",
        dataType: "FLOAT"
      },
      {
        field: "INSURAOWN",
        displayName: "保険分自己負担額",
        dataType: "FLOAT"
      },
      {
        field: "INSOUTOWN",
        displayName: "全額自己負担金額",
        dataType: "FLOAT"
      },
      {
        field: "___SFK",
        displayName: "社福軽減額",
        dataType: "FLOAT"
      },
      {
        field: "___JSI",
        displayName: "自治体独自助成等",
        dataType: "FLOAT"
      },
      {
        field: "___KOUGK",
        displayName: "高額介護サ費",
        dataType: "FLOAT"
      },
      {
        field: "MEDTARGET",
        displayName: "医療費控除対象額",
        dataType: "FLOAT"
      },
      {
        field: "___RIYOUPAY",
        displayName: "利用者請求額",
        dataType: "FLOAT"
      },
      {
        field: "SUMPRICE",
        displayName: "サービス費用総額",
        dataType: "FLOAT"
      },
      {
        field: "___ZEI",
        displayName: "消費税",
        dataType: "FLOAT"
      },
      {
        field: "HOSOKPAY",
        displayName: "補足給付(円)",
        dataType: "FLOAT"
      },
      {
        field: "SEIHOPAY",
        displayName: "生保支払(円)",
        dataType: "FLOAT"
      },
      {
        field: "FUKUSPAY",
        displayName: "福祉事務所(円)",
        dataType: "FLOAT"
      },
      {
        field: "HOSKOWN",
        displayName: "自己負担(円)",
        dataType: "FLOAT"
      },
      {
        field: "ADJUSTPAY",
        displayName: "調整額(円)",
        dataType: "FLOAT"
      },
      {
        field: "DAY1",
        displayName: "1日",
        dataType: "FLOAT"
      },
      {
        field: "DAY2",
        displayName: "2日",
        dataType: "FLOAT"
      },
      {
        field: "DAY3",
        displayName: "3日",
        dataType: "FLOAT"
      },
      {
        field: "DAY4",
        displayName: "4日",
        dataType: "FLOAT"
      },
      {
        field: "DAY5",
        displayName: "5日",
        dataType: "FLOAT"
      },
      {
        field: "DAY6",
        displayName: "6日",
        dataType: "FLOAT"
      },
      {
        field: "DAY7",
        displayName: "7日",
        dataType: "FLOAT"
      },
      {
        field: "DAY8",
        displayName: "8日",
        dataType: "FLOAT"
      },
      {
        field: "DAY9",
        displayName: "9日",
        dataType: "FLOAT"
      },
      {
        field: "DAY10",
        displayName: "10日",
        dataType: "FLOAT"
      },
      {
        field: "DAY11",
        displayName: "11日",
        dataType: "FLOAT"
      },
      {
        field: "DAY12",
        displayName: "12日",
        dataType: "FLOAT"
      },
      {
        field: "DAY13",
        displayName: "13日",
        dataType: "FLOAT"
      },
      {
        field: "DAY14",
        displayName: "14日",
        dataType: "FLOAT"
      },
      {
        field: "DAY15",
        displayName: "15日",
        dataType: "FLOAT"
      },
      {
        field: "DAY16",
        displayName: "16日",
        dataType: "FLOAT"
      },
      {
        field: "DAY17",
        displayName: "17日",
        dataType: "FLOAT"
      },
      {
        field: "DAY18",
        displayName: "18日",
        dataType: "FLOAT"
      },
      {
        field: "DAY19",
        displayName: "19日",
        dataType: "FLOAT"
      },
      {
        field: "DAY20",
        displayName: "20日",
        dataType: "FLOAT"
      },
      {
        field: "DAY21",
        displayName: "21日",
        dataType: "FLOAT"
      },
      {
        field: "DAY22",
        displayName: "22日",
        dataType: "FLOAT"
      },
      {
        field: "DAY23",
        displayName: "23日",
        dataType: "FLOAT"
      },
      {
        field: "DAY24",
        displayName: "24日",
        dataType: "FLOAT"
      },
      {
        field: "DAY25",
        displayName: "25日",
        dataType: "FLOAT"
      },
      {
        field: "DAY26",
        displayName: "26日",
        dataType: "FLOAT"
      },
      {
        field: "DAY27",
        displayName: "27日",
        dataType: "FLOAT"
      },
      {
        field: "DAY28",
        displayName: "28日",
        dataType: "FLOAT"
      },
      {
        field: "DAY29",
        displayName: "29日",
        dataType: "FLOAT"
      },
      {
        field: "DAY30",
        displayName: "30日",
        dataType: "FLOAT"
      },
      {
        field: "DAY31",
        displayName: "31日",
        dataType: "FLOAT"
      },
  
    ]
  }

  // DANG PENDDING 2-3 NEN HIEN TAI CHI HANDLE CHO 1
  // {
  //   name: "請求金額",
  //   table: "BILLED_AMOUNT",
  //   fields: [
  //     {
  //       field: "usercount",
  //       displayName: "利用者番号"
  //     },
  //     {
  //       field: "name",
  //       displayName: "氏名"
  //     },
  //     {
  //       field: "kannaname",
  //       displayName: "カナ氏名"
  //     },
  //     {
  //       field: "systemdisplayname",
  //       displayName: "名前+(システム表示名)"
  //     },
  //     {
  //       field: "prefectures",
  //       displayName: "都道府県"
  //     },
  //     {
  //       field: "Municipalities",
  //       displayName: "市町村"
  //     },
  //     {
  //       field: "DOB",
  //       displayName: "生年月日"
  //     },
  //     {
  //       field: "sex",
  //       displayName: "性別"
  //     },
  //     {
  //       field: "postalcode",
  //       displayName: "郵便番号"
  //     },
  //     {
  //       field: "address1",
  //       displayName: "住所1"
  //     },
  //     {
  //       field: "address2",
  //       displayName: "住所2"
  //     },
  //     {
  //       field: "phonenumber",
  //       displayName: "電話番号"
  //     },
  //     {
  //       field: "termination",
  //       displayName: "終結"
  //     },
  //     {
  //       field: "terminationdate",
  //       displayName: "終結日"
  //     },
  //     {
  //       field: "reasonterminationdate",
  //       displayName: "終結理由"
  //     },
  //     {
  //       field: "housingclassification",
  //       displayName: "終結理由"
  //     },
  //     {
  //       field: "billingmethod",
  //       displayName: "請求方法"
  //     },
  //     {
  //       field: "paymentmethod",
  //       displayName: "支払方法"
  //     },
  //     {
  //       field: "invoiceaddress",
  //       displayName: "本人への請求書送付先(氏名)"
  //     },
  //     {
  //       field: "billingaddress",
  //       displayName: "本人への請求書送付先(郵便番号)"
  //     },
  //     {
  //       field: "addresstosendbill",
  //       displayName: "本人への請求書送付先(郵便番号)"
  //     },
  //     {
  //       field: "addresstosendbill",
  //       displayName: "本人への請求書送付先(郵便番号)"
  //     },
  //     {
  //       field: "address1tosendbill",
  //       displayName: "本人への請求書送付先(住所１)"
  //     },
  //     {
  //       field: "address2tosendbill",
  //       displayName: "本人への請求書送付先(住所2)"
  //     },
  //     {
  //       field: "addresstosendbillotherthanprincipal",
  //       displayName: "本人以外への請求書送付先(氏名)"
  //     },
  //     {
  //       field: "addresstosendbillothers",
  //       displayName: "本人以外への請求書送付先(郵便番号)"
  //     },
  //     {
  //       field: "addresstosendbillothers_zipcode",
  //       displayName: "本人以外への請求書送付先(郵便番号)"
  //     },
  //     {
  //       field: "addresstosendbillothers_phonenumber",
  //       displayName: "本人以外への請求書送付先(電話番号)"
  //     },
  //     {
  //       field: "addresstosendbillotherthanprincipal_address1",
  //       displayName: "本人以外への請求書送付先(住所１)"
  //     },
  //     {
  //       field: "addresstosendbillotherthanprincipal_address2",
  //       displayName: "本人以外への請求書送付先(住所２)"
  //     },
  //     {
  //       field: "addresstosendbillothers_address2",
  //       displayName: "本人以外への請求書送付先(住所２)"
  //     },
  //     {
  //       field: "debitaccountinformation_banknumber",
  //       displayName: "引き落とし口座情報(銀行番号)"
  //     },
  //     {
  //       field: "debitaccountinformation_bankname",
  //       displayName: "引き落とし口座情報(銀行名)"
  //     },
  //     {
  //       field: "debitaccountinformation_branchnumber",
  //       displayName: "引き落とし口座情報(支店番号)"
  //     },
  //     {
  //       field: "debitaccountinformation_branchname",
  //       displayName: "引き落とし口座情報(支店名)"
  //     },
  //     {
  //       field: "withdrawaccountinformation_account",
  //       displayName: "引き落とし口座情報(科目)"
  //     },
  //     {
  //       field: "withdrawaccountinformation_accountnumber",
  //       displayName: "引き落とし口座情報(口座番号)"
  //     },
  //     {
  //       field: "withdrawaccountinformation_depositorname",
  //       displayName: "引き落とし口座情報(預金者名)"
  //     },
  //     {
  //       field: "customercode",
  //       displayName: "顧客コード"
  //     },
  //     {
  //       field: "billinginformation_remarks",
  //       displayName: "請求情報備考"
  //     },
  //     {
  //       field: "billinginformation_registrant",
  //       displayName: "請求情報登録者"
  //     },
  //     {
  //       field: "billinginformation_registrationdate",
  //       displayName: "請求情報登録日"
  //     },
  //     {
  //       field: "billinginformation_lastmodified",
  //       displayName: "請求情報最終更新者"
  //     },
  //     {
  //       field: "billinginformation_lastupdated",
  //       displayName: "請求情報最終更新日"
  //     },
  //     {
  //       field: "expirationdate_startdate",
  //       displayName: "有効期限(開始日)"
  //     },
  //     {
  //       field: "expirationdate_enddate",
  //       displayName: "有効期限(終了日)"
  //     },
  //     {
  //       field: "insuredpersonnumber",
  //       displayName: "被保険者番号"
  //     },
  //     {
  //       field: "categoryinsuredpersion",
  //       displayName: "被保険者区分"
  //     },
  //     {
  //       field: "dateofissue",
  //       displayName: "交付年月日"
  //     },
  //     {
  //       field: "temporaryflag",
  //       displayName: "暫定フラグ"
  //     },
  //     {
  //       field: "insurernumber",
  //       displayName: "保険者番号"
  //     },
  //     {
  //       field: "nameofinsurer",
  //       displayName: "保険者名称"
  //     },
  //     {
  //       field: "carelevelclassification",
  //       displayName: "介護度状態区分(介護度)"
  //     },
  //     {
  //       field: "certificationdate",
  //       displayName: "認定年月日"
  //     },
  //     {
  //       field: "validityperiod_start",
  //       displayName: "認定の有効期間(開始)"
  //     },
  //     {
  //       field: "validityperiod_expiration",
  //       displayName: "認定の有効期間(終了)"
  //     },
  //     {
  //       field: "effectiveperiod_start",
  //       displayName: "変更後有効期間(開始)"
  //     },
  //     {
  //       field: "effectiveperiod_end",
  //       displayName: "変更後有効期間(終了)"
  //     },
  //     {
  //       field: "categorypaymentlimit_permonth",
  //       displayName: "区分支給限度支給額（１月当たり"
  //     },
  //     {
  //       field: "categorypaymentlimit_start",
  //       displayName: "区分支給限度支給額（開始"
  //     },
  //     {
  //       field: "categorypaymentlimit_end",
  //       displayName: "区分支給限度支給額（終了"
  //     },
  //     {
  //       field: "standardpaymentfortype",
  //       displayName: "種類支給限度基準額(訪問介護)"
  //     },
  //     {
  //       field: "standardpaymentforeachtype_homevisitnursing",
  //       displayName: "種類支給限度基準額(訪問看護)"
  //     },
  //     {
  //       field: "standardpaymentforeachtype_vistingrehabilitation",
  //       displayName: "種類支給限度基準額(訪問リハ)"
  //     },
  //     {
  //       field: "typelimitstandardamount_visitingbathing",
  //       displayName: "種類支給限度基準額(訪問入浴)"
  //     },
  //     {
  //       field: "typelimitstandardamount_visitingbathing",
  //       displayName: "種類支給限度基準額(訪問入浴)"
  //     },
  //     {
  //       field: "typelimitstandardamount_daycare",
  //       displayName: "種類支給限度基準額(通所介護)"
  //     },
  //     {
  //       field: "typelimitstandardamount_outpatientrehab",
  //       displayName: "種類支給限度基準額(通所リハ)"
  //     },
  //     {
  //       field: "standardpaymentlimitforeachtype_welfareequipment",
  //       displayName: "種類支給限度基準額(福祉用具)"
  //     },
  //     {
  //       field: "typelimitstandardamount_shorttermadmissionlifecare",
  //       displayName: "種類支給限度基準額(短期入所生活介護)"
  //     },
  //     {
  //       field: "typelimitstandardamount_shorttermadmissionmedicalcare",
  //       displayName: "種類支給限度基準額(短期入所療養介護)"
  //     },
  //     {
  //       field: "standardpaymentlimitaount_nighttimehomevisitcare",
  //       displayName: "種類支給限度基準額(夜間対応型訪問介護)"
  //     },
  //     {
  //       field: "typelimitstandardamount_outpatientcarefordementia",
  //       displayName: "種類支給限度基準額(認知症対応型通所介護)"
  //     },
  //     {
  //       field: "typelimitstandardamount_outpatientcarefordementia",
  //       displayName: "種類支給限度基準額(認知症対応型通所介護)"
  //     },
  //     {
  //       field: "typelimitstandardamount_communallivingcarefordementia",
  //       displayName: "種類支給限度基準額(認知症対応型共同生活介護)"
  //     },
  //     {
  //       field: "typelimitstandardamount_communitybasedoutpatientcare",
  //       displayName: "種類支給限度基準額(地域密着型通所介護)"
  //     },
  //     {
  //       field: "accreditationboardopinionandservicetypedesignation",
  //       displayName: "認定審査会の意見及びサービス種類の指定"
  //     },
  //     {
  //       field: "restrictionsonbenefits_detail1",
  //       displayName: "給付制限_内容1"
  //     },
  //     {
  //       field: "restrictionsonbenefits_detail1startdate",
  //       displayName: "給付制限_内容1(開始年月日)"
  //     },
  //     {
  //       field: "restrictionsonbenefits_detail1enddate",
  //       displayName: "給付制限_内容1(終了年月日)"
  //     },
  //     {
  //       field: "restrictionsonbenefits_detail2",
  //       displayName: "給付制限_内容2"
  //     },
  //     {
  //       field: "restrictionsonbenefits_detail2startdate",
  //       displayName: "給付制限_内容2(開始年月日)"
  //     },
  //     {
  //       field: "restrictionsonbenefits_detail2enddate",
  //       displayName: "給付制限_内容2(終了年月日)"
  //     },
  //     {
  //       field: "restrictionsonbenefits_detail3",
  //       displayName: "給付制限_内容3"
  //     },
  //     {
  //       field: "restrictionsonbenefits_detail3startdate",
  //       displayName: "給付制限_内容3(開始年月日)"
  //     },
  //     {
  //       field: "restrictionsonbenefits_detail3enddate",
  //       displayName: "給付制限_内容3(終了年月日)"
  //     },
  //     {
  //       field: "nameofinhomelongterm_placeofbusinesscode",
  //       displayName: "居宅介護支援事業者およびその事業所の名称(事業所コード)"
  //     },
  //     {
  //       field: "nameofinhomelongterm_name",
  //       displayName: "居宅介護支援事業者およびその事業所の名称(名前)"
  //     },
  //     {
  //       field: "nameofinhomelongterm_dateofsubmission",
  //       displayName: "居宅介護支援事業者およびその事業所の名称(提出年月日)"
  //     },
  //     {
  //       field: "nameofinhomelongterm_caremanagerincharge",
  //       displayName: "居宅介護支援事業者およびその事業所の名称(担当ケアマネ)"
  //     },
  //     {
  //       field: "nameofinhomelongterm_officialname",
  //       displayName: "居宅介護支援事業者およびその事業所の名称(正式名称)"
  //     },
  //     {
  //       field: "renewalagency_facilitycode",
  //       displayName: "更新機関(施設)コード"
  //     },
  //     {
  //       field: "servicecode",
  //       displayName: "サービスコード"
  //     },
  //     {
  //       field: "certifiedinformation_registrant",
  //       displayName: "認定情報登録者"
  //     },
  //     {
  //       field: "certificationinformation_registrationdate",
  //       displayName: "認定情報登録日"
  //     },
  //     {
  //       field: "certificationinformation_lastmodifiedpersion",
  //       displayName: "認定情報最終更新者"
  //     },
  //     {
  //       field: "certificationinformation_lastupdateddate",
  //       displayName: "認定情報最終更新日"
  //     },
  //     {
  //       field: "degreeofindependenceindailylife_bedriddendegree",
  //       displayName: "障害高齢者の日常生活自立度(寝たきり度)"
  //     },
  //     {
  //       field: "degreeofindependenceindailylife_dementia",
  //       displayName: "認知症高齢者の日常生活自立度"
  //     },
  //     {
  //       field: "contributionratecertificate_usershare",
  //       displayName: "負担割合証(利用者負担の割合)"
  //     },
  //     {
  //       field: "specialaddress_insurercode",
  //       displayName: "住所地特例(保険者コード)"
  //     },
  //     {
  //       field: "specialaddress_insurername",
  //       displayName: "住所地特例(保険者名)"
  //     },
  //     {
  //       field: "expirationdate_startdate",
  //       displayName: "有効期限(開始日)"
  //     },
  //     {
  //       field: "expirationdate_enddate",
  //       displayName: "有効期限(終了日)"
  //     },
  //     {
  //       field: "medicalinsurancesystem_legalcode",
  //       displayName: "医療保険制度（法別コード)"
  //     },
  //     {
  //       field: "medicalinsurancesystem_name",
  //       displayName: "医療保険制度（名称)"
  //     },
  //     {
  //       field: "insurernumber",
  //       displayName: "保険者番号"
  //     },
  //     {
  //       field: "insurernumber_6digits",
  //       displayName: "保険者番号（６桁)"
  //     },
  //     {
  //       field: "nameofinsurer",
  //       displayName: "保険者名称"
  //     },
  //     {
  //       field: "symbolsnumbers",
  //       displayName: "記号・番号"
  //     },
  //     {
  //       field: "municipalnumber",
  //       displayName: "市町村番号"
  //     },
  //     {
  //       field: "nameofmunicipality",
  //       displayName: "市町村名称"
  //     },
  //     {
  //       field: "geriactriccarebeneficiarynumber",
  //       displayName: "老人医療受給者番号"
  //     },
  //     {
  //       field: "medicalinsurancebenefitratio",
  //       displayName: "医療保険給付割合"
  //     },
  //     {
  //       field: "occupationalgrounds",
  //       displayName: "職務上の事由区分"
  //     },
  //     {
  //       field: "specialnote",
  //       displayName: "特記"
  //     },
  //     {
  //       field: "typeofmedicalinsurance",
  //       displayName: "医療保険種別"
  //     },
  //     {
  //       field: "categoryofinsuredperson",
  //       displayName: "被保険者区分"
  //     },
  //     {
  //       field: "officeinformation_officecode",
  //       displayName: "事業所情報_事業所コード"
  //     },
  //     {
  //       field: "officeinformation_officecode",
  //       displayName: "事業所情報_事業所コード"
  //     },
  //     {
  //       field: "providerdesignatednumber",
  //       displayName: "提供事業所_指定番号"
  //     },
  //     {
  //       field: "providername",
  //       displayName: "提供事業所_名称"
  //     },
  //     {
  //       field: "businessestablishmentservicetypecode",
  //       displayName: "提供事業所_サービス種別（コード)"
  //     },
  //     {
  //       field: "serviceprovidertypeofservicename",
  //       displayName: "提供事業所_サービス種別（名称)"
  //     },
  //     {
  //       field: "serviceproviderstartdate",
  //       displayName: "提供事業所_訪問開始日"
  //     },
  //     {
  //       field: "serviceproviderfinishdate",
  //       displayName: "提供事業所_訪問終了日"
  //     },
  //     {
  //       field: "serviceprovidervisitendtime",
  //       displayName: "提供事業所_訪問終了時刻"
  //     },
  //     {
  //       field: "serviceproviderfinishstatus",
  //       displayName: "提供事業所_訪問終了状況"
  //     },
  //     {
  //       field: "providerdateofdeath",
  //       displayName: "提供事業所_死亡日"
  //     },
  //     {
  //       field: "providertimeofdeath",
  //       displayName: "提供事業所_死亡時刻"
  //     },
  //     {
  //       field: "providerreasonfortermination",
  //       displayName: "提供事業所_終了理由その他"
  //     },
  //     {
  //       field: "providedplaceofdeath",
  //       displayName: "提供事業所_死亡場所"
  //     },
  //     {
  //       field: "providedplaceofdeathetc",
  //       displayName: "提供事業所_終了理由その他"
  //     },
  //     {
  //       field: "creatorcategory",
  //       displayName: "作成者区分"
  //     },
  //     {
  //       field: "creationcategory",
  //       displayName: "作成区分"
  //     },
  //     {
  //       field: "certificationcategory",
  //       displayName: "認定区分"
  //     },
  //     {
  //       field: "consignmentclassification",
  //       displayName: "委託区分"
  //     },
  //     {
  //       field: "servicecode",
  //       displayName: "サービスコード"
  //     },
  //     {
  //       field: "plancreationdate",
  //       displayName: "計画作成日"
  //     },
  //     {
  //       field: "remarks",
  //       displayName: "備考"
  //     },
  //     {
  //       field: "noinsuranceclaim",
  //       displayName: "保険請求しない"
  //     },
  //     {
  //       field: "hold",
  //       displayName: "保留"
  //     },
  //     {
  //       field: "planning",
  //       displayName: "計画作成"
  //     },
  //     {
  //       field: "resultinput",
  //       displayName: "実績入力"
  //     },
  //     {
  //       field: "nationalhealthinsuranceclaim",
  //       displayName: "国保請求"
  //     },
  //     {
  //       field: "return",
  //       displayName: "返戻"
  //     },
  //     {
  //       field: "error",
  //       displayName: "過誤"
  //     },
  //     {
  //       field: "depositstonationalheathinsurance",
  //       displayName: "国保入金"
  //     },
  //     {
  //       field: "individualbilling",
  //       displayName: "個人請求"
  //     },
  //     {
  //       field: "personaldeposit",
  //       displayName: "個人入金"
  //     },
  //     {
  //       field: "invoicingofconsignmentexpenses",
  //       displayName: "受託費請求"
  //     },
  //     {
  //       field: "receiptofconsignmentfee",
  //       displayName: "受託費入金"
  //     },
  //     //config toi so 145 cua ⓶請求金額CSV, ma pendding 2 va 3.
  //   ]
  // },
  // {
  //   name: "請求額集計",
  //   table: "SUM_BILLED_AMOUNT"
  // }
]

export const SEIKYUU_TYPE  = [
  ["0", "1"],    // Type 1
  // ... defined more if needed
]