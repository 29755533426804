
import { COMMON_TEXT } from 'src/app/const/text-common';
import { DEFAULT_VALUE } from 'src/app/const/table-col-define';
import { DataType } from 'src/app/enum/common-enum';
import { TableData } from 'src/app/models/table-model';
import { checkOperatorDefault, ReplaceAllDefaultOperator } from './operator-custom-helper';


export const getConfigTable = () => {
  return  {
    config: {
      id: '1',
      caption: '',
      emptyMessage: COMMON_TEXT.NO_DATA,
      showIndex: false,
      showCheckbox: false,
      showPagination: false,
      hoverShowCheckbox: true,
      isResponsive: true,
      isSelectedRow: true,
    },
    header: [
      {
        field: 'defaultSettingValue',
        title: DEFAULT_VALUE.NAME,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '100%' },
          row: {}
        },
        dataType: DataType.TEXT,
        visible: true,
        isHasSubText: true
      },
    ],
    body: []
  };
};

export const updateSelected = (row: any, defaultValueSettingConfig: TableData) => {
  defaultValueSettingConfig.body.forEach((d: any) => {
    d.isChecked = (d.id === row.id) ? true : false;
  });
}

export const updateEditItem = (row: any, defaultValueSettingConfig: TableData) => {
  defaultValueSettingConfig.body.forEach((d: any) => {
    d.isEditing = row == null?  false : (d.id === row.id ? true : false);
  });
}


export const focusInput = (id: any, document: Document) => {
  setTimeout(() => {
    const input = document.getElementById(`${id}`);
      if (input) {
        input.focus();
      }
  }, 300);
}

export const updateDSStuctCDDForDefaultFuntion = (functionString: string, data:any, fn:any ) => {

  let params = checkOperatorDefault(fn);
  let parArray = (params??"").split(";");
  if (parArray.length >= 5) {
    let itag = parArray[3].replace(/\"/g,"");
    let columnname = data.columns?.find((c:any) => c.displayname === itag)?.columnname;
    let opOld = `DEFAULT(${params})`;
    let opNew = `DEFAULT(${parArray[0]};${parArray[1]};${parArray[2]};${parArray[3]};${parArray[4]};"${data.dsstructcd}";"${columnname}")`;
    functionString = functionString.replace(opOld, opNew);
  }
  return functionString;
}

export const getDefaultFuntionFromSetting = (settingValueConfig: any) => {
  let paramDefault: any[] = []
  if(!settingValueConfig?.targetTable?.length) return paramDefault
  settingValueConfig.targetTable.map((setting: any) => {
    if (setting?.targetTable && setting.targetTable.length > 0) {
      setting.targetTable.map((col: any) => {
        let funtionDF = ReplaceAllDefaultOperator(col.targetValue, []);
        if (funtionDF && funtionDF.lstdefault && funtionDF.lstdefault.length > 0) {
          funtionDF.lstdefault.map((fn: any) => {
            if (!paramDefault.find((parma: any) => parma == fn))
              paramDefault.push(fn)
          });
        }
      })
    }
  })
  return paramDefault;
}

export const updateDfFuntionTemplate = (functionString: string, data:any, fn:any ) => {

  let params = checkOperatorDefault(fn);
  let parArray = (params??"").split(";");
  if (parArray.length >= 5) {

   let findcontion = data.find((d:any) => d.contiontemplate.defaultvaluesettingcd == parArray[0].replace(/\"/g,"")
                  && d.contiontemplate.kbntag == parArray[1].replace(/\"/g,"")
                  && d.contiontemplate.ttltag == parArray[2].replace(/\"/g,"")
                );
    if (findcontion && findcontion.contionuser.defaultvaluesettingcd!=null) {
      let itag = parArray[3].replace(/\"/g,""); 
      let opOld = `DEFAULT(${params})`;
      let opNew = `DEFAULT(${findcontion.contionuser.defaultvaluesettingcd};"${findcontion.contionuser.kbntag}";"${findcontion.contionuser.ttltag}";"${itag}";"${findcontion.contionuser.defaultvaluesettingname}")`;
      functionString = functionString.replace(opOld, opNew);
    } else{
      let opOld = `DEFAULT(${params})`;
      functionString = functionString.replace(opOld, "");

    }
   
  }
  return functionString;
}

export const updateValueSettingForDFFuntion = (settingValueConfig: any, res: any, isTemplate:boolean = false):any => {
  settingValueConfig.targetTable.map((setting: any) => {
    if(setting.targetTable.length > 0){
      setting.targetTable.map((col: any) => {
        let funtionDF = ReplaceAllDefaultOperator(col.targetValue, []);
        if (funtionDF && funtionDF.lstdefault && funtionDF.lstdefault.length > 0) {
          funtionDF.lstdefault.map((fn: any) => {
            if(isTemplate){
              col.targetValue = updateDfFuntionTemplate(col.targetValue, res.data, fn);
            } else{
              col.targetValue = updateDSStuctCDDForDefaultFuntion(col.targetValue, res.data, fn);
            }
          });
        }
      })
    }
  });

  return settingValueConfig;
}

export const  getDSDefaultRequest = (paramDefaultFuntion: any, isTemplate: boolean, listValueDefaultSetting: any, officeList:any) => {
  let dsDefaultRequest: any[] = [];
  paramDefaultFuntion.map((defaultFn: any)=>{
    let param = checkOperatorDefault(defaultFn);
    if(param !=null ){
      let paramArry = param.split(";");
      if (paramArry.length >= 4) {
        let offices:any;
        if(!isTemplate){
           let dfValueSetting = listValueDefaultSetting.find((df: any) => df?.defaultValueSetting?.defaultvaluesettingcd == paramArry[0]);
           offices = dfValueSetting?.defaultValueSetting?.officeidlst ?? "";
        }else{
          offices = officeList;
        }
        let dfName = "";
        if(paramArry.length >= 5) dfName = paramArry[4].replace(/\"/g,"")
        let ds = dsDefaultRequest.find((df: any) =>  df.defaultvaluesettingcd == paramArry[0] && df.kbntag == paramArry[1] && df.ttltag == paramArry[2] && df.itag == paramArry[4]);
        if(!ds) {
          dsDefaultRequest.push({
            defaultvaluesettingcd : paramArry[0],
            kbntag : paramArry[1].replace(/\"/g,""),
            ttltag : paramArry[2].replace(/\"/g,""),
            itag : paramArry[3].replace(/\"/g,""),
            defaultvaluesettingname: dfName,
            officecdlst: offices
          })
        }

      }
    }
  });

  return dsDefaultRequest;
}