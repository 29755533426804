import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {ErrorHandleService} from '../error-handle.service';
import {ROUTE_PATH} from '../../const/route-path';
import {LocalStorageKey} from '../../_helper/local-storage.helper';
import {ERROR_TEXT, FUNCTION_TITLE_TEXT} from '../../const/error-text';
import {CanDeactivateWidget} from './can-deactivate-widget.service';

interface RouteMap {
  [key: string]: {
    [key: string]: string;
  };
}
@Injectable({
  providedIn: 'root'
})
export class CanActivatePivotListsService {

  constructor(
    private authenticationService: AuthenticationService,
    private errorHandleService: ErrorHandleService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (state?.url === ("/" + ROUTE_PATH.AUTH)) return true;
    let currentRole = this.authenticationService.getData(LocalStorageKey.CURRENT_USER_ROLE);
    if (currentRole && currentRole.length > 0 && currentRole[0].permissions) {

      let currentState = state?.url;
      currentState = this.getRealRoute(currentState);
      let permissionTitleForChecking: string = this.getRequiredPermissionToAccessToStateRoute(currentState);
      if(currentRole[0].permissions.includes(permissionTitleForChecking)) {
        return true;
      }
      else {
        this.openForbiddenErrorScreen();
        return false;
      }
    } 
    else {
      this.openForbiddenErrorScreen();
      return false;
    }
  }

  getRealRoute(state: string) {
    const routeMap: RouteMap = {
      datasource: {
        create: ROUTE_PATH.DATASOURCE_CREATE,
        detail: ROUTE_PATH.DATASOURCE_EDIT,
        list: ROUTE_PATH.DATASOURCE,
      },
      dashboard: {
        create: ROUTE_PATH.DASHBOARD_CREATE,
        detail: ROUTE_PATH.DASHBOARD_DETAIL,
        list: ROUTE_PATH.DASHBOARD_LIST,
      },
      widget: {
        create: ROUTE_PATH.WIDGET_CREATE,
        detail: ROUTE_PATH.WIDGET_DETAIL,
        list: ROUTE_PATH.WIDGET_LIST,
      },
      // 'dashboard-template': {
      //   widget: ROUTE_PATH.DASHBOARD_WIDGET_TEMPLATE
      // }
    };
  
    const parts = state.split("/").filter(part => part !== '' && part !== null);
  
    if (parts.length >= 2) {
      const [mainRouteName, action] = parts;
  
      if (routeMap[mainRouteName] && routeMap[mainRouteName][action]) {
        return `/${routeMap[mainRouteName][action]}`;
      }
    }
    return "";
  }
  

  getRequiredPermissionToAccessToStateRoute(routeString: string) {
    let permissionTitle: string = "";
    switch(routeString) {
      case "/datasource/list":
      case "/datasource/create":
      case "/datasource/detail":
        permissionTitle = "#datasource"
        break;
      case "/widget/list":
      case "/widget/create":
      case "/widget/detail":
      case "/dashboard/list":
      case "/dashboard/create":
      case "/dashboard/detail":
      // case "/dashboard-template/widget" :
        permissionTitle = "#dashboard";
        break;
      default:
        break;
    }
    return permissionTitle;
  }

  openForbiddenErrorScreen(): void {
    this.errorHandleService.customErrorDetails(403, ERROR_TEXT.FORBIDDEN_TITLE, ERROR_TEXT.FORBIDDEN, "", FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL, false);
    this.errorHandleService.navigateTo([ROUTE_PATH.PAGE_ERROR])
  }
}
