import { ColumnType, DataType, FormatType, DateFormat, FooterOption, SummaryColumnOption, FilterGroup } from "../enum/common-enum";
import { paramInt, JsonObject, ListBoxParams, option, paramStr, optionInt } from "../models/common-model";


export const DATE_MIN_VALUE = new Date(-8640000000000000);

export const DATE_MAX_VALUE = new Date(8640000000000000);

export const DATA_SOURCE_DEFAULT_HEADERS = [
  {
    field: 'objcd',
    title: '対象者コード',
    sortable: false,
    filterable: false,
    visible: true,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 0
  },
  {
    field: 'objnm',
    title: '対象者名',
    sortable: false,
    filterable: false,
    visible: true,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 1
  },
  {
    field: 'corpcd',
    title: '法人コード',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 2
  },
  {
    field: 'officecd',
    title: '事業所コード',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 3
  },
  {
    field: 'officenm',
    title: '事業所',
    sortable: false,
    filterable: false,
    visible: true,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 4
  },
  {
    field: 'blockcd',
    title: 'ブロックコード',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 5
  },
  {
    field: 'unitcd',
    title: 'ユニットコード',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 6
  },
  //sortno 7 and sortno 8 is the roomNm and scheduleBedCd [POVOT-3288]
  {
    field: 'ttlnm',
    title: 'タイトル名',
    sortable: false,
    filterable: false,
    visible: true,
    dataType: DataType.TEXT,
    sortno: 9
  },
  {
    field: 'ownstfcd',
    title: '職員番号',
    sortable: false,
    filterable: false,
    visible: false,
    dataType: DataType.TEXT,
    sortno: 10
  },
  {
    field: 'ownstfnm',
    title: '記録者',
    sortable: false,
    filterable: false,
    visible: false,
    dataType: DataType.TEXT,
    sortno: 11
  },
  {
    field: 'ttlkbn',
    title: 'タイトル区分コード',
    sortable: false,
    filterable: false,
    visible: false,
    dataType: DataType.TEXT,
    sortno: 12
  },
  {
    field: 'ttlcd',
    title: 'タイトルコード',
    sortable: false,
    filterable: false,
    visible: false,
    dataType: DataType.TEXT,
    sortno: 13
  },

  {
    field: 'sdate',
    title: '開始日時',
    sortable: false,
    filterable: false,
    visible: true,
    dataType: DataType.TEXT,
    sortno: 14
  },
  {
    field: 'edate',
    title: '終了日時',
    sortable: false,
    filterable: false,
    visible: true,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 15
  },
  {
    field: 'prvtime',
    title: '提供時間(分)',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 15.1
  },
  {
    field: 'prvhour',
    title: '提供時間(時間)',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 15.2
  },
  {
    field: 'odrstfnm',
    title: '担当者１',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 15.3
  },
  {
    field: 'sndstfnm',
    title: '担当者２',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 15.4
  },
  {
    field: 'NOTEKBN',
    title: '記録種別',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: {},
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 15
  },
  {
    field: 'dockbn',
    title: '文書種別',
    sortable: false,
    filterable: false,
    visible: false,
    dataType: DataType.TEXT,
    sortno: 15
  },
  {
    field: 'pickupflg',
    title: '申し送り',
    sortable: false,
    filterable: false,
    visible: false,
    dataType: DataType.TEXT,
    sortno: 15
  },
  {
    field: 'resultatr',
    title: '実績',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: Number.MAX_SAFE_INTEGER - 2
  },
  {
    field: 'roomattr',
    title: '部屋属性',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: Number.MAX_SAFE_INTEGER - 1
  },
  {
    field: 'totaldays',
    title: '利用日数',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {},
      childrenCols: [],
      isMergeCells: false
    },
    dataType: DataType.NUMBER,
    sortno: Number.MAX_SAFE_INTEGER
  },
  {
    field: 'totaltime',
    title: '利用回数',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.NUMBER,
    sortno: Number.MAX_SAFE_INTEGER
  },
  {
    field: 'dspdate',
    title: '日付',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.DATETIME,
    sortno: Number.MAX_SAFE_INTEGER
  },
  {
    field: 'blocknm',
    title: 'ブロック',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: Number.MAX_SAFE_INTEGER
  },
  {
    field: 'unitnm',
    title: 'ユニット',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: Number.MAX_SAFE_INTEGER
  },
  {
    field: 'roomnm',
    title: '部屋名',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: Number.MAX_SAFE_INTEGER 
  },
  {
    field: 'schedbedcd',
    title: 'ベッド番号',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: Number.MAX_SAFE_INTEGER 
  },
  {
    field: 'age',
    title: '年齢',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.NUMBER,
    sortno: Number.MAX_SAFE_INTEGER
  },
  {
    field: 'calcage',
    title: '年齢算出日',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.DATETIME,
    sortno: Number.MAX_SAFE_INTEGER
  },
  {
    field: 'lapsedday',
    title: '経過日数',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.NUMBER,
    sortno: Number.MAX_SAFE_INTEGER
  }

]
export const DATA_SOURCE_DEFAULT_HEADERS_MASTER = [
  {
    field: 'dbl_officecd',
    title: '事業所コード',
    sortable: false,
    filterable: false,
    visible: false, 
    insertdata: true,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 0,
    data: {
      columnname: "OFFICECD",
      columntype: 2,
      datatype: "VARCHAR",
      delflg: false,
      displayname: "事業所コード",
      id: "",
      innerjoin: "",
      outputdata: {type: 0, classcd: '', table: '', joincolumn: '', columnname: ''},
      table: "DAIBELONG",
      tablealias: "DBL",
      tablecondition: "",
      tabtype: 1,
      tooltip: ""
    },
  },
  {
    field: 'dbl_officenm',
    title: '事業所',
    sortable: false,
    filterable: false,
    visible: true,
    insertdata: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.TEXT,
    sortno: 1,
    data: {
      columnname: "OFFICENM",
      columntype: 2,
      datatype: "VARCHAR",
      delflg: false,
      displayname: "事業所",
      id: "",
      innerjoin: "",
      outputdata: { type: 0, classcd: '', table: '', joincolumn: '', columnname: '' },
      table: "DAIBELONG",
      tablealias: "DBL",
      tablecondition: "",
      tabtype: 1,
      tooltip: ""
    },
  },

]



export const sharedDataKey = {
  SYNCLOGS: 'syncLogs',
  PRESYNCLOGS: 'preSyncLogs',
};

export const DATE_MIN_STRING = '0001-01-01T00:00:00';

export const MAXIMUM_MONTH = '9999-99';

export const InvisibleColumn: any = {
  CORPCD: true,
  OFFICECD: true,
  BLOCKCD: true,
  UNITCD: true,
  TTLKBN: true,
  TTLCD: true,
  NOTEKBN: true,
  DOCKBN: true,
  PICKUPFLG: true,
  DBL_OFFICECD: true,
}

export const InvisibleInCustom: any = {
  RESULTATR: true,
  SDATE: true,
  EDATE: true,
  OFFICENM: true,
  TTLNM: true,
  OBJCD: true,
  OBJNM: true,
  PRVHOUR: true // This col is not support this phase
}

export const LBParam: ListBoxParams = {
  items: [],
  displayfilter: false,
  itemDisplayText: 'name',
  cssStyle: { width: '100%', height: '40px' },
}

export const TVPParams = {
  nodes: [],
  expandIcon: '../../../assets/icons/folder.svg',
  collapseIcon: '../../../assets/icons/folder-opened.svg'
}

export const DSCUSTOM = 'DS-CUSTOM'
export const DSTARGET = 'DS-TARGET'
export const FooterName = 'COLUMN-F'
export const SummaryColumnName = 'COLUMN-S'
export const NoFilter = 'no-filter'
export const ColFilterGroup = 'COLFILTER'
export const ItemNoSelect = { name: 'なし', value: NoFilter, type: "no" };
export const customPeriod: any = [{value: "-1", label: "ユーザー定義期間", items: [{value: "-1", label: "カスタマイズ"}]}]

export enum MstFilterType {
  // Type
  Condition = '001',
  Filter = '002',

  // Group
  Group001 = '001', //文字列
  Group002 = '002', //数値
  Group003 = '003', //日付
}

export enum MstFilterGroup {
  VARCHAR = "001",              // 文字列 varchar
  INT = "002",                  // 数値 number
  DATATIME = "003"              // 日付 date
}
export enum MstFilterVarcharItem {
  Equal = "001",                // ・入力文字と一致する 	khớp với ký tự đầu vào	match the input character
  Contains = "002",             // 入力文字を含む chứa các ký tự đầu vào	contains input characters
  EndsWith = "003",             // 入力文字で終わる kết thúc bằng ký tự đầu vào	ends with the input character
  StartsWith = "004",           // 入力文字で始まる bắt đầu với ký tự đầu vào	start with the input character
  DoesNotEqual = "005",         // 入力文字と一致しない không khớp với các ký tự đầu vào does not match input characters
  DoesNotContain = "006"        // 入力文字を含まない không chứa các ký tự đầu vào	does not contain input characters
}
export enum MstFilterIntItem {
  Contains = "001",             // 入力値を含む Chứa giá trị đầu vào	contains the input value
  DoesNotContain = "002",       // 入力値を含まない không chứa giá trị đầu vào	does not contain the input value
  LessOrEqual = "003",          // 入力値以下 nhỏ hơn hoặc bằng Giá trị đầu vào 	less than or equal to input value
  DoesNotEqual = "004",         // 入力値と一致しない Không khớp với giá trị đầu vào	Does not match input value
  GreaterOrEqual = "005",       // 入力値以上 lớn hơn hoặc bằng giá trị đầu vào	greater than or equal to input value
  Equal = "006",                //・入力値と一致する phù hợp với giá trị đầu vào	matches the input value
  Max = "007",                  // 最大値 giá trị lớn nhất Max value
  Min = "008"                   // 最小値 giá trị nhỏ nhất Min value
}
export enum MstFilterDataTimeItem {
  ThisYear = "001",             // 今年 Năm nay	this year
  Today = "002",                // 今日 hôm nay today
  LastYear = "003",             // 去年 năm ngoái	last year
  ThisMonth = "004",            // 今月 tháng này	this month
  FullSchedule = "005",         // 全日程 Lịch trình đầy đủ	Full schedule
  After = "006",                // 入力値より以後 sau giá trị đầu vào	 after the input value
  ThisWeek = "007",             // 今週 tuần này	this week
  Yesterday = "008",            // 昨日 hôm qua	yesterday
  LastWeek = "009",             // 先週 tuần trước last week
  LastMonth = "010",            // 先月 tháng trước	last month
  Equal = "011",                // 入力値と一致する phù hợp với giá trị đầu vào	matches the input value
  Before = "012",               // 入力値より以前 Trước giá trị đầu vào	Before input value
  ThisFiscalYear = "013",       // 本年度 năm nay (tính theo năm tài chính)
  LastFiscalYear = "014",       // 前年度 năm trước (tính theo năm tài chính)
  TwoFiscalYearAgo = "015",     // 前々年度 năm trước nữa (tính theo năm tài chính)
  UntilToday= "016",            // 〜今日まで cho đến ngày hiện 〜今週まで
  UntilThisWeek = "017",        // 〜今週まで cho đến tuần hiện tại
  UntilThisMonth = "018",        // 〜今月まで cho đến tháng hiện until this week
  EndOfThisMonth = "019",        // 今月末日 Cuối tháng này End of this month (2024年9月30日のデータ)
  EndOfLastMonth = "020",        // 先月末日 Cuối tháng trước End of last month (2024年8月31日のデータ)
  EndOfEachMonthThisYear = "021",// 今年毎月末日 Mỗi cuối tháng của năm nay End of each month this year (1月31日、2月28日…12月31日)
  EndOfEachMonthLastYear = "022",// 去年の毎月末日 Mỗi cuối tháng của năm ngoái End of each month last year (1月31日、2月28日…12月31日)
  EndOfEachMonthThisFiscalYear = "023", // 本年度の毎月末日 Mỗi cuối tháng của năm tài chính hiện tại End of each month this fiscal year
  EndOfEachMonthLastFiscalYear = "024", // 前年度の毎月末日 Mỗi cuối tháng của năm tài chính trước End of each month last fiscal year
  EndOfEachMonthTwoFiscalYearAgo = "025" // 前々年度の毎月末日 Mỗi cuối tháng của năm tài chính trước nữa End of each month two fiscal years ago
}

export const SideMenu = {
  DASHBOARD: '#dashboard',
  DATASOURCE: '#datasource',
  SYSTEM_SETTING: '#systemsetting',
  ZAITAKU: '#zaitaku',
  QUICK_SETTING_WIDGET: '#quicksetting'
}

export const PivotOptions: option[] = [
  { name: 'なし', value: FormatType.NoFormatA },

  { name: '日', value: FormatType.Date },        //  Ngày
  { name: '月', value: FormatType.Month },       //  Tháng
  { name: '年', value: FormatType.Year },        //  Năm
  { name: 'グループ', value: FormatType.Group },  //  Group
]

export const PivotValueOptions: option[] = [
  { name: '件数', value: FormatType.Count },        //  COUNT
  { name: '合計', value: FormatType.Sum },          //  SUM
  { name: '合計(時間)', value: FormatType.TimeSum }, //  SUM TIME
  { name: '平均', value: FormatType.Average },      //  Average
  { name: '割合', value: FormatType.Percent },      //  Percent
  { name: '実数', value: FormatType.CountUnique },  //  DISTINCT
]

export const PivotFooterOptions = [
  { name: 'なし', value: undefined },
  { name: '件数 (全て)', value: FooterOption.Count },
  { name: '件数 (一意)', value: FooterOption.CountUnique },
  { name: '合計', value: FooterOption.Sum },
  { name: '平均', value: FooterOption.Average },
]

export const PivotGraphType = [
  { name: '棒', value: 0 },
  { name: '折れ線', value: 1},
]

export const PivotSummaryColumnOptions = [
  { name: 'なし', value: undefined },
  { name: '件数 (全て)', value: SummaryColumnOption.Count },
  { name: '件数 (一意)', value: SummaryColumnOption.CountUnique },
  { name: '合計', value: SummaryColumnOption.Sum },
  { name: '平均', value: SummaryColumnOption.Average },
]

export const JapanDateFormat: JsonObject = {
  [FormatType.Date]: DateFormat.JP_FULL_LONG_DATE,
  [FormatType.Month]: DateFormat.JP_LONG_DATE,
  [FormatType.Year]: DateFormat.JP_YEAR,
}

export const DATATYPE = {
  Number: 'INT',
  Float: 'FLOAT',
  String: 'VARCHAR',
  Date: 'DATETIME',
  Custom: 'CUSTOM'
}

const  [r, c, v, hv]   = [ColumnType.Row, ColumnType.Column, ColumnType.Value, ColumnType.Hidden_Value]
export const InRow    = [r, r + v, r + hv, r + v + hv ].reduce((a: any, b) => (a[b] = true, a), {})
export const InColumn = [c, c + v, c + hv, c + v + hv].reduce((a: any, b) => (a[b] = true, a), {})
export const InValue  = [v, v + r, v + c, v + hv, v + r + hv, v + c + hv].reduce((a: any, b) => (a[b] = true, a), {})
export const InHiddenValue  = [hv, hv + r, hv + c, hv + v].reduce((a: any, b) => (a[b] = true, a), {})



export const AccessOptions: paramInt[] = [
  { name: '表示', value: 1 }, 
  { name: '編集', value: 2 }, 
  { name: '管理', value: 3 }
]

export const PeriodOptions: paramInt[] = [
  { name: 'カナ名', value: 1 }, 
  { name: 'メール', value: 2 }, 
  { name: 'ロール', value: 3 }, 
  { name: 'は皮膚', value: 4 }, 
  { name: '膚', value: 5 }
]

export const DataTypeOptions: paramStr[] = [
  { name: "文字列", value: 'VARCHAR', visible: false },
  { name: "数字", value: 'INT', visible: false },
  { name: "数字", value: 'FLOAT', visible: false  },
  { name: "日付", value: 'DATETIME', visible: false  }
]

export const MoreActionOptions: optionInt[] = [
  {
    name: 'ダウンロード',
    key: 1
  },
  {
    name: '別名で保存',
    key: 2
  },
  {
    name: '新規ダッシュボード',
    key: 3
  },
  {
    name: '削除',
    key: 4,
  }
]

export const OrderBy = {
  ASC: 'asc',
  DESC: 'desc',
}

export const SortType = {
  INSDATE: 'insdate',
  SORT_NO: 'sortno',
  CATEGORY_CD: 'categorycd',
  UPDDATE: 'upddate',
  DATE: 'date',
  KEY: 'key',
}


export const DateTimeExpressions= [
  "DD/MM/YYYY HH:mm:ss",
  "YYYY-MM-DD HH:mm",
  "YYYY-MM-DD HH:mm:ss",
  "YYYY-MM-DDTHH:mm:ss",
  "YYYY-MM-DD",
  "DD-MM-YYYY",
  "M/D/YYYY h:mm:ss A",
]

export const DATA_SOURCE_EXTRA_MASTER_HEADERS = [
  {
    field: 'numberofdays',
    title: '利用日数',
    sortable: false,
    filterable: false,
    visible: false,
    attribute: {
      header: { },
      row: {}
    },
    dataType: DataType.NUMBER,
    sortno: Number.MAX_SAFE_INTEGER-3
  },
]

export const ProcessBarCondition = {
  NOT_START: 'NOT START',
  START: 'START',
  DONE: 'DONE',
  ERROR: 'ERROR',
}

export const TEAM_EVERYONE = 'TM00000000';
export const DASHBOARD_CD_NEW = 'DB00000_New';

export const SystemSettingPage = {
  ROLE_SETTING: "role-setting",
  CORP_SETTING: "corp-setting",
  STAFF_INFO: "staff-info",
  MASTER_SYNC: "master-sync",
  DEFAULT_VALUE: "default-setting",
}

export const FilterValueOption = [
  { name: '本年度', value: '003-013' }, 
  { name: '前年度', value: '003-014' }, 
  { name: '前々年度', value: '003-015'}
]

export const FilterEndOfMonthCurrent = [
  { name: '今年毎月末日', value: '003-021' },
  { name: '去年の毎月末日', value: '003-022' },
  { name: '本年度の毎月末日', value: '003-023' },
  { name: '前年度の毎月末日', value: '003-024' },
  { name: '前々年度の毎月末日', value: '003-025' }
]

export const FilterDateCurrent = [
  { name: '〜今日まで', value: '003-016' }, 
  { name: '〜今週まで', value: '003-017' }, 
  { name: '〜今月まで', value: '003-018' }
]

// danh sách các option không được lựa chọn value đối với trường hợp filter table data type là Date
export const  UnChooseAble : string [] = [
  MstFilterDataTimeItem.ThisYear, 
  MstFilterDataTimeItem.Today, 
  MstFilterDataTimeItem.ThisMonth, 
  MstFilterDataTimeItem.LastYear, 
  MstFilterDataTimeItem.ThisWeek, 
  MstFilterDataTimeItem.Yesterday, 
  MstFilterDataTimeItem.LastWeek, 
  MstFilterDataTimeItem.LastMonth, 
  MstFilterDataTimeItem.ThisFiscalYear, 
  MstFilterDataTimeItem.LastFiscalYear, 
  MstFilterDataTimeItem.TwoFiscalYearAgo, 
  MstFilterDataTimeItem.UntilToday, 
  MstFilterDataTimeItem.UntilThisWeek, 
  MstFilterDataTimeItem.UntilThisMonth,
  MstFilterDataTimeItem.EndOfThisMonth,     
  MstFilterDataTimeItem.EndOfLastMonth,        
  MstFilterDataTimeItem.EndOfEachMonthThisYear,
  MstFilterDataTimeItem.EndOfEachMonthLastYear,
  MstFilterDataTimeItem.EndOfEachMonthThisFiscalYear, 
  MstFilterDataTimeItem.EndOfEachMonthLastFiscalYear, 
  MstFilterDataTimeItem.EndOfEachMonthTwoFiscalYearAgo
];

export const FilterGroupOptions = [
  { name: 'なし', value: FilterGroup.NONE, optionName: 'ない' },
  { name: '年月', value: FilterGroup.YEAR_MONTH, optionName: '月' },
  { name: '月', value: FilterGroup.MONTH, optionName: '月' },
  { name: '月日', value: FilterGroup.MONTH_DAY, optionName: '日' },
  { name: '日', value: FilterGroup.DAY , optionName: '日'},
  { name: '時刻', value: FilterGroup.HOUR_MINUTE, optionName: '分' },
  { name: '時', value: FilterGroup.HOUR, optionName: '時' }
]

export const TableLimits = {
  maxColumns: 500,
  maxRows: 100000
};

export const CONSOLE_CATEGORY_CODES = {
  ALL: 999,
  HELP: 1,
  MAINTENANCE: 2,
  VERSION_UPGRADE: 3,
  OTHERS: 4,
}

export const CONSOLE_CATEGORY_VALUES = {
  ALL: '全て表示',
  HELP: "ヘルプ",
  MAINTENANCE: "メンテナンス",
  VERSION_UPGRADE: "バージョンアップ",
  OTHERS: "その他",
}

export const CONSOLE_CATEGORIES = [
  {
    name: CONSOLE_CATEGORY_VALUES.ALL,
    code: CONSOLE_CATEGORY_CODES.ALL,
  },
  {
    name:  CONSOLE_CATEGORY_VALUES.VERSION_UPGRADE,
    code: CONSOLE_CATEGORY_CODES.VERSION_UPGRADE,
  },
  {
    name: CONSOLE_CATEGORY_VALUES.HELP,
    code: CONSOLE_CATEGORY_CODES.HELP,
  },
  {
    name: CONSOLE_CATEGORY_VALUES.MAINTENANCE,
    code: CONSOLE_CATEGORY_CODES.MAINTENANCE,
  },
  {
    name: CONSOLE_CATEGORY_VALUES.OTHERS,
    code: CONSOLE_CATEGORY_CODES.OTHERS,
  },
];


export const ZAITAKU_USER_INFO: { [key: string]: {column: string, name: string}[] } = {
   // ■A　在宅復帰率（各月の実績）
   // Tỷ lệ về nhà
  "ZD00000013": [
    { column: "peopleLeaving", name: "居宅"}, 
    { column: "newLeavers", name: "退所者"},
    { column: "death", name: "死亡"}
  ],
  // ■B　ベッド回転率（各月の実績）
  // Tỷ lệ luân chuyển giường
  "ZD00000014": [
    { column: "cumulativeNew", name: "新規入所者の延数"},
    { column: "newLeavers", name: "新規退所者数"}
  ],
  // ■C　入所前後訪問指導割合（各月の実績）
  // Hướng dẫn chăm sóc tại nhà trước và sau khi Nhập Viện
  "ZD00000015": [
    { column: "admission", name: "入所前後訪問指導を行った者の延数"}, 
    { column: "cumulativeNew", name: "新規入所者の延数"}
  ],
  // ■D　退所前後訪問指導割合（各月の実績）
  // Hướng dẫn chăm sóc tại nhà trước và sau khi Xuất Viện
  "ZD00000016": [
    { column: "discharge", name: "退所前後訪問指導を行った者の延数"}, 
    { column: "peopleLeaving", name: "居宅への新規退所者の延数"}
  ],
  // ■I　喀痰吸引の実施割合（各月の実績）
  // Hút đờm
  "ZD00000021": [
    { column: "aspiration", name: "入所ごとの喀痰吸引を実施した延入所者日数"}
  ],
  // ■J　経管栄養の実施割合（各月の実績）
  // Tỷ lệ nuôi ăn bằng ống
  "ZD00000022": [
    { column: "feeding", name: "入所ごとの経管栄養を実施した延入所者日数"}
  ]
};

// 延入所者日数 , 新規入所者の延数, 
export const DEFAULT_AUTO_SYNC_COLS = ['cumulativeNew', 'newLeavers', 'peopleLeaving', 'death']


// 0:入所 (院)履歴、、2:居室移動履歴、3:居宅サービス開始中止履歴
export enum HISIO_KBN {
  ADMISSION = '0', // 0:入所 (院)履歴 - Lịch sử nhập viện
  OVERNIGHT = '1', // 1:外泊入院履歴 - Lịch sử nhập viện sau khi ra ngoài viện (ngủ qua đêm bên ngoài)
  CHANGE_ROOM = '2', // 2:居室移動履歴 - Lịch sử chuyển phòng
  START_OR_STOP_HOME_SERVICE = '3' // 3:居宅サービス開始中止履歴 - Lịch sử bắt đầu hoặc dừng dịch vụ tại nhà
} 

export enum RoundingOption {
  DecimalFirst = 0,    // 小数点第一を
  DecimalSecond = 1,   // 小数点第二を
  Unit = 2,            // 一の位を
  Tens = 3,            // 十の位を
  Hundreds = 4,        // 百の位を
  DecimalThird = 5,    // 少数点第三位を
}
