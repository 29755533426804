<div class="zaitaku-container">

    <ng-container *ngIf="zaitakuId === 'ZD00000001'">
        <h4><span>■</span>数値</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblRate.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblRate[0].rateA }}</td>
                        <td *ngFor="let item of dataTblRate.slice(1)">{{ item.rateA }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblRate[0].rateB }}</td>
                        <td *ngFor="let item of dataTblRate.slice(1)">{{ item.rateB }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblRate[0].rateC }}</td>
                        <td *ngFor="let item of dataTblRate.slice(1)">{{ item.rateC }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblRate[0].rateD }}</td>
                        <td *ngFor="let item of dataTblRate.slice(1)">{{ item.rateD }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblRate[0].rateE }}</td>
                        <td *ngFor="let item of dataTblRate.slice(1)">{{ item.rateE }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblRate[0].rateF }}</td>
                        <td *ngFor="let item of dataTblRate.slice(1)">{{ item.rateF }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblRate[0].rateG }}</td>
                        <td *ngFor="let item of dataTblRate.slice(1)">{{ item.rateG }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblRate[0].rateH }}</td>
                        <td *ngFor="let item of dataTblRate.slice(1)">{{ item.rateH }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblRate[0].rateI }}</td>
                        <td *ngFor="let item of dataTblRate.slice(1)">{{ item.rateI }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblRate[0].rateJ }}</td>
                        <td *ngFor="let item of dataTblRate.slice(1)">{{ item.rateJ }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h4><span>■</span>指標</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblIndex.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndex[0].idxA }}</td>
                        <td *ngFor="let item of dataTblIndex.slice(1)">{{ item.idxA }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndex[0].idxB }}</td>
                        <td *ngFor="let item of dataTblIndex.slice(1)">{{ item.idxB }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndex[0].idxC }}</td>
                        <td *ngFor="let item of dataTblIndex.slice(1)">{{ item.idxC }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndex[0].idxD }}</td>
                        <td *ngFor="let item of dataTblIndex.slice(1)">{{ item.idxD }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndex[0].idxE }}</td>
                        <td *ngFor="let item of dataTblIndex.slice(1)">{{ item.idxE }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndex[0].idxF }}</td>
                        <td *ngFor="let item of dataTblIndex.slice(1)">{{ item.idxF }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndex[0].idxG }}</td>
                        <td *ngFor="let item of dataTblIndex.slice(1)">{{ item.idxG }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndex[0].idxH }}</td>
                        <td *ngFor="let item of dataTblIndex.slice(1)">{{ item.idxH }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndex[0].idxI }}</td>
                        <td *ngFor="let item of dataTblIndex.slice(1)">{{ item.idxI }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndex[0].idxJ }}</td>
                        <td *ngFor="let item of dataTblIndex.slice(1)">{{ item.idxJ }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndex[0].sum }}</td>
                        <td *ngFor="let item of dataTblIndex.slice(1)">{{ item.sum }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="chart-container">
            <div class="zaitaku-chart">
                <div class="chart-title">在宅復帰・在宅療養支援等指標</div>
                <pivot-line-chart [data]="dataChart" [width]="width" [height]="400"></pivot-line-chart>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000002'">
        <h4><span>■</span>指標</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblIndexSum.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndexSum[0].idxA }}</td>
                        <td *ngFor="let item of dataTblIndexSum.slice(1)">{{ item.idxA }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndexSum[0].idxB }}</td>
                        <td *ngFor="let item of dataTblIndexSum.slice(1)">{{ item.idxB }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndexSum[0].idxC }}</td>
                        <td *ngFor="let item of dataTblIndexSum.slice(1)">{{ item.idxC }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndexSum[0].idxD }}</td>
                        <td *ngFor="let item of dataTblIndexSum.slice(1)">{{ item.idxD }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndexSum[0].idxE }}</td>
                        <td *ngFor="let item of dataTblIndexSum.slice(1)">{{ item.idxE }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndexSum[0].idxF }}</td>
                        <td *ngFor="let item of dataTblIndexSum.slice(1)">{{ item.idxF }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndexSum[0].idxG }}</td>
                        <td *ngFor="let item of dataTblIndexSum.slice(1)">{{ item.idxG }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndexSum[0].idxH }}</td>
                        <td *ngFor="let item of dataTblIndexSum.slice(1)">{{ item.idxH }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndexSum[0].idxI }}</td>
                        <td *ngFor="let item of dataTblIndexSum.slice(1)">{{ item.idxI }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndexSum[0].idxJ }}</td>
                        <td *ngFor="let item of dataTblIndexSum.slice(1)">{{ item.idxJ }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblIndexSum[0].sum }}</td>
                        <td *ngFor="let item of dataTblIndexSum.slice(1)">{{ item.sum }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000003'">
        <h4><span>■</span>A 在宅復帰率</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblA.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblA[0].leave }}</td>
                        <td *ngFor="let item of dataTblA.slice(1)">{{ item.leave }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblA[0].discharge }}</td>
                        <td *ngFor="let item of dataTblA.slice(1)">{{ item.discharge }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblA[0].death }}</td>
                        <td *ngFor="let item of dataTblA.slice(1)">{{ item.death }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblA[0].rate }}</td>
                        <td *ngFor="let item of dataTblA.slice(1)">{{ item.rate }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblA[0].index }}</td>
                        <td *ngFor="let item of dataTblA.slice(1)">{{ item.index }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000004'">
        <h4><span>■</span>B ベッド回転率</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblB.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblB[0].inmates }}</td>
                        <td *ngFor="let item of dataTblB.slice(1)">{{ item.inmates }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblB[0].newly }}</td>
                        <td *ngFor="let item of dataTblB.slice(1)">{{ item.newly }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblB[0].leave }}</td>
                        <td *ngFor="let item of dataTblB.slice(1)">{{ item.leave }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblB[0].rate }}</td>
                        <td *ngFor="let item of dataTblB.slice(1)">{{ item.rate }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblB[0].index }}</td>
                        <td *ngFor="let item of dataTblB.slice(1)">{{ item.index }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000005'">
        <h4><span>■</span>C 入所前後訪問指導割合</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblC.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblC[0].admission }}</td>
                        <td *ngFor="let item of dataTblC.slice(1)">{{ item.admission }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblC[0].newly }}</td>
                        <td *ngFor="let item of dataTblC.slice(1)">{{ item.newly }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblC[0].rate }}</td>
                        <td *ngFor="let item of dataTblC.slice(1)">{{ item.rate }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblC[0].index }}</td>
                        <td *ngFor="let item of dataTblC.slice(1)">{{ item.index }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000006'">
        <h4><span>■</span>D 退所前後訪問指導割合</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblD.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblD[0].discharge }}</td>
                        <td *ngFor="let item of dataTblD.slice(1)">{{ item.discharge }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblD[0].peopleLeaving }}</td>
                        <td *ngFor="let item of dataTblD.slice(1)">{{ item.peopleLeaving }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblD[0].rate }}</td>
                        <td *ngFor="let item of dataTblD.slice(1)">{{ item.rate }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblD[0].index }}</td>
                        <td *ngFor="let item of dataTblD.slice(1)">{{ item.index }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000007'">
        <h4><span>■</span>E 居宅サービスの実施状況</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblE.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblE[0].serviceType }}</td>
                        <td *ngFor="let item of dataTblE.slice(1)">{{ item.serviceType }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblE[0].index }}</td>
                        <td *ngFor="let item of dataTblE.slice(1)">{{ item.index }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000008'">
        <h4><span>■</span>F リハ専門職員の配置割合</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblF.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblF[0].healthFacility }}</td>
                        <td *ngFor="let item of dataTblF.slice(1)">{{ item.healthFacility }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblF[0].physicalTherapists }}</td>
                        <td *ngFor="let item of dataTblF.slice(1)">{{ item.physicalTherapists }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblF[0].inmates }}</td>
                        <td *ngFor="let item of dataTblF.slice(1)">{{ item.inmates }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblF[0].dayInMonth }}</td>
                        <td *ngFor="let item of dataTblF.slice(1)">{{ item.dayInMonth }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblF[0].rate }}</td>
                        <td *ngFor="let item of dataTblF.slice(1)">{{ item.rate }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblF[0].index }}</td>
                        <td *ngFor="let item of dataTblF.slice(1)">{{ item.index }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000009'">
        <h4><span>■</span>G 支援相談員の配置割合</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblG.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblG[0].careHealthFacility }}</td>
                        <td *ngFor="let item of dataTblG.slice(1)">{{ item.careHealthFacility }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblG[0].coumselors }}</td>
                        <td *ngFor="let item of dataTblG.slice(1)">{{ item.coumselors }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblG[0].inmates }}</td>
                        <td *ngFor="let item of dataTblG.slice(1)">{{ item.inmates }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblG[0].dayInMonth }}</td>
                        <td *ngFor="let item of dataTblG.slice(1)">{{ item.dayInMonth }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblG[0].rate }}</td>
                        <td *ngFor="let item of dataTblG.slice(1)">{{ item.rate }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblG[0].index }}</td>
                        <td *ngFor="let item of dataTblG.slice(1)">{{ item.index }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000010'">
        <h4><span>■</span>H 介護度４又は５の割合</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblH.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblH[0].cumulativeDays }}</td>
                        <td *ngFor="let item of dataTblH.slice(1)">{{ item.cumulativeDays }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblH[0].inmates }}</td>
                        <td *ngFor="let item of dataTblH.slice(1)">{{ item.inmates }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblH[0].rate }}</td>
                        <td *ngFor="let item of dataTblH.slice(1)">{{ item.rate }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblH[0].index }}</td>
                        <td *ngFor="let item of dataTblH.slice(1)">{{ item.index }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000011'">
        <h4><span>■</span>I 喀痰吸引の実施割合</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblI.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblI[0].aspiration }}</td>
                        <td *ngFor="let item of dataTblI.slice(1)">{{ item.aspiration }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblI[0].inmates }}</td>
                        <td *ngFor="let item of dataTblI.slice(1)">{{ item.inmates }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblI[0].rate }}</td>
                        <td *ngFor="let item of dataTblI.slice(1)">{{ item.rate }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblI[0].index }}</td>
                        <td *ngFor="let item of dataTblI.slice(1)">{{ item.index }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000012'">
        <h4><span>■</span>J 経管栄養の実施割合</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblJ.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblJ[0].feeding }}</td>
                        <td *ngFor="let item of dataTblJ.slice(1)">{{ item.feeding }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblJ[0].inmates }}</td>
                        <td *ngFor="let item of dataTblJ.slice(1)">{{ item.inmates }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblJ[0].rate }}</td>
                        <td *ngFor="let item of dataTblJ.slice(1)">{{ item.rate }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblJ[0].index }}</td>
                        <td *ngFor="let item of dataTblJ.slice(1)">{{ item.index }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000013'">
        <h4>A 在宅復帰率</h4>
        <h4><span>■</span>各月の実数</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblMonthA.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthA[0].leave }}</td>
                        <td *ngFor="let item of dataTblMonthA.slice(1)">{{ item.leave }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthA[0].discharge }}</td>
                        <td *ngFor="let item of dataTblMonthA.slice(1)">{{ item.discharge }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthA[0].death }}</td>
                        <td *ngFor="let item of dataTblMonthA.slice(1)">{{ item.death }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000014'">
        <h4>B ベッド回転率</h4>
        <h4><span>■</span>各月の実数</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblMonthB.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthB[0].inmates }}</td>
                        <td *ngFor="let item of dataTblMonthB.slice(1)">{{ item.inmates }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthB[0]?.sameDayDischarge45 }}</td>
                        <td *ngFor="let item of dataTblMonthB.slice(1)">{{ item.sameDayDischarge45 }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthB[0]?.sameDayDischarge }}</td>
                        <td *ngFor="let item of dataTblMonthB.slice(1)">{{ item.sameDayDischarge }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthB[0]?.sameDayAdmissionDischarge45 }}</td>
                        <td *ngFor="let item of dataTblMonthB.slice(1)">{{ item.sameDayAdmissionDischarge45 }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthB[0]?.sameDayAdmissionDischarge }}</td>
                        <td *ngFor="let item of dataTblMonthB.slice(1)">{{ item.sameDayAdmissionDischarge }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthB[0].newly }}</td>
                        <td *ngFor="let item of dataTblMonthB.slice(1)">{{ item.newly }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthB[0].leave }}</td>
                        <td *ngFor="let item of dataTblMonthB.slice(1)">{{ item.leave }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000015'">
        <h4>C 入所前後訪問指導割合</h4>
        <h4><span>■</span>各月の実数</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblMonthC.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthC[0].admission }}</td>
                        <td *ngFor="let item of dataTblMonthC.slice(1)">{{ item.admission }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthC[0].newly }}</td>
                        <td *ngFor="let item of dataTblMonthC.slice(1)">{{ item.newly }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000016'">
        <h4>D 退所前後訪問指導割合</h4>
        <h4><span>■</span>各月の実数</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblMonthD.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthD[0].discharge }}</td>
                        <td *ngFor="let item of dataTblMonthD.slice(1)">{{ item.discharge }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthD[0].peopleLeaving }}</td>
                        <td *ngFor="let item of dataTblMonthD.slice(1)">{{ item.peopleLeaving }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000017'">
        <h4>E 居宅サービスの実施状況</h4>
        <h4><span>■</span>各月の実数</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblMonthE.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthE[0].serviceType }}</td>
                        <td *ngFor="let item of dataTblMonthE.slice(1)">{{ item.serviceType }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000018'">
        <h4>F リハ専門職員の配置割合</h4>
        <h4><span>■</span>各月の実数</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblMonthF.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthF[0].healthFacility }}</td>
                        <td *ngFor="let item of dataTblMonthF.slice(1)">{{ item.healthFacility }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthF[0].physicalTherapists }}</td>
                        <td *ngFor="let item of dataTblMonthF.slice(1)">{{ item.physicalTherapists }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthF[0].inmates }}</td>
                        <td *ngFor="let item of dataTblMonthF.slice(1)">{{ item.inmates }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthF[0]?.sameDayDischarge45 }}</td>
                        <td *ngFor="let item of dataTblMonthF.slice(1)">{{ item.sameDayDischarge45 }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthF[0]?.sameDayDischarge }}</td>
                        <td *ngFor="let item of dataTblMonthF.slice(1)">{{ item.sameDayDischarge }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthF[0]?.sameDayAdmissionDischarge45 }}</td>
                        <td *ngFor="let item of dataTblMonthF.slice(1)">{{ item.sameDayAdmissionDischarge45 }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthF[0]?.sameDayAdmissionDischarge }}</td>
                        <td *ngFor="let item of dataTblMonthF.slice(1)">{{ item.sameDayAdmissionDischarge }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthF[0].dayInMonth }}</td>
                        <td *ngFor="let item of dataTblMonthF.slice(1)">{{ item.dayInMonth }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000019'">
        <h4>G 支援相談員の配置割合</h4>
        <h4><span>■</span>各月の実数</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblMonthG.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthG[0].careHealthFacility }}</td>
                        <td *ngFor="let item of dataTblMonthG.slice(1)">{{ item.careHealthFacility }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthG[0].coumselors }}</td>
                        <td *ngFor="let item of dataTblMonthG.slice(1)">{{ item.coumselors }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthG[0].inmates }}</td>
                        <td *ngFor="let item of dataTblMonthG.slice(1)">{{ item.inmates }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthG[0]?.sameDayDischarge45 }}</td>
                        <td *ngFor="let item of dataTblMonthG.slice(1)">{{ item.sameDayDischarge45 }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthG[0]?.sameDayDischarge }}</td>
                        <td *ngFor="let item of dataTblMonthG.slice(1)">{{ item.sameDayDischarge }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthG[0]?.sameDayAdmissionDischarge45 }}</td>
                        <td *ngFor="let item of dataTblMonthG.slice(1)">{{ item.sameDayAdmissionDischarge45 }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthG[0]?.sameDayAdmissionDischarge }}</td>
                        <td *ngFor="let item of dataTblMonthG.slice(1)">{{ item.sameDayAdmissionDischarge }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthG[0].dayInMonth }}</td>
                        <td *ngFor="let item of dataTblMonthG.slice(1)">{{ item.dayInMonth }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000020'">
        <h4>H 介護度４又は５の割合</h4>
        <h4><span>■</span>各月の実数</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblMonthH.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthH[0].cumulativeDays }}</td>
                        <td *ngFor="let item of dataTblMonthH.slice(1)">{{ item.cumulativeDays }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthH[0].inmates }}</td>
                        <td *ngFor="let item of dataTblMonthH.slice(1)">{{ item.inmates }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthH[0]?.sameDayDischarge45 }}</td>
                        <td *ngFor="let item of dataTblMonthH.slice(1)">{{ item.sameDayDischarge45 }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthH[0]?.sameDayDischarge }}</td>
                        <td *ngFor="let item of dataTblMonthH.slice(1)">{{ item.sameDayDischarge }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthH[0]?.sameDayAdmissionDischarge45 }}</td>
                        <td *ngFor="let item of dataTblMonthH.slice(1)">{{ item.sameDayAdmissionDischarge45 }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthH[0]?.sameDayAdmissionDischarge }}</td>
                        <td *ngFor="let item of dataTblMonthH.slice(1)">{{ item.sameDayAdmissionDischarge }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000021'">
        <h4>I 喀痰吸引の実施割合</h4>
        <h4><span>■</span>各月の実数</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblMonthI.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthI[0].aspiration }}</td>
                        <td *ngFor="let item of dataTblMonthI.slice(1)">{{ item.aspiration }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthI[0].inmates }}</td>
                        <td *ngFor="let item of dataTblMonthI.slice(1)">{{ item.inmates }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthI[0]?.sameDayDischarge45 }}</td>
                        <td *ngFor="let item of dataTblMonthI.slice(1)">{{ item.sameDayDischarge45 }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthI[0]?.sameDayDischarge }}</td>
                        <td *ngFor="let item of dataTblMonthI.slice(1)">{{ item.sameDayDischarge }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthI[0]?.sameDayAdmissionDischarge45 }}</td>
                        <td *ngFor="let item of dataTblMonthI.slice(1)">{{ item.sameDayAdmissionDischarge45 }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthI[0]?.sameDayAdmissionDischarge }}</td>
                        <td *ngFor="let item of dataTblMonthI.slice(1)">{{ item.sameDayAdmissionDischarge }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="zaitakuId === 'ZD00000022'">
        <h4>J 経管栄養の実施割合</h4>
        <h4><span>■</span>各月の実数</h4>
        <div class="table-container">
            <table class="zaitaku-table">
                <thead>
                    <tr>
                        <th class="sticky-column"></th>
                        <th *ngFor="let item of dataTblMonthJ.slice(1)">{{ item.date }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthJ[0].feeding }}</td>
                        <td *ngFor="let item of dataTblMonthJ.slice(1)">{{ item.feeding }}</td>
                    </tr>
                    <tr>
                        <td class="sticky-column">{{ dataTblMonthJ[0].inmates }}</td>
                        <td *ngFor="let item of dataTblMonthJ.slice(1)">{{ item.inmates }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthJ[0]?.sameDayDischarge45 }}</td>
                        <td *ngFor="let item of dataTblMonthJ.slice(1)">{{ item.sameDayDischarge45 }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthJ[0]?.sameDayDischarge }}</td>
                        <td *ngFor="let item of dataTblMonthJ.slice(1)">{{ item.sameDayDischarge }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthJ[0]?.sameDayAdmissionDischarge45 }}</td>
                        <td *ngFor="let item of dataTblMonthJ.slice(1)">{{ item.sameDayAdmissionDischarge45 }}</td>
                    </tr>
                    <tr *ngIf="hasHospitalNearby">
                        <td class="sticky-column">{{ dataTblMonthJ[0]?.sameDayAdmissionDischarge }}</td>
                        <td *ngFor="let item of dataTblMonthJ.slice(1)">{{ item.sameDayAdmissionDischarge }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>