export const ROUTE_PATH = {
    AUTH: "auth",
    CONSOLE: "console",
    RESET_PASSWORD: "reset-password",
    HOME: "home",
    BASIC_INFO: "basic-info",
    DASHBOARD_CREATE: "dashboard/create",
    DASHBOARD_DETAIL: "dashboard/detail",
    DASHBOARD_LIST:"dashboard/list",
    DASHBOARD_TEMPLATE_CREATE: "dashboard-template/create",
    DASHBOARD_TEMPLATE_DETAIL: "dashboard-template/detail",
    // DASHBOARD_WIDGET_TEMPLATE: "dashboard-template/widget",
    DASHBOARD_TEMPLATE:"dashboard-template/list",
    DATA_UPDATE: "data-update",
    DATASOURCE_CREATE: "datasource/create",
    DATASOURCE_EDIT: "datasource/detail",
    DATASOURCE_TEMPLATE_CREATE: "datasource-template/create",
    DATASOURCE_TEMPLATE_EDIT: "datasource-template/detail",
    DATASOURCE_TEMPLATE: "datasource-template/list",
    DATASOURCE: "datasource/list",
    DB_WD_DETAIL: "dashboard/widget-detail",
    CK_REPORT: "ck-report",
    SYSTEM_SETTING: "system-setting",
    HELP: "help",
    WIDGET_CREATE: "widget/create",
    WIDGET_DETAIL: "widget/detail",
    WIDGET_LIST: "widget/list",
    WIDGET_TEMPLATE_CREATE: "widget-template/create",
    WIDGET_TEMPLATE_DETAIL: "widget-template/detail",
    WIDGET_TEMPLATE_LIST: "widget-template/list",
    AGGREGATION_LIBRARY: "list-widget-template",
    PAGE_NOT_FOUND: '404',
    CHOOSE_CORP: "choose-organization",
    LOCK_ACCOUNT:'lock-account',
    PAGE_ERROR: 'error',
    LIST_ITEM_TEMPLATE:"list-widget-template",
    CREATE_WIDGET_TEMPLATE: "create-widget-tempate"
};
