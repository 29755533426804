import { TargetSettingType } from './../../../enum/common-enum';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MESSAGE_TEXT } from '../../../../app/const/message';
import { v4 } from 'uuid';
import { DSCUSTOM, FooterName, InvisibleColumn, DATATYPE, InvisibleInCustom, SummaryColumnName, DSTARGET, PivotOptions, ColFilterGroup } from '../../../../app/const/const';
import { ButtonType, ColumnType, FormatType, InputType, SearchType } from '../../../../app/enum/common-enum';
import { ModalTemplate, operator, SearchParams } from '../../../../app/models/common-model';
import { WidgetDetail, WidgetSettingRecord } from '../../../../app/models/request/widget.dto';
import Utils from '../../../../app/util/utils';
import { convertJapaneseNumber, createXNode, formatingDateTimeData, selectType } from '../../../../app/_helper/helper';
import { BUTTON, COMMON_TEXT, WIDGET_SETTING } from '../../../../app/const/text-common';
import { bool } from 'aws-sdk/clients/signer';
import { cloneDeep, orderBy } from 'lodash';
import { Router } from '@angular/router';
import { setCaretPosition } from 'src/app/_helper/mention-helper';
import { OPERATOR_CUSTOM, OPERATOR_CUSTOM_NAME, OPERATOR_TYPE } from 'src/app/const/operator-custom';
import { OperatorCustom } from 'src/app/models/operator-custom';
import { createOperator, generateHtml, generateHtmlCusor, generateHtmlOperator, getOperatorFromString, getOperatorString} from 'src/app/_helper/operator-custom-helper';
import { InputFormulaComponent } from 'src/app/component/common/input-formula/input-formula.component';
import { SharedDataService } from 'src/app/services/share-data.service';
import { BaseComponent } from 'src/app/component/common/base.component';
import { takeUntil } from 'rxjs';
import { DistinctedColumnOfDataRawModel } from 'src/app/models/request/datasource.dto';
import { SaucerLogService } from '../../../services/saucer-logs/saucer-log.service';

import { DefaultValueSettingRO } from 'src/app/models/response/default-value-setting.ro';

@Component({
  selector: 'pivot-defined-column-dialog',
  templateUrl: './defined-column-dialog.component.html',
  styleUrls: ['./defined-column-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class DefinedColumnDialogComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  // @ViewChild('operatorEditor') operatorEditorEmelent: ElementRef;
  
  @ViewChild('inputFormula') inputFormula: InputFormulaComponent;
  @ViewChild('lstRowOption', { static: false }) listOp: any;
  @ViewChild('table') table: ElementRef;
  
  @Input() widgetSettingActionLog: any;
  @Input() targetSettings: any [] = [];
  // modal data
  @Input() modalData: ModalTemplate = new ModalTemplate();
  // edit  data
  @Input() datasourceCols: Array<WidgetDetail>;
  @Input() selected: WidgetDetail | null

  @Input() columns: WidgetDetail[] = [];

  @Input() distinctedColumns: DistinctedColumnOfDataRawModel[] = [];
  @Output() loadDataColumnTargetSettingForLargeDataEvent: any = new EventEmitter<string>();

  @Output() onSubmitData: any = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<WidgetDetail>();
  @Input() defaultValueSetting: DefaultValueSettingRO[] = [];
  column: WidgetDetail = new WidgetDetail();
  displayCols: Array<WidgetDetail>;
  // button type
  buttonType = ButtonType;
  rowFilters: any[] = [];
  targetOptions: any[] = [];
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  WIDGET_SETTING = WIDGET_SETTING;
  DATATYPE = DATATYPE;
  // input params
  inputNameParams: any = {
    type: InputType.text,
    validate: true,
    validateError: MESSAGE_TEXT.REQUIRE_INPUT,
    borderFill: true,
    maxLength: 50,
  };

  selectedColIndex: number = 0;

  calculatorStyle: any = { 'margin-left.px': 10, 'width.px': 250, 'height.px': 30 }

  operators: Array<operator> = []
  operatorHtml: SafeHtml;
  _sanitizer: DomSanitizer;

  cursorIndex: number | undefined;
  prevValue: any;
  hover: bool = false;
  usingDataType: any;
  columnname: string;
  currentTargets: any[] = [];

  targetParams: SearchParams = {
		type: SearchType.combo,
		cssStyle: { width: 'min(100%, 583px)', height: '40px' },
		readonly: false,
		disabled: false,
		maxLength: 100,
		comboDisplayText: 'name'
	};

  rowParams: SearchParams = cloneDeep(this.targetParams);

  columnOptions: any[] = [];
  rowOptions: any[] = [];
  datetimeFormatOptions: any[] = PivotOptions.filter(op => op.value != FormatType.Group);
  // input params
  inputTargetName: any = {
    type: InputType.text,
    validate: true,
    validateError: MESSAGE_TEXT.REQUIRE_INPUT,
    borderFill: true,
    maxLength: 50,
  };

  DSCUSTOM = DSCUSTOM
  DSTARGET = DSTARGET
  targetTable: any[] = [];
  targetTableSlice: any[] = [];
  selectedSetting: WidgetSettingRecord = new WidgetSettingRecord();
  targetFomular: any[] = [{code: TargetSettingType.FIX_NUMBER, name: "入力"}, {code: TargetSettingType.FORMULAR, name: '計算式'}];
  showFormulaModal: boolean = false;
  isDefineNewColumnTab =  true;
  selectedRow: any = null;
  selectedColumn: any= null;
  selectedRowFormula: any;
  formatDateTime: any;
  validation: any = {};
  buttonName: string = '追加';
  key: string = '';
  selectedFormat: any;
  targetTableAll: any = []
  targetTableEach: any = [];
  isFilterAll: boolean = false;
  usedColumnsInTarget: Array<WidgetDetail> = [];
  usedColumnsInCustom: Array<WidgetDetail> = [];
  popupIcon = "../../../../assets/icons/open-popup.svg";
  isDisableSaveBtn: boolean = true;
  isDraftData: boolean = false;
  TARGET_SETTING_TYPE = TargetSettingType;
  isLoading: bool = false;

  itemsMention: any = {};

  isFilterTableComponentVisible = true;
  isLoadFullForRow = false;
  originalSelectedSaucerLog: any;
  constructor(
    sanitizer: DomSanitizer,  
    private sharedDataService: SharedDataService,
    private saucerLogService: SaucerLogService
  ) {
    super();
    this._sanitizer = sanitizer;
    const bodyElement = document.getElementsByTagName("body")[0];
    const isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    if (isDarkMode) {
      this.popupIcon = '../../../../assets/icons/open-popup-white.svg';
    } else {
      this.popupIcon = '../../../../assets/icons/open-popup.svg';
    }
    this.itemsMention.itemsFunction = [];
    OPERATOR_CUSTOM.map((x:OperatorCustom) =>{
      this.itemsMention.itemsFunction.push(
        { label: x.name , type: OPERATOR_TYPE.OPERATOR_CUSTOM }
      )
    });
    this.itemsMention.itemsColumn = [];
  }

  // on change data
  ngOnChanges(changes: SimpleChanges): void {
    this.usingDataType = null;
    if (!Utils.isNullOrEmpty(this.displayCols)) {
      this.inputNameParams.isValidate = false;
    }
    else {
      this.column = new WidgetDetail()
    }

    let displayCols = this.datasourceCols.filter(x => 
      !InvisibleColumn[x.columnname] 
      && !x.columnname?.includes(ColFilterGroup)
      && ![DSCUSTOM, FooterName, SummaryColumnName].includes(x.datasourcecd)
    )?.map(item => {
      if(item.formattype?.toString()?.includes(FormatType.Group)) item.datatype = item.datatype == DATATYPE.String ? DATATYPE.Float : item.datatype;
      return item;
    })
    displayCols = cloneDeep(displayCols);
    this.displayCols = displayCols
    let notTDDNodes = this.displayCols.filter(el => !el.columnname?.toUpperCase().startsWith("TTD_"));
    let TTDNodes = this.displayCols.filter(el => el.columnname?.toUpperCase().startsWith("TTD_"));
    if(TTDNodes.length) {
      let orderTTDNodes = TTDNodes.sort((a: any, b: any) => {
        const numberA = parseInt(a.columnname.split("_")[1]);
        const numberB = parseInt(b.columnname.split("_")[1]);
        return numberA - numberB;
      })
      notTDDNodes = orderBy(notTDDNodes, ["utcsortno"]);
      this.displayCols = [...orderTTDNodes, ...notTDDNodes]
    }
    
    this.displayCols = this.displayCols?.filter(
      (item, index, array) => array.findIndex((element) => element.columnname === item.columnname) === index
    );
    this.itemsMention.itemsColumn = [];


    if(changes['widgetSettingActionLog']) {
      this.widgetSettingActionLog = changes['widgetSettingActionLog'].currentValue;
    }

    this.originalSelectedSaucerLog = cloneDeep(this.selected);
  }

  ngOnInit(): void {
    this.targetSettings = cloneDeep(this.targetSettings);
    if(!this.targetSettings) {
      this.targetSettings = [];
    }
    if(this.targetSettings && this.targetSettings.length) {
      this.reloadLastestTargetTable()
    }

    if (this.selected) {
      if(this.selected.datasourcecd === DSCUSTOM) {
        this.isDefineNewColumnTab = true;
        this.column = this.selected
        this.columnname = this.column.columnname

      }
      else { //DSCUSTOMTARGET
        this.isDefineNewColumnTab = false;
        let exist = this.targetSettings.filter((target: WidgetSettingRecord)=> target.targetColumnName === this.selected?.columnname).pop();
        this.selectedSetting = cloneDeep(exist);
        this.buttonName = '確定';

        this.loadDataForDropDownTarget();
        if(this.selectedSetting.isApplyAll) {
          this.addApplyAllRecordToTable()
        }
        else {
          this.loadTargetTableForRow(this.selectedSetting.row.code);
        }
        this.repopulateData();
      }
    }
    else {
      this.isDefineNewColumnTab = true;
    }
    if(!this.columnOptions.length || !this.rowOptions.length) {
      this.loadDataForDropDownTarget();
    }

    this.sharedDataService.currentDistinctedColumnsWidgetSetting
    .pipe(takeUntil(this.destroy$)).subscribe(distinctedColumns => {
      if(distinctedColumns && distinctedColumns.length) {
        this.distinctedColumns = distinctedColumns;

        const columnDisticted = this.distinctedColumns
        .find(x => x.columnName == this.selectedSetting.row.code);
        if(columnDisticted && columnDisticted.isFull) {
          this.isLoading = false;
        }

        if(this.selectedSetting.isApplyAll) {
          this.addApplyAllRecordToTable()
        }
        else {
          this.loadTargetTableForRow(this.selectedSetting.row.code);
        }
        if(this.selected || this.selectedSetting?.row?.data) {
          this.repopulateData();
        }
      }
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.table.nativeElement.removeEventListener('scroll', this.onTableScroll.bind(this));
    this.sharedDataService.resetDistinctedColumnWidgetSetting();
  }
  ngAfterViewInit() {
    this.table.nativeElement.addEventListener('scroll', this.onTableScroll.bind(this));
  }

  onTableScroll() {
    const scrollTop = this.table.nativeElement.scrollTop;
    const scrollHeight = this.table.nativeElement.scrollHeight;
    const clientHeight = this.table.nativeElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 50) {
      if (this.targetTableSlice.length < this.targetTable.length) {
        const nextData = this.targetTable.slice(this.targetTableSlice.length, this.targetTableSlice.length + 100);
        this.targetTableSlice = [...this.targetTableSlice, ...nextData];
      }
    }

  }

  // close dialog
  onClose() {
    this.reset()
    this.onSubmitData.emit(null);
  }

  // on submit data event
  onSubmit() { 
    if(this.isDefineNewColumnTab) {
      if (Utils.isNullOrEmpty(this.columnname)) {
        this.inputNameParams.validateError = MESSAGE_TEXT.REQUIRE_INPUT;
        this.inputNameParams.isValidate = true;
        this.inputNameParams.validate = true;
        return;
      }
    }
    if (!this.selected) {
      if (this.columns.some(col => col.columnname === this.columnname && col.delflg === false)) {
        this.validateDuplicateColName();
        return;
      }

      this.column.columnid = v4()
      this.column.columntype = ColumnType.NoUse
      this.column.datasourcecd = DSCUSTOM
    } else {
      const isDuplicate = this.columns.some(col => col !== this.selected && col.columnname === this.columnname && col.delflg === false);

      if (isDuplicate) {
        this.validateDuplicateColName();
        return;
      }
    }
    this.column.columnname = this.columnname
    this.column.displayname = this.column?.columnname
    let dataFormula = this.inputFormula.getData();
    this.column.isHaveTargetCol = dataFormula.operators.some((x: any) => x.type === "column" && x.value.datasourcecd === DSTARGET);
    if(dataFormula.usingDataType == DATATYPE.Number || dataFormula.usingDataType == DATATYPE.Float || dataFormula.usingDataType == DATATYPE.Custom) {
      this.column.rounding = dataFormula.rounding;
      this.column.datatype = dataFormula.usingDataType;
    }
    if(dataFormula.usingDataType == null) {
      this.column.rounding = dataFormula.rounding;
    }

    this.column.operator = getOperatorString(dataFormula.operators);
    dataFormula.operators.forEach((op: any) => {
      if(op.value != OPERATOR_TYPE.OPERATOR_CUSOR){
        if(op.type.trim() == 'column'){
          if(typeof op.value == 'object') {
            this.usedColumnsInCustom.push(op.value);
          }
        }
      }
    });

    // replace "¥" => "\" to submit data
    this.targetSettings.map((record: any) => {
      record.targetTable.map((r: any) => {
        if (r.value != null && typeof r.value == 'string') {
          r.value = r.value.replaceAll("¥", "\\");
        }
        return r;
      })
        
      return record;
    });
    
    //#region Log
    if(this.isDefineNewColumnTab) {
      //CUSTOME log
      if(this.selected) {
        //UPDATE
        this.saucerLogService.action({
          content: JSON.stringify({
            old: {
              displayname: this.originalSelectedSaucerLog.displayname,
              operator: this.getLisOperatorSaucerLog(this.originalSelectedSaucerLog.operator),
              rounding: this.originalSelectedSaucerLog.rounding
            }, 
            new: {
              displayname: this.column.displayname,
              operator: this.getLisOperatorSaucerLog(this.column.operator),
              rounding: this.column.rounding
            }
          })
        }, { 
          action: this.widgetSettingActionLog.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG.UPDATE_DEFINED_COLUMN
        });
      } else {
        //CREATE
        this.saucerLogService.action({
          content: JSON.stringify({
            old: null,
            new: {
              displayname: this.column.displayname,
              operator: this.getLisOperatorSaucerLog(this.column.operator),
              rounding: this.column.rounding
            }
          })
        }, { 
          action: this.widgetSettingActionLog.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG.CREATE_DEFINED_COLUMN
        });
      }
    } else {
      //TARGET_SETTING log
      const newRecord = this.targetSettings.map(x => ({
        targetColumnName : x.targetColumnName,
        lessThanColor: x.lessThanColor,
        greaterThanColor : x.greaterThanColor,
        isNoColor: x.isNoColor,
        row: {
          code: x.row.code,
          name: x.row.name,
        },
        column : {
          code: x.column.code,
          name: x.column.name,
        },
        targetTable: x.targetTable.filter((y : any) => y.targetValue)
      }));
      this.saucerLogService.action({
        content: JSON.stringify({
          old: null,
          new: newRecord
        })
      }, { 
        action: this.widgetSettingActionLog.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG.SAVE_TARGET_VALUE
      });
    }
    //#endregion

    let dataOutput = {column: this.column, targetSettings: this.targetSettings, 
                      usedColumnsInTarget: this.usedColumnsInTarget, usedColumnsInCustom: this.usedColumnsInCustom}
    this.onSubmitData.emit(dataOutput);
    this.reset()
  }

  getLisOperatorSaucerLog(operator: string) {
    const lstFormular = this.inputFormula.getData()?.operators;
    if (lstFormular.length > 0) {
      for (let i = 0; i < lstFormular.length; i++) {
        const item = lstFormular[i]?.value;
        if (item && typeof item === 'object') {
          operator = operator.replace(item.columnname, item.displayname);
        }
      }
    }
    return operator;
  }
  
  validateDuplicateColName() {
    this.inputNameParams.validateError = '既に登録されています。';
    this.inputNameParams.isValidate = true;
    this.inputNameParams.validate = true;
  }

  reset() {
    this.operators = [];
    this.cursorIndex = undefined;
    this.column = new WidgetDetail();
    this.columnname = '';
  }

  // input  name
  onInputName(data: any) {
    this.columnname = data.currentTarget?.value;
    this.inputNameParams.validate = false;
    if(this.selected)
    {
      //Update targetvalue that using operator in target table when rename DS-TARGET
      let oldName = this.selected.columnname
      let newName = data.currentTarget?.value
      if(oldName != newName) {
        let oldNameOperator = `{${oldName}}`
        let newNameOperator = `{${newName}}`
        this.targetSettings.map((record: any) => {
          let original = record;
          original.targetTable =  original.targetTable.map((r: any) => {
            if(r.targetValue && r.targetValue.toString().includes('{')) {
              let map =  { ...r , 
                targetValue : r.targetValue.replaceAll( oldNameOperator, newNameOperator),
              }       
              return map;
            }
            else return r;
          })
            
          return original ;
        } );
      }
    }
  }


  remove() {
    this.hover = false;
    this.reset();
    this.onDelete.emit(this.selected!);

    //Log
    this.saucerLogService.action({
      content: JSON.stringify({
        old: {
          displayname: this.originalSelectedSaucerLog.displayname,
          operator: this.getLisOperatorSaucerLog(this.originalSelectedSaucerLog.operator),
          rounding: this.originalSelectedSaucerLog.rounding
        },
        new: null
      })
    }, { 
      action: this.widgetSettingActionLog.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG.DELETE_DEFINED_COLUMN
    });
  }


  onTabChange(value: boolean) {
    if(this.isDefineNewColumnTab == value) return;

    this.isDefineNewColumnTab = value;
    if( !this.isDefineNewColumnTab) {
      if(this.selectedSetting && this.selectedSetting.id == "") {
        this.selectedSetting = new WidgetSettingRecord();
      }
    }
  }

  loadDataForDropDownTarget() {
    this.datasourceCols = orderBy(this.datasourceCols, ["utcsortno"]);
    this.columnOptions = this.datasourceCols.filter(x => 
      !InvisibleColumn[x.columnname]
      && x.columnname != "PRVHOUR" // Not support this phase
      && !x.columnname?.includes(ColFilterGroup)
      && ![DSCUSTOM, FooterName, SummaryColumnName].includes(x.datasourcecd)
    ).map((item:any) => {
      let col =  {code: item.columnname, name: item.displayname, datatype: item.datatype, value: item.columnname}
      return col;
    });
    this.targetParams.dataSource = this.columnOptions;
    this.targetParams = cloneDeep(this.targetParams);
    this.rowOptions = this.datasourceCols.filter(x => 
      !InvisibleColumn[x.columnname] 
      && x.columnname != "PRVHOUR" // Not support this phase
      && !x.columnname?.includes(ColFilterGroup)
      && ![DSCUSTOM, DSTARGET,  FooterName, SummaryColumnName].includes(x.datasourcecd)
    ).map((item:any) => {
      let row = {code: item.columnname, name: item.displayname, datatype: item.datatype, value: item.columnname}
      return row;
    });
    this.rowParams.dataSource = this.rowOptions;
    this.rowParams = cloneDeep(this.rowParams);
    let emptyItem ={ code: null,  name: "", datatype: null }

    if(this.rowOptions.length) this.rowOptions.unshift(emptyItem);
    if(this.columnOptions.length) this.columnOptions.unshift(emptyItem);
  }

  onChangeApplyStyle(value: boolean) {
    this.isFilterAll = value;
    this.selectedSetting.isApplyAll = value;

    this.targetTable = [];
    
    if(value) {
      this.isLoading = false;
      this.addApplyAllRecordToTable()
    }
    else {
      if(!this.selectedSetting.row) return;
      this.loadTargetTableForRow(this.selectedSetting.row.code, true)
      if(this.rowFilters.length > 0) {
        let targets: any[] = [];
        this.targetTable.forEach(t => {
          let findTarget = this.rowFilters?.find(f => f.value == t.value);
          if(findTarget){
            targets.push(t);
          }
        })
        this.targetTable = cloneDeep(targets);
      }
    }
  }

  addApplyAllRecordToTable() {
    let record = {value: "目標値", type: "0", targetValue: ""};
    this.targetTable = [];
    this.targetTable.push(record);
    this.targetTableSlice = [...this.targetTable];
    if(this.selectedSetting.row) {
      if(this.selectedFormat || this.selectedSetting.row.datatype == "DATETIME") {
        if(!this.selectedFormat) {
          this.selectedFormat = {};
          this.selectedFormat.formattype = "A1";
          this.formatDateTime = PivotOptions.find(x => x.value == FormatType.Date);
        }
      }
      else {
        this.formatDateTime = PivotOptions.find(x => x.value == FormatType.NoFormatA);
      }
    }
    
  }

  changeRowItem(event: any) {
    let selectedRow= event[0];
    if(selectedRow) {
      this.validation.row = false;
      this.formatDateTime = null;
      this.selectedFormat = null;
      this.selectedSetting.row = selectedRow
      this.selectedRow = selectedRow;
      this.rowParams.defaultValue = selectedRow.name || '';
      this.rowParams = cloneDeep(this.rowParams);
      this.rowFilters = [];
      if(this.selectedSetting.isApplyAll) {
        this.addApplyAllRecordToTable()
      }
      else {
        this.loadTargetTableForRow(selectedRow.code, true);
      }
    }
  }

  loadTargetTableForRow(colName: string, loadForce: boolean = false) {
    const columnDisticted = this.distinctedColumns
    .find(x => x.columnName == colName.toUpperCase());
    if(!columnDisticted) return;

    this.isLoadFullForRow = columnDisticted.isFull;

    //Check data is full
    if((loadForce || !columnDisticted.data.length) && !columnDisticted.isFull) {
      this.targetTableSlice = [];
      this.isLoading = true;
      this.loadDataColumnTargetSettingForLargeDataEvent.emit(colName.toUpperCase());
      return;
    } 
    if(columnDisticted.isFull) {
      this.isLoading = false;
    }

    const dataRow = columnDisticted.data.map((item: any) => item) as string[];

    if(this.selectedSetting.row) {
      if(this.selectedFormat || this.selectedSetting.row.datatype == "DATETIME") {
        this.targetTable = [];

        if(!this.selectedFormat) {
          this.selectedFormat = {};
          this.selectedFormat.formattype = "A1";
          this.formatDateTime = PivotOptions.find(x => x.value == FormatType.Date);
        }
        const formated = formatingDateTimeData(dataRow, this.selectedFormat);
        const uniqueDataRow = new Set(formated);
        this.targetTable = Array.from(uniqueDataRow).map((item:string) => {return {value: item, type: "", targetValue: ""}})
      }
      else {
        this.targetTable = Array.from(dataRow).map((item:string) => {return {value: item, type: "", targetValue: ""}})
        this.formatDateTime = PivotOptions.find(x => x.value == FormatType.NoFormatA);
      }
    }
    
    this.targetTable = orderBy(this.targetTable, ["value"]);

    // replace Yen character when select Target
    this.targetTable.map((x: any)=> {
      if (typeof x.value == 'string' && x.value != null) {
        x.value = x.value.replace(/\\/g, "¥");
      }
      return x;
    });
    this.renderTableSetting();
    
    this.mapTargetTable();
    this.currentTargets = cloneDeep(this.targetTable);
  }

  renderTableSetting() {
    const nextData = this.targetTable.slice(0, 100);
    this.targetTableSlice = [...nextData] as any;
  }

  changeColumnItem(event: any) {
    if(event) {
      this.validation.column = false;
      let column = event[0];
      this.targetParams.defaultValue = column.name || '';
      this.targetParams = cloneDeep(this.targetParams);
      this.selectedColumn = column;
    }
  }

  mapTargetTable() {
    this.targetOptions = cloneDeep(this.targetTable)?.map((s: any) => {
      s.name = typeof s.value === 'string' ? s.value?.replace(/\\/g, "¥") : s.value;
      s.value = s.name;
      s.checked = false;
      return s;
    });
    this.rowFilters = [];
  }

  addRecord() {
    if(!(this.isLoadFullForRow ||  this.selectedSetting.isApplyAll)) return;

    this.buttonName = '追加';
    let isValidForm = true;
    if(!this.selectedSetting.targetColumnName){
      this.inputTargetName.validateError = MESSAGE_TEXT.REQUIRE_INPUT;
      this.inputTargetName.isValidate = true;
      this.inputTargetName.validate = true;
      isValidForm = false;
    } 
    else {
      let existTargetColumn = this.targetSettings.filter((x: WidgetSettingRecord) => 
                        x.targetColumnName == this.selectedSetting.targetColumnName && this.selectedSetting.id != x.id);
      if(existTargetColumn && existTargetColumn.length) {
        this.inputTargetName.validateError = MESSAGE_TEXT.DUPLICATE_NAME;
        this.inputTargetName.isValidate = true;
        this.inputTargetName.validate = true;
        isValidForm = false;
      }
    }

    if(!this.selectedRow) {
      this.validation.row = true;
      isValidForm = false;
    }
    else {
      this.validation.row = false;
    }

    if(!this.selectedColumn) {
      this.validation.column = true;
      isValidForm = false;
    }
    else {
      this.validation.column = false;
    }
    if(!isValidForm) return;

    let selectedRow = this.findColumnDataInDataSource(this.selectedRow.code, ColumnType.Row);

    if(selectedRow) {
      if(this.selectedRow.data ) {
        selectedRow.data.formattype = this.selectedFormat ? this.selectedFormat.formattype: "";
      }
      else {
        selectedRow.data.formattype = this.selectedFormat ? this.selectedFormat.formattype: "";
      }
      this.selectedSetting.row = selectedRow;
    }

    let selectedColumn = this.findColumnDataInDataSource(this.selectedColumn.code, ColumnType.Value);
    if(selectedColumn) {
      this.selectedSetting.column = selectedColumn;
    }
    
    if(!this.isFilterAll) {
      let targets: any[] = cloneDeep(this.targetTable);
      this.targetTable = cloneDeep(this.currentTargets).map(item => {
          let findTarget = targets?.find(t => t.value == item.value);
          if(findTarget) {
            item = findTarget
          }
          return item;
      });
    }
    this.selectedSetting.targetTable = this.targetTable;

    if(!this.selectedSetting.id) {
      this.selectedSetting.id = v4();
      this.targetSettings.push(cloneDeep(this.selectedSetting));

      //Log
      const newRecord = {
        targetColumnName : this.selectedSetting.targetColumnName,
        lessThanColor: this.selectedSetting.lessThanColor,
        greaterThanColor : this.selectedSetting.greaterThanColor,
        isNoColor: this.selectedSetting.isNoColor,
        row: {
          code: this.selectedSetting.row.code,
          name: this.selectedSetting.row.name,
        },
        column : {
          code: this.selectedSetting.column.code,
          name: this.selectedSetting.column.name,
        },
        targetTable: this.selectedSetting.targetTable.filter(x => x.targetValue)
      }
      this.saucerLogService.action({
        content: JSON.stringify({
          old: null,
          new: newRecord,
        })
      }, { 
        action: this.widgetSettingActionLog.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG.CREATE_TARGET_VALUE
      });
    }
    else {
      const oldTarget =  {...this.targetSettings.find(x => x.id == this.selectedSetting.id)};
      this.targetSettings = this.targetSettings.map((x: WidgetSettingRecord) => x.id == this.selectedSetting.id ? ({...this.selectedSetting}): ({...x}));

      const oldRecord = {
        targetColumnName : oldTarget.targetColumnName,
        lessThanColor: oldTarget.lessThanColor,
        greaterThanColor : oldTarget.greaterThanColor,
        isNoColor: oldTarget.isNoColor,
        row: {
          code: oldTarget.row.code,
          name: oldTarget.row.name,
        },
        column : {
          code: oldTarget.column.code,
          name: oldTarget.column.name,
        },
        targetTable: oldTarget.targetTable.filter((x:any) => x.targetValue)
      }
      const newRecord = {
        targetColumnName : this.selectedSetting.targetColumnName,
        lessThanColor: this.selectedSetting.lessThanColor,
        greaterThanColor : this.selectedSetting.greaterThanColor,
        isNoColor: this.selectedSetting.isNoColor,
        row: {
          code: this.selectedSetting.row.code,
          name: this.selectedSetting.row.name,
        },
        column : {
          code: this.selectedSetting.column.code,
          name: this.selectedSetting.column.name,
        },
        targetTable: this.selectedSetting.targetTable.filter(x => x.targetValue)
      }
      //Log
      this.saucerLogService.action({
        content: JSON.stringify({
          old: oldRecord,
          new: newRecord,
        })
      }, { 
        action: this.widgetSettingActionLog.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG.UPDATE_TARGET_VALUE
      });
    }
    this.resetSettingTargetForm();
    this.isDisableSaveBtn = false;
    this.isDraftData = false;
  }

  resetRecord(isResetBtn = false) {
    this.isDisableSaveBtn = false;
    this.isDraftData = false;
    this.resetSettingTargetForm(false, isResetBtn);
  }

  findColumnDataInDataSource(columnCode: string, columnType: ColumnType) {
    return this.datasourceCols.filter(x => 
      !InvisibleColumn[x.columnname]
      && ![DSCUSTOM, FooterName, SummaryColumnName].includes(x.datasourcecd)
    ).map((item:any, type) => {
      let col =  {code: item.columnname, name: item.displayname, data: {...item}}
      col.data.columntype = columnType;

      return col;
    }).filter((x:any) => x.code === columnCode).pop();
  }

  resetSettingTargetForm(isDelete: boolean = false, isResetBtn = false) {
    this.resetValidationForm();
    this.selectedRow = null;
    this.rowParams.defaultValue = '';
    this.rowParams = cloneDeep(this.rowParams);
    this.selectedColumn = null;
    this.targetParams.defaultValue = '';
    this.targetParams = cloneDeep(this.targetParams);
    this.targetTable = [];
    this.targetTableSlice = [];
    this.formatDateTime = null;
    this.selectedFormat = null;
    this.rowFilters = [];
    if(this.selectedSetting && (this.buttonName == '追加' || isDelete)) {
      this.selectedSetting = new WidgetSettingRecord();
    }
    else if(isResetBtn)
    {
      this.selectedSetting = new WidgetSettingRecord();
    }
  }

  resetValidationForm() {
    this.validation = {};
    this.inputTargetName.validateError = "";
    this.inputTargetName.isValidate = false;
    this.inputTargetName.validate = false;
  }

  setTargetName(data: any) {
    let targetColumn: any = {}; 
    if(this.selectedSetting && this.selectedSetting.id === undefined)
    {
      targetColumn.columnid = v4();
      targetColumn.columntype = ColumnType.Value;
      targetColumn.datasourcecd = DSTARGET
      targetColumn.columnname = data.currentTarget?.value;
      targetColumn.displayname = targetColumn.columnname;
      targetColumn.datatype = "INT";
    }
    else 
    {
      //Update operator in columns with type DS-CUSTOM when rename DS-TARGET
      let oldName = this.selectedSetting.targetColumn.columnname
      let newName = data.currentTarget?.value
      if(oldName != newName) {
        let oldNameOperator = `{${oldName}}`
        let newNameOperator = `{${newName}}`
        this.columns.map(w=> w.operator = w.operator?.replaceAll(oldNameOperator, newNameOperator) as string);
      }
      //update targetSettings when renaming target column
      let filter = this.targetSettings.filter((t: any) => t.column?.data?.columnid == this.selectedSetting?.targetColumn?.columnid).pop() as WidgetSettingRecord;
      if(filter) {
        filter.column.code = newName;
        filter.column.name = newName;
        if(filter.column.data) {
          filter.column.data.columnname = newName;
          filter.column.data.displayname = newName;
        }
      }
      targetColumn = {...this.selectedSetting.targetColumn} ;
      targetColumn.columnname = data.currentTarget?.value;
      targetColumn.displayname = targetColumn.columnname;
    }
    let column = cloneDeep(targetColumn)
    this.selectedSetting.targetColumn = column;
    this.selectedSetting.targetColumnName = column.columnname;
  }

  editTarget(id: string) {
    this.resetValidationForm();
    this.buttonName = '確定';
    let selectedSetting = this.targetSettings.filter((item:WidgetSettingRecord )=> item.id === id).pop();
    this.selectedSetting = cloneDeep(selectedSetting);
    this.loadTargetTableForRow(this.selectedSetting.row.code);
    this.repopulateData();
    this.isDisableSaveBtn = true;
  }

  repopulateData() {
    if(this.selectedSetting) {
      let selectedRow =  cloneDeep(this.selectedSetting.row)
      if(selectedRow) {  
        this.selectedFormat = selectedRow.data; 
        this.formatDateTime = PivotOptions.filter(y => y.value === selectType(this.selectedFormat?.formattype, 'A')).pop();
        let datatypeRow = selectedRow.data?.datatype;
        delete selectedRow.data;
        this.selectedRow = selectedRow
        this.selectedRow.value = this.selectedRow.code;
        this.selectedRow.datatype = datatypeRow;
        this.rowParams.defaultValue = this.selectedRow.name;
        this.rowParams = cloneDeep(this.rowParams);
      }
      let selectedColumn =  cloneDeep(this.selectedSetting.column)
      if(selectedColumn) {
        let datatypeColumn= selectedColumn.data?.datatype;
        delete selectedColumn.data;
        this.selectedColumn = selectedColumn
        this.targetParams.defaultValue = selectedColumn.name;
        this.selectedColumn.value = selectedColumn.code;
        this.targetParams = cloneDeep(this.targetParams);
        this.selectedColumn.datatype = datatypeColumn;
      }

      if(this.selectedSetting.isApplyAll) {
        this.targetTable = this.selectedSetting.targetTable;
        this.targetTable = orderBy(this.targetTable, ["value"]);
      }
      else if(this.selectedSetting.row) {
        const dataRow = (this.distinctedColumns
                      .find(x => x.columnName == this.selectedSetting.row.code.toUpperCase())
                      ?.data || [])
                      .map((item: any) => item) as string[];
        if( this.selectedFormat) {
          const formatDateTime = formatingDateTimeData(dataRow, this.selectedSetting.row.data);
          const uniqueDataRow = new Set(formatDateTime);
          let allRows = Array.from(uniqueDataRow).map((item:string) => {
            let exist = this.selectedSetting.targetTable.filter((data: any) => data.value ? data.value == item: "" == item);
            if(exist.length > 0 ){
              return {...exist[0]}
            }
            return {value: item, type: "", targetValue: ""}
          })
          this.targetTable = orderBy(allRows, ["value"]);
        }
      }
    }
    this.mapTargetTable();

    // replace Yen character when Edit Target
    this.targetTable.map((x: any) => {
      if (typeof x.value == 'string' && x.value != null) {
        x.value = x.value.replace(/\\/g, "¥");
      }
      return x;
    });

    this.renderTableSetting();

    this.currentTargets = cloneDeep(this.targetTable);
    this.rowFilters = [];
  }

  deleteTarget(id: string) {
    //Log
    const oldTarget = {...this.targetSettings.find((item:WidgetSettingRecord )=> item.id == id)};
    const old = {
      displayname: oldTarget.targetColumnName,
      row: {
        code: oldTarget.row.code,
        name: oldTarget.row.name,
      },
      column: {
        code: oldTarget.column.code,
        name: oldTarget.column.name,
      },
      lessThanColor: oldTarget.lessThanColor,
      greaterThanColor: oldTarget.greaterThanColor,
      isNoColor: oldTarget.isNoColor
    }
    this.saucerLogService.action({
      content: JSON.stringify({
        old: old,
        new: null,
      })
    }, { 
      action: this.widgetSettingActionLog.ADD_USER_DEFINED_COLUMN_TARGET_VALUE_DIALOG.DELETE_TARGET_VALUE
    });

    this.isDisableSaveBtn = false;
    this.targetSettings = this.targetSettings.filter((item:WidgetSettingRecord )=> item.id !== id);
    this.resetSettingTargetForm(true);
  } 

  showTargetFormulaModal(selectedRow: any) {
    this.selectedRowFormula = selectedRow;
    this.showFormulaModal = true;
  }
  changeValueInline(selectedRow: any) {
    this.selectedRowFormula = selectedRow;
    this.showFormulaModal = true;
  }

  onSelectedOption(data: any) {
    if(data) {
      this.rowFilters = data.value || [];
      let targets: any[] = cloneDeep(this.targetTable);
      this.currentTargets = this.currentTargets.map(o => {
          let findItem = this.rowFilters.find(s=> s.value == o.value);
          let findTarget = targets?.find(t => t.value == o.value);
          if(findTarget) o = findTarget;
          o.checked = findItem? true: false;
          o.name = o.value
          return o;
      });
      this.targetTable = data.value?.length > 0? cloneDeep(this.currentTargets).filter(s=>s.checked): cloneDeep(this.currentTargets);
      this.renderTableSetting();
    }
  }

  submitFormula(event: any) {
    this.showFormulaModal = false;
    if(!event) return;
    if(!this.isDraftData) {
      this.isDraftData = true;
    }
    let {operator, displayText,  usedColumnsInTarget, rounding} = event;
    this.selectedRowFormula.targetValue = operator;
    this.selectedRowFormula.displayText = displayText;

    this.selectedRowFormula.rounding = rounding;
    let uniq = new Set([...this.usedColumnsInTarget, ...usedColumnsInTarget]);
    this.usedColumnsInTarget = [...uniq];
  }

  filterData(event: any) {
    if(this.targetTable.length > 0 && this.isLoadFullForRow) {
      this.isFilterTableComponentVisible = true;
      this.listOp?.toggle(event);
    }
  }
  onPanelHide() {
    // Hủy component khi panel bị ẩn
    this.isFilterTableComponentVisible = false;
  }

  changeDateFormat(event: any) {
    let option = event.value;
    let selectedRow = this.findColumnDataInDataSource(this.selectedRow.code, ColumnType.Row);
    if(selectedRow) {
      if(selectedRow.data.datatype === "DATETIME") {
        selectedRow.data.formattype = option.value;
        let data = cloneDeep(this.selectedRow);
        data.data = selectedRow.data;
        data.data.formattype = option.value;
        this.selectedFormat = data.data;
        if(this.selectedSetting && !this.selectedSetting.isApplyAll) {
          this.targetTable = [];
          const dataRow = (this.distinctedColumns
                          .find(x => x.columnName == this.selectedSetting.row.code.toUpperCase())
                          ?.data || [])
                          .map((item: any) => item) as string[];
          const formatDateTime = formatingDateTimeData(dataRow, selectedRow.data);
          const uniqueDataRow = new Set(formatDateTime);
          const targetTable = Array.from(uniqueDataRow).map((item:string) => {return {value: item, type: "", targetValue: ""}})
          this.targetTable = orderBy(targetTable, ["value"]);
          this.currentTargets = cloneDeep(this.targetTable);
          this.renderTableSetting();
          this.mapTargetTable();
        }
      }
    }
  }

  reloadLastestTargetTable() {
    let settings: WidgetSettingRecord[] =[];
    //Modify target table to lastest record
    if(this.targetSettings && this.targetSettings && this.targetSettings.length) {
      if(this.distinctedColumns && this.distinctedColumns.length) {
        this.targetSettings.forEach((target: WidgetSettingRecord) => {
          let setting:WidgetSettingRecord = new WidgetSettingRecord();

          const dataRow = (this.distinctedColumns
                          .find(x => x.columnName == target.row?.code.toUpperCase())
                          ?.data || [])
                          .map((item: any) => item) as string[];
          const uniqueDataRow = new Set(dataRow);
          let newRecords = Array.from(uniqueDataRow).map((item:string) => {return {value: item, type: "", targetValue: ""}})
          let lastestTargetTable = newRecords.map((item: any)=> 
          {
            let existRecord =  target.targetTable.filter((target: any) => target.value === item.value).pop();
            if(existRecord) {
              item = existRecord;
            }
            return item;
          })
          lastestTargetTable = orderBy(lastestTargetTable, ["value"]);
          setting = {...target, targetTable: lastestTargetTable}
          settings.push(setting);
        })
      }
    }
  }

  enableDropDownDateTime() {
    if(this.selectedRow && this.selectedRow.datatype === 'DATETIME')
      return false;
    return  true
  }

  formatNumber(event: any, data: any) {
    const tabindex = event.tabIndex;
    let value = convertJapaneseNumber(event.value);
    value = this.cellValueChange(value);
    if(value && !this.isDraftData) {
      this.isDraftData = true;
    }
    data.targetValue =  value;
    if(this.key == 'Tab') {
      if (!isNaN(tabindex)) {
        setTimeout(() => {
          const nextElement: any = document.querySelector(`[tabindex="${tabindex + 1}"]`);
          if (nextElement) {
            nextElement.focus();
          }
        }, 100);
      }
      this.key = '';
    }
  }

  cellValueChange(data: any) {
    let numericString = data.toString()?.replace(/[^\d.]/g, '');
    let hasDecimal = numericString.includes('.');
    if(hasDecimal) {
      let [thousand, decimal] = numericString.split('.');
      if(decimal) {
        return thousand + '.' + decimal.slice(0, 2);
      }
      else return thousand;
    }
    return data;
  }

  inputKeyDown(event: any) {
    this.key = event.key
  }

  changeFormulaType(event: any, data: any) {
    data.targetValue = "";
    data.displayText = "";
    if(event) {
      data.type = event.value.code;
    }
  }

  itemSelectedMetion(event:any){
    let selObj =  window.getSelection();
    if (selObj && selObj.rangeCount > 0) {
      var anchorNode = selObj.anchorNode;
      let item:any = {};
      item.label = event.label;
      item.type = event.type;
      item.value = event.label;
      var span = createOperator(item);
      var empty = document.createTextNode('\u00A0'); 
      var spanEpmty = document.createTextNode(' '); 

      if( event.type == OPERATOR_TYPE.OPERATOR_CUSTOM){
        spanEpmty = document.createTextNode('('); 
      }
      var nexNode =  anchorNode?.nextSibling;
      if(anchorNode?.nodeName == "#text"){
        let searchStringLength = event.pos - event.startPos;
        let nodeTextValue = anchorNode.nodeValue ?? "";
        nodeTextValue = nodeTextValue?.substring(0, nodeTextValue?.length - searchStringLength);
        anchorNode.nodeValue = nodeTextValue;
      }
      if(nexNode) {
        nexNode?.parentNode?.insertBefore(span, nexNode);
        nexNode?.parentNode?.insertBefore(empty, nexNode);
        nexNode?.parentNode?.insertBefore(spanEpmty, nexNode);

      }else{
        anchorNode?.parentNode?.appendChild(span);
        anchorNode?.parentNode?.appendChild(empty);
        anchorNode?.parentNode?.appendChild(spanEpmty);
     
      } 
      let spanEpmtyHTML = (<HTMLInputElement><unknown>spanEpmty);
      if( item.type == OPERATOR_TYPE.OPERATOR_CUSTOM){
        setCaretPosition(spanEpmtyHTML,1,null);
      }else{
        setCaretPosition(spanEpmtyHTML,0,null);
      }
    }
  }

  isDisableSaveButton() {
    if(!this.isDefineNewColumnTab && (this.isDisableSaveBtn || this.isDraftData) ){
      return true;
    }
    return false;
  }


  onPasteEditor(event: any) {
    event.preventDefault();
    let data = (event.clipboardData.getData("text").replace(/\n/g, ''));
    //insertValuePaste(data, this.operatorEditorEmelent);
  }

  getTargetValueModel(data: any): string {
    return data.targetFomular && data.targetFomular.code === this.TARGET_SETTING_TYPE.FORMULAR
      ? data.displayText
      : data.targetValue;
  }

  setTargetValueModel(data: any, value: string): void {
    if (data.targetFomular && data.targetFomular.code === this.TARGET_SETTING_TYPE.FORMULAR) {
      data.displayText = value;
    } else {
      data.targetValue = value;
    }
  }
}
